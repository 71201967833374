export const ltrim = function (text:string, trim: string) {
  if (trim === undefined)
    trim = '\\s';
  return text.replace(new RegExp("^[" + trim + "]*"), '');
};

export const rtrim = function (text:string, trim: string) {
  if (trim === undefined)
  trim = '\\s';
  return text.replace(new RegExp("[" + trim + "]*$"), '');
};

export const pathCase = (text: string, allow_params: string[]) => {
  if(text === undefined || text === "") return "/"
  const formatted_text = text.toString()
  .toLowerCase()
  .normalize("NFD")
  .trim()
  .replace(/\s+/g, "/")
  .replace(/[^\w\/\-\:]+/g, "")
  .replace(/\/\/+/g, "/")
  .replace(/\-\-+/g, "-");

  const formatted_text2 = ltrim(rtrim(formatted_text, "/"), "/")
  var formatted_text3: string[] = []
  var param_exist: string[] = []
  
  formatted_text2.split("/").forEach((p) => {
    if(p.startsWith(":")){
      const trimmed_p = ltrim(p, ":")
      if (
        param_exist.findIndex( (pe) => pe === trimmed_p ) === -1 &&
        allow_params.findIndex( (ap) => ap === trimmed_p) > -1
      ) {
        formatted_text3.push(":"+trimmed_p)
        param_exist.push(trimmed_p)
      } else {
        formatted_text3.push(trimmed_p)
      }
    } else{
      formatted_text3.push(p)
    }
  })
  var out =  "/" + formatted_text3.join("/")
  return out
};
