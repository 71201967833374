import { Pagination } from "../../libs/types/pagination"

export enum ProgrammingLanguage  {
    Go = "go"
}

export enum Framework  {
    Go_Gin = "go:gin"
}

export enum DatabaseServer  {
    Postgres = "postgres",
    MySql = "mysql"
}

export enum HTTPMethod  {
    POST = "POST",
    GET = "GET",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
}

export type ApiGenProject = {
    id: number
    code: string
    workspace_id: number
    
    project_name: string
    service_name: string 
    description: string
    programming_language: ProgrammingLanguage 
    framework: Framework 
    database: DatabaseServer
    stack: string

    creator_id: number
    creator_name: string

    generated_url: string 
    generated_expired: Date
}

export type ApiGenProjects = {
    projects: ApiGenProject[]
    pagination: Pagination
}


export type ApiGenSchema = {
    id: number
    project_id: number
    name: string
    columns: ApiGenSchemaColumn[]
    primary_key: string
    timestamp_created: string
    timestamp_updated: string
    softdelete: string 
}

export type ApiGenSchemas ={
    schemas: ApiGenSchema[]
}

export type ApiGenSchemaColumn = {
    name: string
    column_type: ColumnType
    column_size: number
    column_decimal: number
    enum_values: string[]
    nullable: boolean
    default: string
}   

export namespace ApiGenSchemaColumn {
    export function primaryKeyDefault(database: DatabaseServer) :ApiGenSchemaColumn {
        switch(database) {
            case DatabaseServer.Postgres:
                return { name: "id", column_type: ColumnType.Serial, column_decimal: 0, column_size: 0, enum_values: [], nullable: false, default: "" }
            case DatabaseServer.MySql:
                return { name: "id", column_type: ColumnType.Int, column_decimal: 0, column_size: 0, enum_values: [], nullable: false, default: "" }
            default:
        }
        return { name: "id", column_type: ColumnType.Serial, column_decimal: 0, column_size: 0, enum_values: [], nullable: false, default: "" }
    }

    export function timestampColumns(withTimestamp: boolean, softdelete: boolean) :ApiGenSchemaColumn[] {
        var res: ApiGenSchemaColumn[] = []
        if(softdelete) {
            res.push({ name: "deleted_at", column_type: ColumnType.Timestamp, column_decimal: 0, column_size: 0, enum_values: [], nullable: true, default: "" })
        }
        if(withTimestamp){
            res.push({ name: "created_at", column_type: ColumnType.Timestamp, column_decimal: 0, column_size: 0, enum_values: [], nullable: false, default: "" })
            res.push({ name: "updated_at", column_type: ColumnType.Timestamp, column_decimal: 0, column_size: 0, enum_values: [], nullable: false, default: "" })
        }
        return res
    }

    export function fullColumnType(column: Column) :string {
        switch(column.column_type){
            case ColumnType.Decimal: 
                return ColumnType.Decimal + " (" + column.column_size.toString() + "," + column.column_decimal.toString() + ")"
            case ColumnType.Double: 
                return ColumnType.Double + " (" + column.column_size.toString() + "," + column.column_decimal.toString() + ")"
            case ColumnType.Enum:
                return ColumnType.Enum + " (" + column.enum_values.join(",") + ")"
        }
        
        return column.column_type
    }

    export function ArraySwap(columns: ApiGenSchemaColumn[], index: number, direction: 'up' | 'down') :ApiGenSchemaColumn[] {
        var tmp_columns :ApiGenSchemaColumn[] = [...columns]
        if(direction === "down"){
            const target = index + 1
            if(target >= columns.length) {
                return columns
            }
            const tmp = tmp_columns[index]; tmp_columns[index] = tmp_columns[target]; tmp_columns[target] = tmp
        } else if(direction === "up"){
            const target = index - 1
            if(target < 0) { 
                return columns
            }
            const tmp = tmp_columns[index]; tmp_columns[index] = tmp_columns[target]; tmp_columns[target] = tmp
        }
        return tmp_columns
    }
}

export type ApiGenRoutes = {
    routes: ApiGenRoute[]
}

export type ApiGenRoute = {
    id: number
    project_id: number
    schema_id: number
    name: string
    http_method: HTTPMethod
    path: string
    endpoint_type: EndpointType
    requests: ApiGenRouteRequest[]
    responses: string[]
}

export type ApiGenRouteRequest = {
    request_name: string 
    schema_column_name: string 
    request_type: RouteRequestType
}

export enum RouteRequestType  {
    EQUAL = "equal",
    SEARCH = "search",
    GT = "gt",
    GTE = "gte",
    LT = "lt",
    LTE = "lte",
    IN = "in",
    UPDATE = "update",
}




export type CGRest = {
    id: number
    user_id: number
    project_name: string
    service_name: string 
    description: string
    programming_language: ProgrammingLanguage 
    database: DatabaseServer
    domains: CGRestDomain[]
}

export type CGRestDomain = {
    id: number
    service_id: number
    name: string
    columns: Column[]
    primary_key: string
    timestamps: boolean
    softdelete: boolean 
}
export type CGRestDomains ={
    domains: CGRestDomain[]
}

export type Column = {
    name: string
    column_type: ColumnType
    column_size: number
    column_decimal: number
    enum_values: string[]
    nullable: boolean
}   

export namespace Column {
    export function primaryKeyDefault(database: DatabaseServer) :Column {
        switch(database) {
            case DatabaseServer.Postgres:
                return { name: "id", column_type: ColumnType.Serial, column_decimal: 0, column_size: 0, enum_values: [], nullable: false }
            case DatabaseServer.MySql:
                return { name: "id", column_type: ColumnType.Int, column_decimal: 0, column_size: 0, enum_values: [], nullable: false }
            default:
        }
        return { name: "id", column_type: ColumnType.Serial, column_decimal: 0, column_size: 0, enum_values: [], nullable: false }
    }

    export function timestampColumns(withTimestamp: boolean, softdelete: boolean) :Column[] {
        var res: Column[] = []
        if(softdelete) {
            res.push({ name: "deleted_at", column_type: ColumnType.Timestamp, column_decimal: 0, column_size: 0, enum_values: [], nullable: true })
        }
        if(withTimestamp){
            res.push({ name: "created_at", column_type: ColumnType.Timestamp, column_decimal: 0, column_size: 0, enum_values: [], nullable: false })
            res.push({ name: "updated_at", column_type: ColumnType.Timestamp, column_decimal: 0, column_size: 0, enum_values: [], nullable: false })
        }
        return res
    }

    export function fullColumnType(column: Column) :string {
        switch(column.column_type){
            case ColumnType.Decimal: 
                return ColumnType.Decimal + " (" + column.column_size.toString() + "," + column.column_decimal.toString() + ")"
            case ColumnType.Double: 
                return ColumnType.Double + " (" + column.column_size.toString() + "," + column.column_decimal.toString() + ")"
            case ColumnType.Enum:
                return ColumnType.Enum + " (" + column.enum_values.join(",") + ")"
        }
        
        return column.column_type
    }

    export function ArraySwap(columns: Column[], index: number, direction: 'up' | 'down') :Column[] {
        var tmp_columns :Column[] = [...columns]
        if(direction === "down"){
            const target = index + 1
            if(target >= columns.length) {
                return columns
            }
            const tmp = tmp_columns[index]; tmp_columns[index] = tmp_columns[target]; tmp_columns[target] = tmp
        } else if(direction === "up"){
            const target = index - 1
            if(target < 0) { 
                return columns
            }
            const tmp = tmp_columns[index]; tmp_columns[index] = tmp_columns[target]; tmp_columns[target] = tmp
        }
        return tmp_columns
    }
}


export enum ColumnType  {
    // Numeric
    Serial = "serial", SmallInt = "smallint", Int = "int", BigInt = "bigint", Decimal = "decimal", Double = "double",
    // Boolean
    Boolean = "boolean",
    // Character
    Text = "text", Varchar = "varchar", Char = "char", Enum = "enum",
    // Date
    Date = "date", DateTime = "datetime", Timestamp = "timestamp", Timestamptz = "timestamptz", Time = "time", Year = "year",
    // Special
    UUID = "uuid",
}
export namespace ColumnType {
    export function dbColumns(database: DatabaseServer): ColumnType[] {
        if (database === "postgres") {
            return [
                // Numeric
                ColumnType.Serial, ColumnType.SmallInt, ColumnType.Int, ColumnType.BigInt, ColumnType.Decimal,
                // Boolean
                ColumnType.Boolean,
                // Character
                ColumnType.Text, ColumnType.Char, ColumnType.Varchar, ColumnType.Enum,
                // Date
                ColumnType.Date, ColumnType.Time, ColumnType.Timestamp, ColumnType.Timestamptz,
                // Special
                ColumnType.UUID,
            ]
        }
        return [
            // Numeric
            ColumnType.SmallInt, ColumnType.Int, ColumnType.BigInt, ColumnType.Decimal, ColumnType.Double,
            // Boolean
            ColumnType.Boolean,
            // Character
            ColumnType.Text, ColumnType.Char, ColumnType.Varchar, ColumnType.Enum,
            // Date
            ColumnType.Date, ColumnType.DateTime, ColumnType.Time, ColumnType.Timestamp, ColumnType.Year,
        ]
    }

    export function dbColumnsWithCategory(database: DatabaseServer): Map<string, ColumnType[]> {
        switch(database) {
            case "postgres": return new Map<string, ColumnType[]>([
                ["Numeric", [ColumnType.Serial, ColumnType.SmallInt, ColumnType.Int, ColumnType.BigInt, ColumnType.Decimal]],
                ["Boolean", [ColumnType.Boolean]],
                ["Character", [ColumnType.Text, ColumnType.Char, ColumnType.Varchar, ColumnType.Enum]],
                ["Date & Time", [ColumnType.Date, ColumnType.Time, ColumnType.Timestamp, ColumnType.Timestamptz]],
                ["Special", [ColumnType.UUID]],
            ]); 
        }
        return new Map<string, ColumnType[]>([
            ["Numeric", [ColumnType.SmallInt, ColumnType.Int, ColumnType.BigInt, ColumnType.Decimal, ColumnType.Double]],
            ["Boolean", [ColumnType.Boolean]],
            ["Character", [ColumnType.Text, ColumnType.Char, ColumnType.Varchar, ColumnType.Enum]],
            ["Date & Time", [ColumnType.Date, ColumnType.DateTime, ColumnType.Time, ColumnType.Timestamp, ColumnType.Year]],
        ])
    }
}

export enum EndpointType  {
    GET = "GET",
    GETLIST = "GETLIST",
    GETLIST_PAGINATION = "GETLIST_PAGINATION",
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    DELETE = "DELETE",
}

export type CGRestRoutes = {
    routes: CGRestRoute[]
}

export type CGRestRoute = {
    id: number 
    service_id: number
    domain_id: number 
    name: string 
    http_method: HTTPMethod
    path: string 
    endpoint_type: EndpointType
    requests: CGRestRouteRequest[]
    responses: string[]
}

export enum CGRestRouteRequestType  {
    EQUAL = "equal",
    SEARCH = "search",
    BETWEEN = "between",
    GT = "gt",
    GTE = "gte",
    LT = "lt",
    LTE = "lte",
    REPEATED = "repeated",
    UPDATE = "update",
}

export type CGRestRouteRequest = {
    request_name: string 
    request_type:  CGRestRouteRequestType
    validations: string[]
}

export type CGRestRoute_CreateReq = {
    domain_id: number 
    name: string 
    http_method: HTTPMethod
    path: string 
    endpoint_type: EndpointType
    requests: CGRestRouteRequest[]
    responses: string[]
}
