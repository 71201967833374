import { ApiErrorResponse, UnknownError } from "libs/api/response";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CodegenApi } from "repositories/apigen-api";
import { ApiGenProject } from "repositories/apigen-api/param";

export default function useApiGenProject(code: string | undefined) :[ApiGenProject | undefined, ApiErrorResponse | undefined, ()=>void] {
    const [result, setResult] = useState<ApiGenProject>();
    const [error, setError] = useState<ApiErrorResponse>();
    const navigate = useNavigate()
    async function fetch(_code: string) {
      try {
        var res = await CodegenApi.ApiGenProject_GetByCode (_code);
        setResult(res.data);
      } catch (error) {
        if(error as ApiErrorResponse){
          if((error as ApiErrorResponse).http_status === 404) {
            navigate('/404')
          }
  
          toast.error((error as ApiErrorResponse).message)
          setError((error as ApiErrorResponse));
        } else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
          toast.error("Internal Error")
        }
      } finally {
      }
    }
    async function mutate(){
      if(code !==undefined){
        fetch(code)
      }
    }
    useEffect(() => {
      mutate()
    }, [code]);
  
    
    return [result, error, mutate];
  }
  
  