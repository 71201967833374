import { createContext, useState, useContext, useEffect, ReactNode } from 'react'
import { User } from '../../repositories/user-api/param';
import { UserApi } from '../../repositories/user-api';
import { ApiErrorResponse } from '../api/response';
import { useAuth } from './auth';

type user_ctx = {
    user?: User
    mutate: () => void
};

const UserContext = createContext<user_ctx>({mutate: () => {}});

export const UserProvider = (props: {
    children?: ReactNode 
}) => {
    const { isAuthenticated } = useAuth()
    const [currentUser, setCurrentUser] = useState<User>()

    const fetch_current_user = async () => {
        try {
            const resp = await UserApi.GetAuthenticated()
            setCurrentUser(resp.data)
          } catch (error) {
            if(error as ApiErrorResponse){
            } else {
              console.log("Unknown error:",error);
            }
          } finally {
          }
    }

    useEffect(() => {
        if(isAuthenticated){
            fetch_current_user()
        }
    }, [isAuthenticated])


    return (
        <UserContext.Provider value={{ user: currentUser, mutate: fetch_current_user }}>
            {props.children}
        </UserContext.Provider>
    )
}

export const useCurrentUser = () => useContext(UserContext)
