import { Badge } from 'flowbite-react'
import DashboardLayout from 'components/layout/dashboard-layout';
import { SuperSEO } from 'react-super-seo';
import { Link } from 'react-router-dom';
import { FiArrowRight } from "react-icons/fi";

const DashboardMainPage = () => {
  return <DashboardLayout>
    <SuperSEO
      title="Kodingless Dashboard"
      description="Kodingless Dashboard"
      lang="en"
    />
  <h2 className="mb-4 text-xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-3xl dark:text-white">
    Backend Tools
  </h2>
  <hr className="mb-2"></hr>
  <p className="mb-5 text-base text-gray-500 dark:text-gray-400 sm:text-lg">
    Developer tools for backend engineers like you to make you coding quicker by providing code-generated source code so you have flexibility for customization of the code
  </p>

  {/* https://web3templates.com/components/cards */}
  <div className="flex items-center ">
    <div className="container max-w-6xl px-5 mx-auto ">
      <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex items-center justify-between p-5 bg-tertiary-300 hover:bg-tertiary-500 rounded shadow-sm cursor-pointer">
          <Link to="/dashboard/be/api-generator">
            <div className="text-sm text-white">API Generator</div>
            <div className="flex items-center pt-1">
                <div className="text-xl font-medium text-white ">0 Projects</div>
            </div>
            
          </Link>
          <div className="text-white">
            <FiArrowRight></FiArrowRight>
          </div>
        </div>
        <div className="flex items-center justify-between p-5 bg-light-300 hover:bg-light-300 rounded shadow-sm cursor-pointer">
          <div>
            
            <div className="text-sm text-white">DB Seeder</div>
            <div className="flex items-center pt-1">
                <div className="text-xl font-medium text-white ">0 Projects</div>
            </div>
            
          </div>
          <div className="text-white">
            <Badge color={"warning"}>Coming<br/>Soon</Badge>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
</DashboardLayout>
};

export default DashboardMainPage;
