import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { Flowbite } from "flowbite-react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Homepage from "pages/landing/home/home.page";
import { AuthProvider } from "libs/context/auth";
import { UserProvider } from "libs/context/user";
import PageNotFound from "pages/other/404.page";
// import CategoryPage from "pages/category";
// import ArticlePage from "pages/article";
import LoginPage from "pages/dashboard/auth/login.page";
import { flowbite_theme } from "components/theme/flowbite_theme";
import DashboardMainPage from "pages/dashboard/main";
import BEApiGeneratorIndexPage from "pages/dashboard/be-api-generator/index";
import BEApiGeneratorOverviewPage from "pages/dashboard/be-api-generator/view/overview.page";
import BEApiGeneratorSchemaPage from "pages/dashboard/be-api-generator/view/schema.page";
import BEApiGeneratorRoutesPage from "pages/dashboard/be-api-generator/view/routes.page";
import BEApiGeneratorGeneratePage from "pages/dashboard/be-api-generator/view/codegen.page";
import { WorkspaceProvider } from "libs/context/workspace";
import ProfilePage from "pages/dashboard/accounts/profile";
import WorkspaceOverviewPage from "pages/dashboard/workspace/overview.page";
import RegisterPage from "pages/dashboard/auth/register.page";
import UserVerifyPage from "pages/dashboard/auth/user-verify.page";

const MainRoutes = () => {
  let routes: RouteObject[] = [
    { path: "/", element: <Homepage /> },
    { path: "/dashboard", element: <DashboardMainPage /> },
    { path: "/dashboard/be/api-generator", element: <BEApiGeneratorIndexPage /> },
    { path: "/dashboard/be/api-generator/:code", element: <BEApiGeneratorOverviewPage /> },
    { path: "/dashboard/be/api-generator/:code/schema", element: <BEApiGeneratorSchemaPage /> },
    { path: "/dashboard/be/api-generator/:code/routes", element: <BEApiGeneratorRoutesPage /> },
    { path: "/dashboard/be/api-generator/:code/generate", element: <BEApiGeneratorGeneratePage /> },
    { path: "/dashboard/profile", element: <ProfilePage /> },
    { path: "/dashboard/workspace", element: <WorkspaceOverviewPage /> },
    
    { path: "/login", element:  <LoginPage />  },
    { path: "/register", element:  <RegisterPage />  },
    { path: "/verify-account", element:  <UserVerifyPage />  },
    
    { path: "/404", element:  <PageNotFound />  },

    { path: "*", element:  <Navigate to="/404" />  },
  ];

  let element = useRoutes(routes);

  return <AuthProvider config={{redirect_to: '/dashboard', login_path: "/login"}}>
    <UserProvider>
      
        <Flowbite theme={flowbite_theme}>
          <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
          <WorkspaceProvider>
          {element}
          </WorkspaceProvider>
        </Flowbite>
    </UserProvider>
  </AuthProvider>
}

// const MainRoutes = () => {
//   const { loading, modalShow } = useAppSelector((state) => state.globalState);
//   const { token } = useAppSelector((state) => state.auth);
//   const [protectedRoute] = useProtectedRoute();


//   // company_context.
//   let routes: RouteObject[] = [
//     {
//       path: "/",
//       element: <Login />,
//     },
//     { path: "/404", element:  <div>Not Found</div>  },
//     { path: "*", element:  <Navigate to="/" />  },
//   ];

//   let element = useRoutes(token ? protectedRoute : routes);
//   // useEffect(() => {
//   //   if (token) navigate("/transaction");
//   // }, [token]);

//   return (<Flowbite theme={flowbite_theme}>
//       <div className={modalShow ? "overflow-hidden" : ""}>
//         { loading && (
//           <div className="fixed w-full h-screen flex items-center justify-center z-50 bg-slate-500/50">
//             <Spinner></Spinner>
//           </div>
//         )}
        
//         <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
//         {/* {token === null && <Navigate to="/" replace={true} />} */}
//         {element}
//       </div>
//     </Flowbite>
//   );
// };

export default MainRoutes;
