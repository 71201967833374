import React, { useEffect, useState } from "react";
import { Button, Label, Modal, Select, Spinner, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import { useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { WorkspaceMember } from "repositories/workspace-api/workspace";
import { WorkspaceApi } from "repositories/workspace-api";

const AddWorkspaceMemberModal = (props: {state: {show: boolean, workspace_id: number}, setState: React.Dispatch<React.SetStateAction<{show: boolean, workspace_id: number}>>, onSuccess: () => void}) => {
  const [submitting, setSubmitting] = useState(false)

  const ValidationSchema = yup.object().shape({
    email: yup.string().required(),
    role: yup.string().is(['owner', 'member']),
  })

  const { register, handleSubmit, formState: { errors  }, reset, setError } = useForm<WorkspaceMember>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });
  
  useEffect(() => {
    if(props.state.show){ reset() }
  }, [props.state.show]);

  const onSubmitHandler = async (data: WorkspaceMember) => {
    if(props.state.workspace_id <= 0) return
    try {
      setSubmitting(true)
      await WorkspaceApi.WorkspaceApi_AddMember(props.state.workspace_id, data.email, data.role)
      props.onSuccess()
      reset()
      props.setState({...props.state, show:false})
      toast.success("Create Project Success")
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "email": setError("email", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "role": setError("role", { type: "focus", message: e.message }, { shouldFocus: true }); break;
         }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };
  
  return <>
      <Modal show={props.state.show} onClose={ ()=> { props.setState({...props.state, show:false}) }} >
          <Modal.Header>Add Member</Modal.Header>
          <Modal.Body>
            <form key="add-member-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)} >
              <div>
                <div className="mb-2 block"> <Label htmlFor="email" value="Email"/></div>
                <TextInput {...register("email")} id="email"  defaultValue={""}/>
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.email && <>{errors.email.message}</>) }</p>
              </div>
              
              <div id="select">
                <div className="mb-2 block"> <Label htmlFor="role" value="Role" /></div>
                <Select {...register("role")} id="role" required={true} defaultValue={"member"}>
                  <option value={ "member" }> Member </option>
                  <option value={ "owner" }> Owner </option>
                </Select>
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.role && <>{errors.role.message}</>) }</p>
              </div>

              <div className="self-center">
                <Button type="submit" color="primary" disabled={submitting}>
                  Save
                  {submitting && (
                  <div className="ml-3">
                    <Spinner size="sm" light={true} />
                  </div>
                  )}
                </Button>
              </div>
            </form> 
          </Modal.Body>
      </Modal>
  </>
}

export default AddWorkspaceMemberModal