import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react'
import Cookies from 'js-cookie'
import { UserToken } from '../../repositories/usertoken-api/param';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const KEY_AUTH_TOKEN = "auth_token"
const KEY_REFRESH_TOKEN = "refresh_token"

type auth_ctx = {
    isAuthenticated: boolean
    token?: UserToken
    login: (token: UserToken) => void
    logout: () => void
};

const AuthContext = createContext<auth_ctx>({ 
    isAuthenticated: false, 
    login: async (token) => { 
        await Cookies.set(KEY_AUTH_TOKEN, token.auth_token)
        await Cookies.set(KEY_REFRESH_TOKEN, token.refresh_token)
    }, 
    logout: () => {
        Cookies.remove('token')
        Cookies.remove('refresh_token')
    } });

export const AuthProvider = (props: {
    children?: ReactNode 
    config?: {
        login_path?: string,
        redirect_to?: string,
    },
}) => {
    const location = useLocation()
    const { redirect } = useParams()
    const navigate = useNavigate();
    
    const [isAuth, setIsAuth] = useState(false)
    const [token, setToken] = useState<UserToken>()

    const login_path =  props.config ? props.config.login_path ? props.config.login_path : '/login' : '/login'
    const redirect_path = props.config ? props.config.redirect_to ? props.config.redirect_to : '/' : '/'

    useEffect(() => {
        const auth_token = Cookies.get(KEY_AUTH_TOKEN)
        const refresh_token = Cookies.get(KEY_REFRESH_TOKEN)
        if (auth_token) {
            setIsAuth(true)
            setToken({auth_token: auth_token, refresh_token: refresh_token ?? ''})
            if ( location.pathname === login_path) {
                navigate(redirect ?? redirect_path)
            }
        } else {
            if ( location.pathname !== login_path && location.pathname.startsWith('/dashboard')) {
                navigate(login_path +'?redirect='+encodeURI(location.pathname))
            }
        }
    }, [])

    const login = async (token: UserToken) => {
        Cookies.set(KEY_AUTH_TOKEN, token.auth_token)
        Cookies.set(KEY_REFRESH_TOKEN, token.refresh_token)
        setIsAuth(true)
        setToken(token)
        navigate(redirect ?? redirect_path)
    }

    const logout = () => {
        Cookies.remove(KEY_AUTH_TOKEN)
        Cookies.remove(KEY_REFRESH_TOKEN)
        Cookies.remove("selected_workspace")
        setIsAuth(false)
        setToken(undefined)
        navigate(login_path)
    }

    return (
        <AuthContext.Provider value={{ 
            isAuthenticated: isAuth, 
            token:token,
            login, 
            logout, 
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)
