import { Button, Modal, Spinner } from 'flowbite-react'
import { useState } from 'react';
import { ApiErrorResponse } from 'libs/api/response';
import { toast } from 'react-toastify';
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import logoLogin from "assets/images/logo-with-text.svg";
import { UserApi } from 'repositories/user-api';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';

const RegisterPage = () => {
  const [isLoading, setIsLoading] =  useState(false)
  const [registerSuccessModal, setRegisterSuccessModal] =  useState(false)

  const ValidationSchema = yup.object().shape({
    email: yup.string().email().required(),
    name: yup.string().required(),
    password: yup.string().required(),
    confirm_password: yup.string().required().oneOf([yup.ref("password")], "Passwords doesnt match"),
  })
  
  const { register, handleSubmit, formState: { errors }, setError } = useForm<{email: string, name: string, password: string, confirm_password: string}>({ mode: 'all', resolver: yupResolver(ValidationSchema) });

  const onSubmitHandler = async (data: {email: string, name: string, password: string, confirm_password: string}) => {
    try {
      setIsLoading(true)
      await UserApi.AuthApi_Register(data.email, data.name, data.password)
      toast.success("Register success, please check your email inbox to verify your account")
      setRegisterSuccessModal(true)
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "email" : 
              setError("email", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
            case "name" : 
              setError("email", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
            case "password": 
              setError("password", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
            case "confirm_password": 
              setError("confirm_password", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
        }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setIsLoading(false)
    }
  };

  return <>
    <SuperSEO
      title="Register to Kodingless"
      description="Register to Kodingless"
      lang="en"
    />
    <RegisterSuccessModal show={registerSuccessModal} setShow={setRegisterSuccessModal}></RegisterSuccessModal>
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className='mb-5'>
          <Link to="/" className=''>
            <img src={logoLogin} alt="logo" className="max-w-[400px]" />
          </Link>
        </div>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                      Registration
                  </h1>
                  <form className="space-y-4 md:space-y-6" onSubmit={ handleSubmit(onSubmitHandler) }>
                      <div>
                          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                          <input {...register("email")} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="email@domain.com" required />
                          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.email && <>{errors.email.message}</>)}</p>
                      </div>
                      <div>
                          <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                          <input {...register("name")} type="name" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Put your name here..." required />
                          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>)}</p>
                      </div>
                      <div>
                          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                          <input {...register("password")} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.password && <>{errors.password.message}</>)}</p>
                      </div>
                      <div>
                          <label htmlFor="confirm_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirmation Password</label>
                          <input {...register("confirm_password")} type="password" name="confirm_password" id="confirm_password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.confirm_password && <>{errors.confirm_password.message}</>)}</p>
                      </div>
                     
                      <Button type="submit" color={"primary"} disabled={isLoading} style={{width: "100%"}} >
                        Register
                        {isLoading && (
                        <div className="ml-3"><Spinner size="sm" light={true} /></div>
                        )}
                      </Button>

                      <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                          Aleady have an account? <Link to="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login</Link>
                      </p>
                      
                  </form>
              </div>
          </div>
      </div>
    </section>
  </>
};

export default RegisterPage;


const RegisterSuccessModal = (props: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const navigate = useNavigate()
  return <>
    <Modal show={props.show} size="sm" className="" onClose={async () => {
      await props.setShow(false)
      navigate('/login')
    }} 
    >
      <Modal.Header>Registration Success</Modal.Header>
      <Modal.Body>
        <div className='mb-4'>You registration has succeed. Please check your email inbox to verify your account. The email might be on promotion tab, updates or even spam, check all of them. You can't login until you verify your account.</div>
        <div className="">
          <Button color="failure" className="w-full" onClick={async () => {
            await props.setShow(false)
            navigate('/login')
          }}>Login Now</Button>
        </div>
      </Modal.Body>
    </Modal>
  </>
}