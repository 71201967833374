import React, { useEffect, useState } from "react";
import { Button, Checkbox, Label, Modal, Select, TextInput } from "flowbite-react";
// import { Column, ColumnType, DatabaseServer } from "repositories/codegen-rest-api/param";
import { useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { slugify } from "libs/strings/slugify";
import { Tag, WithContext as ReactTags } from 'react-tag-input';
import { ApiGenSchemaColumn, ColumnType, DatabaseServer } from "repositories/apigen-api/param";

export interface SchemaColumnEditModalState {
  show: boolean 
  current: ApiGenSchemaColumn
  onSuccess: (data: ApiGenSchemaColumn) => void
}

const SchemaColumnEditModal = (props: {state: SchemaColumnEditModalState, setState: React.Dispatch<React.SetStateAction<SchemaColumnEditModalState>>, database: DatabaseServer, current_columns: ApiGenSchemaColumn[]}) => {
  const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
    column_type: yup.string().required(),
    column_size: yup.number().min(0),
    column_decimal: yup.number().min(0),
    enum_values: yup.array().of(yup.string()).when("column_type", {
      is: "enum",
      then: yup.array().of(yup.string()).test({
        message: 'enum values is required',
        test:  arr => arr !== undefined && arr.length > 0,
      })
    }),
    primary_key: yup.boolean(),
    nullable: yup.boolean(),
  })
  const { control, register, handleSubmit, formState: { errors }, setError, reset, setValue, getValues } = useForm<ApiGenSchemaColumn>({ mode: 'onChange', reValidateMode: 'onChange', resolver: yupResolver(ValidationSchema) });
  const [columnType, setColumnType] = useState("text")
  const [enumValues, setEnumValues] = useState<Tag[]>([])

  useEffect(() => {
    if(props.state.show){ 
      reset()
      setValue("name", props.state.current.name)
      setValue("column_type", props.state.current.column_type)
      setValue("column_size", props.state.current.column_size)
      setValue("column_decimal", props.state.current.column_decimal)
      setValue("enum_values", props.state.current.enum_values)
      setValue("nullable", props.state.current.nullable)
      setColumnType(props.state.current.column_type)
      if (props.state.current.enum_values !== undefined){
        setEnumValues(props.state.current.enum_values.map( (e) => { var x:Tag = {id:e, text:e}; return x } ))
      } else {
        setEnumValues([])
      }
    }
  }, [props.state.show]);

  const onSubmitHandler = async (data: ApiGenSchemaColumn) => {
    if(props.state.current.name !== data.name){
      if( props.current_columns.findIndex( (e) => e.name === data.name ) > -1  ) {
        setError("name", { type: "focus", message: "duplicate column name" }, { shouldFocus: true });
      } else if(data.name === "id") {
        setError("name", { type: "focus", message: "cannot use reserved name" }, { shouldFocus: true });
      } else {
        props.state.onSuccess(data)
        props.setState({...props.state, show: false})    
      }
    } else {
      props.state.onSuccess(data)
      props.setState({...props.state, show: false})  
    }
  };
  
  return <>
      <Modal show={props.state.show} size="xl" onClose={ ()=> { props.setState({...props.state, show: false}) }} >
        <Modal.Header>Update Column</Modal.Header>
        <Modal.Body>
          <form key="add-column-form" className="flex flex-col gap-4 mt-4" onSubmit={handleSubmit(onSubmitHandler)}>
            <div>
                <div className="mb-2 block"> <Label htmlFor="name" value="Field / Column Name"/></div>
                <TextInput {...register("name")} id="name"  defaultValue={""} placeholder="ex: Product Api" onChange={(e) => {
                  setValue("name", slugify(e.target.value, "_"));
                }}/>
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>) }</p>
            </div>
            <div>
              <div className="mb-2 block"> <Label htmlFor="column_type" value="Type"/></div>
              <div className="flex items-center gap-2">
                <div className="grow">
                  <Select {...register(`column_type`)} defaultValue={columnType} onChange={(e)=>setColumnType(e.target.value)}>
                    {
                      props.database === "postgres" ? 
                      Array.from(ColumnType.dbColumnsWithCategory(DatabaseServer.Postgres)).map( ([key, values], i) => {
                        return <optgroup key={"opt-group-"+i.toString()} label={key}>
                        { values.map( (e, j) => <option key={"opt-" + j.toString()} value={e}> {e} </option>) }  
                        </optgroup>
                      }):
                      props.database === "mysql" ? 
                      Array.from(ColumnType.dbColumnsWithCategory(DatabaseServer.MySql)).map( ([key, values], i) => {
                        return <optgroup key={"opt-group-"+i.toString()} label={key}>
                        { values.map( (e, j) => <option key={"opt-" + j.toString()} value={e}> {e} </option>) }  
                        </optgroup>
                      }):
                      null
                    }
                  </Select>
                </div>
                <div className={ [ColumnType.Decimal, ColumnType.Double ].filter( (e) => e === columnType ).length > 0 ? 'grow-0' : 'grow-0 hidden' }>
                  <TextInput {...register("column_size")} type="number" defaultValue={0} max={255} placeholder="Length / Size"/>
                </div>
                <div className={ [ColumnType.Decimal, ColumnType.Double ].filter( (e) => e === columnType ).length > 0 ? 'grow-0' : 'grow-0 hidden'}>
                  <TextInput {...register("column_decimal")} type="number"  defaultValue={0} placeholder="Decimal" />
                </div>
              </div>
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">{ 
                (errors?.column_type && <>{errors.column_type.message}</>) ||
                (errors?.column_size && <>{errors.column_size.message}</>) || 
                (errors?.column_decimal && <>{errors.column_decimal.message}</>)
              }</p>
            </div>
            <div className={ columnType === ColumnType.Enum ? "" : "hidden" }>
              <div className="mb-2 block"> <Label htmlFor="name" value="Enum Values"/></div>
              <ReactTags
                tags={enumValues}
                delimiters={[188, 13]}
                handleDelete={ (i) => {
                  const newEnumValues = enumValues.filter((tag, index) => index !== i)
                  setEnumValues(newEnumValues) 
                  if(newEnumValues.length > 1) {
                    setValue('enum_values', newEnumValues.map(e => e.id))
                  } else {
                    setValue('enum_values', [])
                  }
                }}
                handleAddition={ (i) => {
                  const newEnumValues = [...enumValues, i]
                  setEnumValues(newEnumValues)
                  setValue('enum_values', newEnumValues.map(e => e.id))
                } }
                handleDrag={(tag, currPos, newPos) =>{
                  const newEnumValues = enumValues.slice();
                  newEnumValues.splice(currPos, 1);
                  newEnumValues.splice(newPos, 0, tag);
                  setEnumValues(newEnumValues);
                  setValue('enum_values', newEnumValues.map(e => e.id))
                }}
                placeholder="Press enter to add enum value"
                inputFieldPosition="top"
                autocomplete
                classNames={
                  { 
                    tag:"flex w-fit h-fit items-center gap-1 font-semibold bg-primary-100 text-primary-800 dark:bg-primary-200 dark:text-primary-800 group-hover:bg-primary-200 dark:group-hover:bg-primary-300 rounded px-2 py-0.5 p-1 text-xs", 
                    tagInputField: "block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 rounded-lg p-2.5 text-sm",
                    selected:"flex gap-1 mt-2"
                  }
                }
              />
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.enum_values && <>{errors.enum_values.message}</>) }</p>
            </div>
            
            <div>
              <div className="flex items-center gap-2">
                <Checkbox
                  {...register("nullable")}
                  defaultChecked={false}
                />
                <Label htmlFor="nullable"> Nullable </Label>
              </div>
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">{ (errors?.nullable && <>{errors.nullable.message}</>) }</p>
            </div>
            
            <div className="self-center">
                <Button type="submit" color="primary"> Update </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
  </>
}

export default SchemaColumnEditModal;

