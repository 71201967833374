import React, { useState } from "react";
import { Button, Modal, Spinner } from "flowbite-react";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import { CodegenApi } from "repositories/apigen-api";
import { ApiGenSchema } from "repositories/apigen-api/param";

export interface CreateCrudModalState {
  show: boolean
  schema?: ApiGenSchema
}
const CreateCrudModal = (props: {state: CreateCrudModalState, setState: React.Dispatch<React.SetStateAction<CreateCrudModalState>>, onSuccess: () => void}) => {
  const [submitting, setSubmitting] = useState(false)
  const onSubmitHandler = async () => {
    if(props.state.schema === undefined) return
    try {
      setSubmitting(true)
      await CodegenApi.ApiGenRoute_CreateCrud(props.state.schema.id)
      toast.success("Generate success...")
      props.setState({...props.state, show: false})
      props.onSuccess()
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }      
    } finally {
      setSubmitting(false)
    }
  };

  return <>
    <Modal show={props.state.show} size="sm" onClose={ ()=> props.setState({...props.state, show: false})} >
      <Modal.Header>Create Confirmation</Modal.Header>
      <Modal.Body>
        {
        props.state.schema !== undefined &&
        <form key="create-crud-form" className="flex flex-col gap-4">
          <div>
            <div className="">This action will generating 5 endpoints.</div>
            <ol>
              <li>GET /{props.state.schema.name}</li>
              <li>GET /{props.state.schema.name}/:{props.state.schema.primary_key}</li>
              <li>POST /{props.state.schema.name}</li>
              <li>PUT /{props.state.schema.name}/:{props.state.schema.primary_key}</li>
              <li>DELETE /{props.state.schema.name}/:{props.state.schema.primary_key}</li>
            </ol>
          </div>
          <div className="flex flex-wrap gap-4 self-center">
            <Button color="light" onClick={()=>{props.setState({...props.state, show: false})}}>
              Cancel
            </Button>
            <Button color={"success"} onClick={onSubmitHandler} disabled={submitting}>
              Confirm
              {submitting && (
              <div className="ml-3">
                  <Spinner size="sm" light={true} />
              </div>
              )}
            </Button>
          </div>
        </form> 
        }
        
      </Modal.Body>
    </Modal>
  </>
}

export default CreateCrudModal