import React, { useEffect, useState } from "react";
import { Button, Label, Modal, Spinner, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import { WorkspaceApi } from "repositories/workspace-api";
import { useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { MyWorkspace } from "repositories/workspace-api/workspace";

const UpdateWorkspaceNameModal = (props: {state: {show: boolean, workspace: MyWorkspace}, setState: React.Dispatch<React.SetStateAction<{show: boolean, workspace: MyWorkspace}>>, onSuccess: () => void}) => {
  const [submitting, setSubmitting] = useState(false)

  const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
  })
  const { register, handleSubmit, formState: { errors  }, setValue, reset, setError } = useForm<{name: string}>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  useEffect(() => {
    if(props.state.show) setValue('name', props.state.workspace.name, {shouldValidate:true})
  }, [props.state.show])

  const onSubmitHandler = async (data: {name: string}) => {
    try {
      setSubmitting(true)
      await WorkspaceApi.WorkspaceApi_UpdateWorkspace(props.state.workspace.id, data.name)
      props.onSuccess()
      reset()
      props.setState({...props.state, show:false})
      toast.success("Update Success")
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "name": setError("name", { type: "focus", message: e.message }, { shouldFocus: true }); break;
         }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };
  
  return <>
      <Modal show={props.state.show} onClose={ ()=> { props.setState({...props.state, show:false}) }} >
          <Modal.Header>Update Workspace</Modal.Header>
          <Modal.Body>
            <form key="update-workspace-name-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)} >
              <div>
                <div className="mb-2 block"> <Label htmlFor="name" value="Name"/></div>
                <TextInput {...register("name")} id="name" />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>) }</p>
              </div>
              
              <div className="self-center">
                <Button type="submit" color="primary" disabled={submitting}>
                  Update
                  {submitting && (
                  <div className="ml-3">
                    <Spinner size="sm" light={true} />
                  </div>
                  )}
                </Button>
              </div>
            </form> 
          </Modal.Body>
      </Modal>
  </>
}

export default UpdateWorkspaceNameModal