import React, { ReactNode, useState } from "react";
import { FiArrowLeftCircle, FiTrello, FiCheckCircle, FiChevronDown, FiChevronUp, FiCode, FiHome, FiUser, FiRefreshCw } from "react-icons/fi";
import { Avatar, Button, Dropdown, Modal, Navbar, Spinner, Table } from "flowbite-react";
import { useAuth } from "../../libs/context/auth";
import { useCurrentUser } from "../../libs/context/user";
import { Link, useLocation } from "react-router-dom";
import { useWorkspace, workspace_ctx } from "libs/context/workspace";
import { capitalCase } from "change-case";
import { FaSlack } from "react-icons/fa";

const DashboardLayout = (props :{children? :ReactNode}) => {
    const location = useLocation();
    const { logout } = useAuth()
    const [sidebarStatus, setSidebarStatus] = useState(true);
    const [workspaceModal, setWorkspaceModal] = useState(false);
    const current_user = useCurrentUser()
    const user_workspace = useWorkspace()

    // https://tailwindcomponents.com/component/responsive-sidebar-tailwindcss
    return (
      <>
        { user_workspace.workspace === undefined  && 
        <div className="absolute w-full h-full bg-light-500/50 z-[100]">
          <div className="flex h-screen">
            <div className="m-auto">
              <Spinner />
            </div>
          </div>
        </div>
        }

        <ChangeWorkspaceModal show={workspaceModal} setShow={setWorkspaceModal} user_workspace={user_workspace}></ChangeWorkspaceModal>
        <div
          className={
            "sidebar fixed top-0 bottom-0 lg:left-0 p-2 w-[300px] overflow-y-auto text-center bg-primary-900 z-50 " +
            (sidebarStatus ? "" : "hidden")
          }
        >
          <div className="text-gray-100 text-xl">
            <div className="p-2.5 mt-1 flex items-center">
              {/* <Image src={Logo} alt="gajiku" className="h-10" height={"50%"} width={"40%"} /> */}
              <h1 className="font-bold text-gray-200 text-[15px] ml-3">
                Kodingless Dashboard 
              </h1>
              <FiArrowLeftCircle className="cursor-pointer absolute right-[10px] bg-primary-900" onClick={() => setSidebarStatus(false) }></FiArrowLeftCircle>
            </div>
          </div>
          <div className="my-2 bg-primary-300 h-[1px]"></div>

          <div className={"p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-tertiary-300 text-white"} 
            onClick={() => setWorkspaceModal(true)}
          >
            <div className="flex flex-col justify-between w-full items-center">
              <div className="text-[15px] text-white font-bold mr-auto">
                {user_workspace.workspace?.name ?? ""}
              </div>
              <div className="text-xs mr-auto">Plan: {capitalCase(user_workspace.workspace?.plan_name ?? "Free")}</div>
            </div>
            

            <FiRefreshCw></FiRefreshCw>
          </div>

          {/* <SidebarItem title={workspace_ctx.workspace?.name ?? ""} to={"#"} icon={<FiRefreshCw></FiRefreshCw>}></SidebarItem> */}
          <div className="my-2 bg-primary-300 h-[1px]"></div>
          <SidebarItem title="Dashboard" to={"/dashboard"} icon={<FiHome></FiHome>} active={location.pathname === '/dashboard'}></SidebarItem>
          <SidebarItem title="Backend Tools" to={"#"} icon={<FiCode></FiCode>} defaultSubmenuOpen={true}>
            <SidebarItem title="API Generator" to={"/dashboard/be/api-generator"} active={location.pathname.startsWith('/dashboard/be/api-generator')}></SidebarItem>
          </SidebarItem>
          <div className="my-2 bg-primary-300 h-[1px]"></div>
          <SidebarItem title="Account & Profile" to={"/dashboard/profile"} icon={<FiUser></FiUser>} active={location.pathname === '/dashboard/profile'}></SidebarItem>
          <SidebarItem title="Workspace & Plan" to={"/dashboard/workspace"} icon={<FiCheckCircle></FiCheckCircle>} active={location.pathname === '/dashboard/workspace'}></SidebarItem>
          
          <div className="my-2 bg-primary-300 h-[1px]"></div>
          <a href={"https://kodingless.canny.io/roadmaps"} target={"_blank"} rel="noreferrer" >
            <div className={"p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-tertiary-300 text-white"}>
              <FiTrello></FiTrello>
              <span className="text-[15px] ml-4 text-white font-bold">
                Roadmaps
              </span>
            </div>
          </a>
          <a href={"https://join.slack.com/t/kodingless/shared_invite/zt-1mhecpb11-7MnvtwgzJ~3_lQAOOpxmoQ"} target={"_blank"} rel="noreferrer" >
            <div className={"p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-tertiary-300 text-white"}>
              <FaSlack></FaSlack>
              <span className="text-[15px] ml-4 text-white font-bold">
                Join Slack
              </span>
            </div>
          </a>
        </div>
        <div className={"w-100 h-screen " + (sidebarStatus ? "sm:pl-[300px] " : "")} >
          <Navbar fluid={true} rounded={false} border={true}>
            <div className="flex ">
            { !sidebarStatus &&
              <Navbar.Toggle
                aria-hidden={false}
                style={{ display: "block" }}
                onClick={() => setSidebarStatus(!sidebarStatus)}
              />
            }
              {/* <div className="self-center ml-2">
                {
                current_user.user?.plan === PlanType.Pro ? 
                <Badge color="success" size="sm"> Plan: Pro </Badge> :
                current_user.user?.plan === PlanType.Business ? 
                <Badge color="success" size="sm"> Plan: Business </Badge> :
                <Badge color="warning" size="sm"> Plan: Free </Badge> 
                }
              </div> */}
            </div>
            
            <div className="flex md:order-2">
              <Dropdown
                arrowIcon={true}
                inline={true}
                label={
                  <>
                    <Avatar
                      alt="User settings"
                      img={""}
                      rounded={true}
                    />
                    <div className="ml-2 self-start text-left">
                      <div className="text-sm">{current_user.user?.name}</div>
                      <div className="font-bold text-sm">
                        {current_user.user?.email}
                      </div>
                    </div>
                  </>
                }
              >
                <Dropdown.Item onClick={()=>{ logout() }}>
                  {" "}
                  Logout
                </Dropdown.Item>
              </Dropdown>
            </div>
          </Navbar>
          <div className="p-10">
            {props.children}
          </div>
        </div>
      </>
    );
  };
  
  const SidebarItem = (props: {
    title: string;
    to: string;
    icon?: JSX.Element;
    children?: ReactNode;
    active?: boolean;
    defaultSubmenuOpen?: boolean;
  }) => {
    const [submenu, setSubmenu] = useState(props.defaultSubmenuOpen ?? false);
  
    if (React.Children.count(props.children) == 0) {
      return (
        <Link to={props.to}>
          <div className={"p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-tertiary-300 text-white" + (props.active ? props.active ? ' bg-tertiary-300' : '' : '' )}>
            {props.icon}
            <span className="text-[15px] ml-4 text-white font-bold">
              {props.title}
            </span>
          </div>
        </Link>
      );
    }
  
    return (
      <>
        <div>
          <div
            className={"p-2.5 mt-3 flex items-center rounded-md px-4 duration-300 cursor-pointer hover:bg-tertiary-300 text-white" + (props.active ? props.active ? ' bg-tertiary-300' : '' : '' )}
            onClick={() => {
              setSubmenu(!submenu);
            }}
          >
            {props.icon}
            <div className="flex justify-between w-full items-center">
              <span className="text-[15px] ml-4 text-white font-bold">
                {props.title}
              </span>
              <span className="text-sm rotate-180" id="arrow">
                {submenu ? <FiChevronDown></FiChevronDown>:<FiChevronUp></FiChevronUp> }
              </span>
            </div>
          </div>
          <div className={submenu ? "" : "hidden"}>
            <div className="ml-4">{props.children}</div>
          </div>
        </div>
      </>
    );
  };
  export default DashboardLayout;


  const ChangeWorkspaceModal = (props: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, user_workspace: workspace_ctx}) => {
    const workspace_list = props.user_workspace.workspaceList
    const current_workspace_id = props.user_workspace.workspace !== undefined ? props.user_workspace.workspace.id : -1 
    return <>
      <Modal show={props.show} size="sm" className="" onClose={ ()=> props.setShow(false)} >
        <Modal.Header>Change Workspace</Modal.Header>
        <Modal.Body className="!p-0">
          <Table>
            <Table.Body className="divide-y">
              {
                workspace_list.map((item) => 
                <Table.Row key={`workspace-list-${item.id.toString()}`} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell 
                    className={`whitespace-nowrap font-medium text-gray-900 hover:bg-light-300 ${current_workspace_id === item.id ? ' bg-light-300 ' : ' cursor-pointer '}`}
                    onClick={() => {
                      if(current_workspace_id === item.id){ return }
                      props.user_workspace.setWorkspace(item)
                      props.setShow(false)
                    }}
                  >
                  {item.name} ({item.role})
                  {
                  props.user_workspace.workspace !== undefined && props.user_workspace.workspace.id === item.id ?
                    <div className="text-sm font-light">current</div>:
                    <div className="text-sm font-light">choose this workspace</div>
                  }
                  </Table.Cell>
                </Table.Row>)
              }
            </Table.Body>
          </Table>
          <div className="py-2">
            <Button color="failure" className="w-full" onClick={() => props.setShow(false)}>Close</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  }