import { ThemeProps } from "flowbite-react";

export const flowbite_theme: ThemeProps = {
    theme: {
      modal: {
        content: {
          base: 'relative h-full w-full p-4',
          inner: 'relative rounded-lg bg-white shadow dark:bg-gray-700',
        },
      },
      sidebar: {
        item: {
          base: 'flex items-center justify-center rounded-lg p-2 text-base font-normal text-gray-900 hover:text-white hover:bg-primary dark:text-white dark:hover:bg-gray-700',
          active: 'bg-primary dark:bg-primary-700 text-white',
          collapsed: {
            insideCollapse: 'group w-full pl-8 transition duration-75',
            noIcon: 'font-bold',
          },
          content: {
            base: 'px-3 flex-1 whitespace-nowrap',
          },
          icon: {
            base: 'h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white',
            active: 'text-gray-700 dark:text-gray-100 text-white',
          },
        },
      },
      button: {
        color: {
          dark: 'text-white bg-gray-800 border border-transparent hover:bg-gray-900 focus:ring-4 focus:ring-gray-300 disabled:hover:bg-gray-800 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-800 dark:border-gray-700 dark:disabled:hover:bg-gray-800',
          failure:
            'text-white bg-danger-700 border border-transparent hover:bg-danger-800 focus:ring-4 focus:ring-danger-300 disabled:hover:bg-danger-800 dark:bg-danger-600 dark:hover:bg-danger-700 dark:focus:ring-danger-900 dark:disabled:hover:bg-danger-600',
          gray: 'text-gray-900 bg-white border border-gray-200 hover:bg-gray-100 hover:text-blue-700 disabled:hover:bg-white focus:ring-blue-700 focus:text-blue-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-2 dark:disabled:hover:bg-gray-800',
          info: 'text-white bg-blue-700 border border-transparent hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:disabled:hover:bg-blue-600',
          light:
            'text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 disabled:hover:bg-white dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700',
          purple:
            'text-white bg-purple-700 border border-transparent hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 disabled:hover:bg-purple-700 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 dark:disabled:hover:bg-purple-600',
          success:
            'text-white bg-success-700 border border-transparent hover:bg-success-800 focus:ring-4 focus:ring-success-300 disabled:hover:bg-success-700 dark:bg-success-600 dark:hover:bg-success-700 dark:focus:ring-success-800 dark:disabled:hover:bg-success-600',
          warning:
            'text-white bg-warning-400 border border-transparent hover:bg-warning-500 focus:ring-4 focus:ring-warning-300 disabled:hover:bg-warning-400 dark:focus:ring-warning-900 dark:disabled:hover:bg-warning-400',
          
          primary: 'text-white bg-primary-700 border border-transparent hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 disabled:hover:bg-primary-700 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 dark:disabled:hover:bg-primary-600',
          secondary: 'text-white bg-secondary-700 border border-transparent hover:bg-secondary-800 focus:ring-4 focus:ring-secondary-300 disabled:hover:bg-secondary-700 dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-secondary-800 dark:disabled:hover:bg-secondary-600',
          tertiary: 'text-white bg-tertiary-700 border border-transparent hover:bg-tertiary-800 focus:ring-4 focus:ring-tertiary-300 disabled:hover:bg-tertiary-700 dark:bg-tertiary-600 dark:hover:bg-tertiary-700 dark:focus:ring-tertiary-800 dark:disabled:hover:bg-tertiary-600',
        }
      },
      badge: {
        color: {
          info: 'bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800 group-hover:bg-blue-200 dark:group-hover:bg-blue-300',
          gray: 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300 group-hover:bg-gray-200 dark:group-hover:bg-gray-600',
          light: 'bg-light-100 text-light-800 dark:bg-light-700 dark:text-light-300 group-hover:bg-light-200 dark:group-hover:bg-light-600',
          failure:
            'bg-danger-100 text-danger-800 dark:bg-danger-200 dark:text-danger-900 group-hover:bg-danger-200 dark:group-hover:bg-danger-300',
          success:
            'bg-success-100 text-success-800 dark:bg-success-200 dark:text-success-900 group-hover:bg-success-200 dark:group-hover:bg-success-300',
          warning:
            'bg-warning-100 text-warning-800 dark:bg-warning-200 dark:text-warning-900 group-hover:bg-warning-200 dark:group-hover:bg-warning-300',
          indigo:
            'bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900 group-hover:bg-indigo-200 dark:group-hover:bg-indigo-300',
          purple:
            'bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900 group-hover:bg-purple-200 dark:group-hover:bg-purple-300',
          pink: 'bg-pink-100 text-pink-800 dark:bg-pink-200 dark:text-pink-900 group-hover:bg-pink-200 dark:group-hover:bg-pink-300',
          primary: 'bg-primary-100 text-primary-800 dark:bg-primary-200 dark:text-primary-900 group-hover:bg-primary-200 dark:group-hover:bg-primary-300',
          secondary: 'bg-secondary-100 text-secondary-800 dark:bg-secondary-200 dark:text-secondary-900 group-hover:bg-secondary-200 dark:group-hover:bg-secondary-300',
          tertiary: 'bg-tertiary-100 text-tertiary-800 dark:bg-tertiary-200 dark:text-tertiary-900 group-hover:bg-tertiary-200 dark:group-hover:bg-tertiary-300',
          danger:
          'bg-danger-100 text-danger-800 dark:bg-danger-200 dark:text-danger-900 group-hover:bg-danger-200 dark:group-hover:bg-danger-300',

        },
      },
    }
};
