import DashboardLayout from "components/layout/dashboard-layout";
import { Breadcrumb, Button, Label, Select, Spinner, Textarea } from "flowbite-react";
import { SuperSEO } from "react-super-seo";
import { useParams } from "react-router-dom";
import TabNavigation from "components/tabs/tab-navigation.component";
import useApiGenProject from "hook/useApiGenProject";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Framework, ProgrammingLanguage } from "repositories/apigen-api/param";
import { CodegenApi } from "repositories/apigen-api";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import moment from "moment";
import JSZip from "jszip";
import { StaticTreeDataProvider, Tree, TreeItem, TreeItemIndex, UncontrolledTreeEnvironment } from "react-complex-tree";
import 'react-complex-tree/lib/style.css';
import Highlight from 'react-highlight'
import 'highlight.js/styles/default.css';

const BEApiGeneratorGeneratePage = () => {
  const { code } = useParams()
  const [ project, _, project_mutate ] = useApiGenProject(code)
  const [submitting, setSubmitting] = useState(false)
  const [filepreviewLoading, setFilepreviewLoading] = useState(false)

  const [previewFiles, setPreviewFiles] = useState<string[]>([])
  const [previewFileDatas, setPreviewFileDatas] = useState<Map<string, string>>(new Map<string, string>())

  const has_download = project !== undefined ? project.generated_url !== "" && moment(project.generated_expired).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD'): false
  
  useEffect(() => {
    const loadFilePreview = async (_url: string) => {
      setFilepreviewLoading(true)
      const jsZip = new JSZip();
      let response = await fetch(_url);      // will fetch the data from s3 blob
      let data = await response.blob();        // and convert it into blob
      

      let filenames: string[] = []
      let filedatas: Map<string, string> = new Map<string, string>()
      await jsZip.loadAsync(data).then(function (zip) {
        Object.keys(zip.files).forEach(async function (filename) {
          filenames = [...filenames, filename]
          zip.files[filename].async("text").then(function (filedata) {
            // setCodegenFileTrees( [...codegenFileTrees, filename])
            filedatas.set(filename, filedata)
          });
        });
      });
      setPreviewFiles(filenames)    
      setPreviewFileDatas(filedatas)

      setFilepreviewLoading(false)
    }

    if(project !== undefined){
      setPreviewFiles([])
      setPreviewFileDatas(new Map<string, string>())
      if(has_download){
        loadFilePreview(project.generated_url)
      }
    }
  }, [project])


  const ValidationSchema = yup.object().shape({
    programming_language: yup.string().is(['go']),
    framework: yup.string().when('programming_language', {
      is: 'go',
      then: yup.string().is(['go:gin']),
    }),
  })
  const { register, handleSubmit, formState: { errors  }, reset, setValue, setError } = useForm<{programming_language: ProgrammingLanguage, framework: Framework}>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });
  
  useEffect( () => {
    if(project !== undefined){
      setValue('programming_language', project.programming_language, {shouldValidate: true})
      setValue('framework', project.framework, {shouldValidate: true})
    }
  }, [project])

  const onSubmitHandler = async (data: {programming_language: ProgrammingLanguage, framework: Framework}) => {
    try {
      if(project === undefined) return
      setSubmitting(true)
      await CodegenApi.ApiGenProject_GenerateCode(project.id, data.framework)
      toast.success("Generate Code Done")
      project_mutate()
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "framework": setError("framework", { type: "focus", message: e.message }, { shouldFocus: true }); break;
         }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };

  if(project === undefined){
    return <div>
      <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
      <div className="fixed w-full h-screen flex items-center justify-center z-50 bg-slate-500/50">
        <Spinner></Spinner>
      </div>
      <DashboardLayout></DashboardLayout>   
    </div>
  }

  return <div>
    <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
    
    <DashboardLayout>   
      <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/dashboard/be/api-generator">API Generator</Breadcrumb.Item>
          <Breadcrumb.Item>{project.project_name}</Breadcrumb.Item>
      </Breadcrumb>

      <TabNavigation items={[
        { key:"overview", name:"Overview", href:`/dashboard/be/api-generator/${code}` },
        { key:"schema", name:"Schema", href:`/dashboard/be/api-generator/${code}/schema` },
        { key:"routes", name:"Routes", href:`/dashboard/be/api-generator/${code}/routes` },
        { key:"generate", name:"Code Generate", href:`/dashboard/be/api-generator/${code}/generate` },
      ]} active={"generate"}></TabNavigation>
      
      <form key="codegen-form" className="mt-8" onSubmit={handleSubmit(onSubmitHandler)} >
        <div className="mb-2 block"> <Label value="Generate Code" /></div>
        <div className="flex flex-row gap-x-4 ">
          <div id="select-prog-language">
            
            <Select {...register("programming_language")} className="min-w-[150px]" id="programming_language" required={true} defaultValue={ProgrammingLanguage.Go}>
              <option value={ ProgrammingLanguage.Go }> Golang </option>
            </Select>
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.programming_language && <>{errors.programming_language.message}</>) }</p>
          </div>
          <div id="select-framework" className="">
            <Select {...register("framework")} id="framework" className="min-w-[150px]" required={true} defaultValue={Framework.Go_Gin}>
              <option value={ Framework.Go_Gin }> Gin Framework </option>
            </Select>
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.programming_language && <>{errors.programming_language.message}</>) }</p>
          </div>  
          <div className="">
            <Button type="submit" color="primary" disabled={submitting}>
              Generate
              {submitting && (
              <div className="ml-3">
                <Spinner size="sm" light={true} />
              </div>
              )}
            </Button>
          </div>
        </div>
      </form>
      
      {
      has_download ?
      <div className="mt-2 ">
        <hr className="mb-4"/>
        <div className="flex flex-row gap-x-4 items-center">
          <Button type="button" color="primary" href={project.generated_url}>
            Download
          </Button>
          <div className="text-sm font-medium text-gray-500">Expired: {moment(project.generated_expired).format('DD-MM-YYYY')}</div>
        </div>
        {
          filepreviewLoading &&
          <div className="text-sm text-light mt-8">
            Loading datas <Spinner className="ml-1" size={"sm"}/>
          </div>
        }
        { 
        !filepreviewLoading && previewFiles.length > 0 &&
        <FilePreviewComponent service_name={project.service_name} files={previewFiles} filedatas={previewFileDatas}></FilePreviewComponent>
        }
      </div> :
      <div className="text-sm text-light mt-8">
        There is no generated URL (or already expired. Please generate one)
      </div>
      }
    </DashboardLayout>
  </div>
}

export default BEApiGeneratorGeneratePage;

const FilePreviewComponent = (props: {service_name: string, files: string[], filedatas: Map<string, string>}) => {
  const [selectedFileContent, setSelectedFileContent] = useState<string>()
  const [selectedFileName, setSelectedFileName] = useState<string>("")
  
  const generate_filetree_datas = () => {
    var parent_child_files: {fullpath : string, filename: string, parent: string}[] = []
    
    // create into parent child files
    props.files.forEach((items) => {
      var current_item = items 
      var current_item_arr = current_item.split('/')
      
      while(current_item_arr.length > 0 ){
        const fullpath = current_item_arr.join('/')
        const name = current_item_arr[current_item_arr.length-1]
        current_item_arr.pop()
        const parent = current_item_arr.join('/')

        // if parent path already exist, skip
        if(parent_child_files.findIndex((pcf) => pcf.fullpath === fullpath) > -1){ continue }
        parent_child_files.push({fullpath: fullpath, filename: name, parent: parent})
        
      }
    })

    // create data structure for react-complex-tree
    var datas: Record<TreeItemIndex, TreeItem<any>> = {} 
    parent_child_files.forEach((item) => {
      const children = parent_child_files.filter((pcf) => pcf.parent === item.fullpath).map((pcf) => pcf.fullpath)
      datas[item.fullpath] = {
        index: item.fullpath,
        data: item.filename,
        children: children,
        isFolder: children.length>0,
      }
    })

    return new StaticTreeDataProvider(datas, (item, data) => ({ ...item, data }))
  }
  return <div className="flex gap-4 py-4">
    <div className="w-1/4 flex flex-col border-tertiary pr-2 mr-2 border-r ">
    <UncontrolledTreeEnvironment  
      onFocusItem={(item) => {
        if(item.isFolder){ return }
        const content = props.filedatas.get(item.index.toString()) 
        if(content !== undefined && content !== ""){
          setSelectedFileContent(content)
          setSelectedFileName(item.data)
        }
      }}
      renderTreeContainer={(props) => <div className="rct-tree-root min-h-[500px] !bg-transparent">{props.children}</div>}
      dataProvider={generate_filetree_datas()}
      getItemTitle={item => item.data}
      viewState={{}}
    >
      <Tree treeId="tree-1" rootItem={props.service_name} treeLabel="Files" />
    </UncontrolledTreeEnvironment>

    </div>
    <div className="w-3/4">
      {
      selectedFileContent === undefined ? 
      <div className="text-sm text-light mt-4">Select file in the sidebar</div> :
      <>
        <div className="text-sm text-light mb-2">{selectedFileName}</div>

        <Highlight className='language-golang text-sm' innerHTML={false}>
        {selectedFileContent !== undefined && selectedFileContent !== "" ? selectedFileContent : ""}
        </Highlight>

      </>
      
      }
      
    </div>
  </div>
}