import { ApiGenSchema_Create, ApiGenSchema_Delete, ApiGenSchema_GetByName, ApiGenSchema_GetByProjectCode, ApiGenSchema_Update } from "./apigen-schema";
import { ApiGenRoute_GetByProjectCode, ApiGenRoute_CreateCrud, ApiGenRoute_Create, ApiGenRoute_DeleteById, CodeGenRestRoutesApi_GetById, CodeGenRestRoutesApi_GetByServiceId, ApiGenRoute_Update } from "./apigen-routes";
import { ApiGenProject_Create, ApiGenProject_Delete, ApiGenProject_GenerateCode, ApiGenProject_GetByCode, ApiGenProject_GetByWorkspaceId } from "./apigen-project";

export const CodegenApi = {
  ApiGenProject_Create,
  ApiGenProject_Delete,
  ApiGenProject_GetByCode,
  ApiGenProject_GetByWorkspaceId,
  ApiGenProject_GenerateCode,

  ApiGenSchema_GetByProjectCode,
  ApiGenSchema_Create,
  ApiGenSchema_Delete,
  ApiGenSchema_GetByName,
  ApiGenSchema_Update,
  
  ApiGenRoute_GetByProjectCode,
  ApiGenRoute_CreateCrud,
  ApiGenRoute_DeleteById,
  ApiGenRoute_Create,
  ApiGenRoute_Update,

  // CodeGenRestRoutesApi_GetByServiceId,
  // CodeGenRestRoutesApi_GetById,
};
  