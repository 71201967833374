import React, { useEffect, useState } from "react";
import { Badge, Button, Label, Modal, Select, Spinner, Table, TextInput, ToggleSwitch } from "flowbite-react";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import { ApiGenRoute, ApiGenRouteRequest, ApiGenSchema, EndpointType, HTTPMethod, RouteRequestType } from "repositories/apigen-api/param";
import { CodegenApi } from "repositories/apigen-api";
import { FormState, useForm, UseFormRegister, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { pascalCase, snakeCase } from "change-case";
import {  pathCase } from "libs/strings/pathCase";
import { FiX, FiPlus, FiHelpCircle, FiMoreVertical } from "react-icons/fi";
import { reorder } from "libs/array/reorder";
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from "@hello-pangea/dnd";

export interface RoutesCreateUpdateModalState {
  show: boolean
  route?: ApiGenRoute
  schema?: ApiGenSchema
}
const RoutesCreateUpdateModal = (props: {
  state: RoutesCreateUpdateModalState, 
  setState: React.Dispatch<React.SetStateAction<RoutesCreateUpdateModalState>>, 
  onSuccess: (data: ApiGenRoute) => void}) => {
  
  const closeModal = () => props.setState({...props.state, show: false})
  const [submitting, setSubmitting] = useState(false)

  const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
    endpoint_type: yup.string().required().oneOf([EndpointType.GET, EndpointType.GETLIST, EndpointType.GETLIST_PAGINATION, EndpointType.CREATE, EndpointType.UPDATE, EndpointType.DELETE]),
    path: yup.string().required(),
    http_method: yup.string().oneOf([HTTPMethod.GET, HTTPMethod.POST, HTTPMethod.PUT, HTTPMethod.PATCH, HTTPMethod.DELETE]),
    requests: yup.array().of(
      yup.object().shape({
        request_name: yup.string().required().test({name: "unique", 
        message: "request param name cannot same",
        test: (data, x) => {
          const rr : ApiGenRouteRequest[]= requests as ApiGenRouteRequest[]
          return  rr.filter((r) => r.request_name === data).length <= 1
        }}),  
        schema_column_name: yup.string().required(),   
        request_type:  yup.string().oneOf([RouteRequestType.EQUAL, RouteRequestType.IN, RouteRequestType.GT, RouteRequestType.GTE, RouteRequestType.LT, RouteRequestType.LTE, RouteRequestType.SEARCH, RouteRequestType.UPDATE]),
      })
    ),
    responses: yup.array().of(yup.string()),
  })

  const { getValues, register, handleSubmit, formState, formState: { errors }, getFieldState, setValue, trigger, setError, reset } = useForm<ApiGenRoute>({ mode: 'onChange', reValidateMode: 'onChange', resolver: yupResolver(ValidationSchema) })
  const endpointType = getValues('endpoint_type')
  const requests = getValues('requests') ?? []
  const responses = getValues('responses') ?? []
  const path = getValues('path') ?? ""

  useEffect( () => {
    if(props.state.show && props.state.schema ){
      if (props.state.route === undefined ){
        console.log("msuk")
        setValue('schema_id', props.state.schema.id,  {shouldValidate: true})
        setValue('name', "", {shouldValidate: true})
        setValue('endpoint_type', EndpointType.GET, {shouldValidate: true})
        setValue('http_method', HTTPMethod.GET, {shouldValidate: true})
        const new_requests_get = [{request_name: props.state.schema.primary_key, schema_column_name: props.state.schema.primary_key, request_type: RouteRequestType.EQUAL}]
        setValue('requests', new_requests_get, {shouldValidate: true})
        setValue('responses', props.state.schema.columns.filter((c) => c.name !== "created_at" && c.name !== "updated_at" && c.name !== "deleted_at").map( (c) => c.name ), {shouldValidate: true})
        setValue('path', "/" + props.state.schema.name, {shouldValidate: true})

      } else if (props.state.route !== undefined){
        setValue('schema_id', props.state.route.id)
        setValue('name', props.state.route.name)
        setValue('endpoint_type', props.state.route.endpoint_type)
        setValue('http_method', props.state.route.http_method)
        setValue('requests', props.state.route.requests)
        setValue('responses', props.state.route.responses)
        setValue('path', props.state.route.path)
        trigger()
      }
    } 
  },[props.state.show])

  const set_default_by_enpoint_type = (_endpoint_type: EndpointType) => {
    if(props.state.schema === undefined) return
    
    switch(_endpoint_type){
      case EndpointType.GET:
        const new_requests_get = [{request_name: props.state.schema.primary_key, schema_column_name: props.state.schema.primary_key, request_type: RouteRequestType.EQUAL}]
        setValue('http_method', HTTPMethod.GET, {shouldValidate: true})
        setValue('requests', new_requests_get, {shouldValidate: true})
        setValue('responses', props.state.schema.columns.filter((c) => c.name !== "created_at" && c.name !== "updated_at" && c.name !== "deleted_at").map( (c) => c.name ), {shouldValidate: true})
        setValue("path", pathCase(path, new_requests_get.map((item) => item.request_name)), {shouldValidate: true});
        break
      case EndpointType.GETLIST:
        setValue('http_method', HTTPMethod.GET, {shouldValidate: true})
        setValue('requests', [], {shouldValidate: true})
        setValue('responses', props.state.schema.columns.filter((c) => c.name !== "created_at" && c.name !== "updated_at" && c.name !== "deleted_at").map( (c) => c.name ), {shouldValidate: true})
        setValue("path", pathCase(path, []), {shouldValidate: true});
        break
      case EndpointType.GETLIST_PAGINATION:
        setValue('http_method', HTTPMethod.GET, {shouldValidate: true})
        setValue('requests', [], {shouldValidate: true})
        setValue('responses', props.state.schema.columns.filter((c) => c.name !== "created_at" && c.name !== "updated_at" && c.name !== "deleted_at").map( (c) => c.name ), {shouldValidate: true})
        setValue("path", pathCase(path, []), {shouldValidate: true});
        break
      case EndpointType.CREATE:
        setValue('http_method', HTTPMethod.POST, {shouldValidate: true})
        setValue('requests', [], {shouldValidate: true})
        setValue('responses', [], {shouldValidate: true})
        setValue("path", pathCase(path, []), {shouldValidate: true});
        break
      case EndpointType.UPDATE:
        const pk = props.state.schema.primary_key
        const new_requests_update = [
          ...props.state.schema.columns.filter((c) => c.name !== "created_at" && c.name !== "updated_at" && c.name !== "deleted_at").map( (c) => {
              return {
                request_name: c.name,
                schema_column_name: c.name, 
                request_type: c.name === pk ? RouteRequestType.EQUAL : RouteRequestType.UPDATE,
              }
          })
        ]
        setValue('http_method', HTTPMethod.PUT, {shouldValidate: true})
        setValue('requests', new_requests_update, {shouldValidate: true})
        setValue('responses', [], {shouldValidate: true})
        setValue("path", pathCase(path, new_requests_update.map((item) => item.request_name)), {shouldValidate: true});
        break
      case EndpointType.DELETE:
        setValue('http_method', HTTPMethod.DELETE, {shouldValidate: true})
        setValue('requests', [{request_name: props.state.schema.primary_key, schema_column_name: props.state.schema.primary_key, request_type: RouteRequestType.EQUAL}],{shouldValidate: true})
        setValue('responses', props.state.schema.columns.filter((c) => c.name !== "created_at" && c.name !== "updated_at" && c.name !== "deleted_at").map( (c) => c.name ), {shouldValidate: true})
        setValue("path", pathCase(path, []), {shouldValidate: true});
        break
    }
  }

  const onSubmit =  (data: ApiGenRoute) => {
    if(props.state.schema === undefined) return
    data.schema_id = props.state.schema.id 
    data.project_id = props.state.schema.project_id
    if(props.state.route === undefined || props.state.route.id <= 0){
      // Create Mode 
      submitCreate(data)
    } else {
      // Update Mode
      data.id = props.state.route.id
      submitUpdate(data)
    }
  }

  const submitCreate = async (data: ApiGenRoute) => {    
    try {
      setSubmitting(true)
      await CodegenApi.ApiGenRoute_Create(data)
      toast.success("Create Route success...")
      closeModal()
      props.onSuccess(data)
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach((e) => {
          switch(e.field){
            case "schema_id" : setError("schema_id", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "name": setError("name", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "endpoint_type": setError("endpoint_type", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "http_method": setError("http_method", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "requests": setError("requests", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "responses": setError("responses", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "path": setError("path", { type: "focus", message: e.message }, { shouldFocus: true }); break;
        }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }      
    } finally {
      setSubmitting(false)
    }
  };

  const submitUpdate = async (data: ApiGenRoute) => {    
    try {
      setSubmitting(true)
      await CodegenApi.ApiGenRoute_Update(data)
      toast.success("Update Route success...")
      closeModal()
      props.onSuccess(data)
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach((e) => {
          switch(e.field){
            case "schema_id" : setError("schema_id", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "name": setError("name", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "endpoint_type": setError("endpoint_type", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "http_method": setError("http_method", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "requests": setError("requests", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "responses": setError("responses", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "path": setError("path", { type: "focus", message: e.message }, { shouldFocus: true }); break;
        }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }      
    } finally {
      setSubmitting(false)
    }
  };

  if(props.state.schema === undefined){
    return <Modal show={props.state.show} onClose={ ()=> { props.setState({...props.state, show: false})}} >
      <Modal.Header> {props.state.route === undefined ? "Create Route" : "Update Route" } </Modal.Header>
      <Modal.Body>
        <Spinner></Spinner>
      </Modal.Body>
    </Modal>
  }
  
  return <>
    <Modal show={props.state.show} size="6xl" onClose={ ()=> closeModal()} >
      <Modal.Header> {props.state.route === undefined ? "Create Route" : "Update Route" } </Modal.Header>
      <Modal.Body>
        <form key="create-routes-form" className="flex flex-col gap-4 mt-4" onSubmit={ handleSubmit(onSubmit)}>
          <div>
            <div className="mb-2 block"> <Label htmlFor="name" value="Function Name"/></div>
            <TextInput {...register(`name`)} placeholder="ex: GetById" onChange={(e) => {
              setValue('name', pascalCase(e.target.value), {shouldValidate: true});
            }} />
            <p className="mt-2 text-sm text-red-600 dark:text-red-500"> {(errors?.name && <>{errors.name.message}</>)}</p>
          </div>

          <div>
            <div className="mb-2 block ">
              <Label htmlFor="column_type" value="Endpoint Type"/>
              <span className="text-xs ml-1">
                {
                  endpointType === EndpointType.GET ? '(Fetch a single of data)' : 
                  endpointType === EndpointType.GETLIST ? '(Fetch list of datas)' : 
                  endpointType === EndpointType.GETLIST_PAGINATION ? '(Fetch list of datas with pagination)' : 
                  endpointType === EndpointType.CREATE ? '(Create data)' : 
                  endpointType === EndpointType.UPDATE ? '(Update data)' : 
                  endpointType === EndpointType.DELETE ? '(Delete data)' : 
                  ''
                }  
              </span>
            </div>
            <div >
              <Select {...register(`endpoint_type`)} defaultValue={EndpointType.GET} onChange={(e) => {
                setValue("endpoint_type", e.target.value as EndpointType, {shouldValidate: true})
                set_default_by_enpoint_type(e.target.value as EndpointType)
              }}>
                <option value={EndpointType.GET}> GET </option>
                <option value={EndpointType.GETLIST}> GETLIST </option>
                <option value={EndpointType.GETLIST_PAGINATION}> GETLIST Pagination </option>
                <option value={EndpointType.CREATE}> CREATE </option>
                <option value={EndpointType.UPDATE}> UPDATE </option>
                <option value={EndpointType.DELETE}> DELETE </option>
              </Select>
            </div>
            <p className="mt-2 text-sm text-red-600 dark:text-red-500"> {(errors?.endpoint_type && <>{errors.endpoint_type.message}</>)}</p>
            
          </div>
          <div>
            <div className="mb-2 block">
              <Label htmlFor="column_type" value="Route"/>
              <span className="text-xs ml-1">(use &quot;:&quot; for inline param, e.g. :id)</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="grow-0">
                <Select {...register(`http_method`)} defaultValue={HTTPMethod.GET}>
                  <option value={HTTPMethod.GET}> GET </option>
                  <option value={HTTPMethod.POST}> POST </option>
                  <option value={HTTPMethod.PUT}> PUT </option>
                  <option value={HTTPMethod.PATCH}> PATCH </option>
                  <option value={HTTPMethod.DELETE}> DELETE </option>
                </Select>
              </div>
              <div className='grow'>
                <TextInput {...register("path")} placeholder="url path" onBlur={(e) => {
                  setValue("path", pathCase(e.target.value, requests.map( (r) => r.request_name ) ), {shouldValidate: true});
                }}/>
              </div>
            </div>
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              {(errors?.http_method && <>{errors.http_method.message}</>) || 
              (errors?.path && <>{errors.path.message}</>)}
            </p>
          </div>

          {/* Requests Section */}

          {
          endpointType !== EndpointType.CREATE &&
          <RequestSection schema={props.state.schema} requests={requests} current_path={path} endpointType={endpointType}
          formState={formState} register={register} setValue={setValue} trigger={trigger}
          ></RequestSection>
          }

          {/* Responses Section */}
          {
          endpointType !== EndpointType.CREATE && endpointType !== EndpointType.UPDATE &&
          <div>
            <div className="mb-2 block"> <Label htmlFor="responses" value="Responses"/></div>
            <div className="flex flex-wrap items-center gap-2">
            {
            props.state.schema.columns.map((sc, idx)=>{
            return <div key={"responses-"+idx.toString()}  className="w-fit cursor-pointer"
              onClick={() => {
                if(responses.findIndex((r) => r === sc.name) > -1){ setValue('responses', responses.filter( (r) => r !== sc.name ), {shouldValidate: true}) } 
                else { setValue('responses', [...responses, sc.name], {shouldValidate: true}) }
              }}
              >
              <Badge color={responses.findIndex((r) => r === sc.name) > -1 ? "success" : "light"}>{sc.name}</Badge>
            </div>
            })}
            </div>
          </div>
          }
          
          <div className="flex self-center gap-x-4">
            <div className="self-center">
              <Button type="submit" color="primary" disabled={submitting}> 
                Save
                {submitting && ( <div className="ml-3"><Spinner size="sm" light={true} /></div> )}
              </Button>
            </div>

            <div className="self-center">
              <Button type="button" color="failure" onClick={closeModal}> 
                Close
              </Button>
            </div>
          </div>
        </form> 
      </Modal.Body>
    </Modal>
  </>
}


const RequestSection = (props: {
  schema: ApiGenSchema
  requests: ApiGenRouteRequest[]
  endpointType: EndpointType
  current_path: string 

  register: UseFormRegister<ApiGenRoute>
  formState: FormState<ApiGenRoute>
  setValue: UseFormSetValue<ApiGenRoute>
  trigger: UseFormTrigger<ApiGenRoute>
}) => {

  const requests_inurl = (name: string) :boolean => {
    if(props.current_path !== undefined) {
      const path_splitted = props.current_path.split("/")
      return path_splitted.findIndex((p) => p === ":" + name ) > -1
    }
    return false
  }

  const requests_remove_in_url = (request_name: string)  => { 
    var path_splitted = props.current_path.split("/")
    const in_url_idx = path_splitted.findIndex((ps) => ps === ":" +request_name)

    if(in_url_idx > -1){
      path_splitted.splice(in_url_idx,1)
      props.setValue('path', path_splitted.join("/"), {shouldValidate: true})
    }
  }

  const path_refresh = ()=>{
    const new_path = pathCase(props.current_path, props.requests.map( (r) => r.request_name ) )
    props.setValue("path", new_path, {shouldValidate: true});
  }

  return <div>
    <div className="mb-2 block"> <Label htmlFor="requests" value="Requests"/></div>
    <div className="flex gap-4">
      {/* Requests Sidebar */}
      <div className="w-1/4 flex flex-col border-tertiary pr-2 mr-2 border-r ">
        <ul className="flex-1 min-h-0 overflow-y-auto">
          {
          props.schema.columns.map( (sc) => 
          <li key={`req-column-choice-${sc.name}`}>
            <div className={"pl-4 pr-2 mb-1 cursor-pointer font-bold text-sm flex items-center h-10 rounded-lg hover:bg-primary-50"} 
              onClick={() => props.setValue('requests', [...props.requests, {request_name: sc.name, schema_column_name: sc.name, request_type: RouteRequestType.EQUAL}], {shouldValidate: true})}>
              <div className="flex-1">{sc.name}</div>
              <div className="flex-0">
                <button type="button" className="inline-flex items-center justify-center w-6 h-6 text-primary-50 transition-colors duration-150 bg-primary-500 rounded-full focus:shadow-outline hover:bg-primary-800">
                  <FiPlus/>
                </button>
              </div>
            </div>
          </li>
          )}
        </ul>
      </div>

      {/* Requests Body */}
      <div className="w-3/4">
        <div className="flex mb-4"><FiHelpCircle></FiHelpCircle> <span className="text-xs ml-2">The order of the requests affecting the source codes (e.g. queries)</span></div>
        {props.endpointType === EndpointType.GETLIST_PAGINATION && <div className="flex mb-4"><FiHelpCircle></FiHelpCircle> <span className="text-xs ml-2">Get List Pagination has 2 default parameters: `page` & `size` </span></div> }
        
        {
        props.requests.length === 0 ?
        <div className="text-sm text-light mt-4">No parameter requests yet Select columns/fields from the sidebar</div> :
        <RoutesTableDraggable onDragEnd={(source_index: number, dest_index: number) => {
          props.setValue('requests', reorder(props.requests, source_index, dest_index), {shouldValidate: true}) 
        }}
        >
        {
          props.requests.map( (item, index) => (
          <React.Fragment key={"requests-draggable-" + index.toString()}>
            <Table.Cell className="whitespace-nowrap"><FiMoreVertical></FiMoreVertical></Table.Cell>
            <Table.Cell className="whitespace-nowrap">
              <TextInput {...props.register(`requests.${index}.request_name`) }  onBlur={async (e) => {
                props.setValue(`requests.${index}.request_name`, snakeCase(e.target.value), {shouldValidate: true});
                path_refresh()
              }}  />
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">  {(props.formState.errors && props.formState.errors.requests && props.formState.errors.requests[index] && props.formState.errors.requests[index]?.request_name && <>{props.formState.errors.requests[index]?.request_name?.message}</>)} </p>
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap">{item.schema_column_name}</Table.Cell>
            <Table.Cell className="whitespace-nowrap">
              <Select { ...props.register(`requests.${index}.request_type`) } sizing={"sm"} defaultValue={item.request_type}>
                <option value={RouteRequestType.EQUAL}> Equal (=) </option>
                <option value={RouteRequestType.IN}> Multiple (array) </option>
                <option value={RouteRequestType.SEARCH}> Search </option>
                <option value={RouteRequestType.GT}> Greater Than (&gt;) </option>
                <option value={RouteRequestType.GTE}> Greater Than Equal (&#8805;) </option>
                <option value={RouteRequestType.LT}> Less Than (&lt;) </option>
                <option value={RouteRequestType.LTE}> Less Than Equal (&#8804;) </option>
                {
                props.endpointType === EndpointType.UPDATE &&
                <option value={RouteRequestType.UPDATE}> Affected Update </option>
                }
              </Select>
            </Table.Cell>
            <Table.Cell className="whitespace-nowrap">
              <ToggleSwitch checked={requests_inurl(item.request_name)} label="" onChange={(e) => {
                if (!requests_inurl(item.request_name)){
                  props.setValue('path', pathCase(props.current_path + '/:' + item.request_name, props.requests.map((r) => r.request_name) ), {shouldValidate: true})
                } else {
                  requests_remove_in_url(item.request_name)
                }
              }}/>
            </Table.Cell>
            <Table.Cell>
              <button type="button" className="inline-flex items-center justify-center w-8 h-8 mr-2 text-pink-100 transition-colors duration-150 bg-danger-700 rounded-full focus:shadow-outline hover:bg-danger-800"
                onClick={() => {
                  props.setValue('requests', props.requests.filter( (r) => r.request_name !== item.request_name ), {shouldValidate: true})
                  requests_remove_in_url(item.request_name)
                  props.trigger()
                }}
              >
                <FiX></FiX>
              </button>
            </Table.Cell>
          </React.Fragment>
          ))
        }
        </RoutesTableDraggable>
        }
      </div>
    </div>
  </div>
}

const RoutesTableDraggable = (props: { children: JSX.Element[], onDragEnd: (source_index: number, dest_index: number) => void }) => {
  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { destination, source } = result;
    if (!destination || source.index === destination.index) return;

    props.onDragEnd(source.index, destination.index)
  };

  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) return null;

  return (
    <Table>
      <Table.Head>
        <Table.HeadCell className="w-0"></Table.HeadCell>
        <Table.HeadCell>Request Param</Table.HeadCell>
        <Table.HeadCell>Schema Column</Table.HeadCell>
        <Table.HeadCell>Type</Table.HeadCell>
        <Table.HeadCell>In URL?</Table.HeadCell>
        <Table.HeadCell className="w-0">Delete</Table.HeadCell>
      </Table.Head>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="dropable-table-body">
        {(provided, snapshot) => (
          <tbody className="characters"
            {...provided.droppableProps} ref={provided.innerRef}
          > 
            {props.children.map((item, idx) => (
            <Draggable key={"dropable-tr-" + (item.key as string)} draggableId={"dropable-tr-" + (item.key as string)} index={idx}>
              {(provided) => (
                <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  {item}
                </tr>
              )}
            </Draggable>
            ))
            }
            {provided.placeholder}
          </tbody>
        )}
        </Droppable>
      </DragDropContext>
    </Table>
  );
};

export default RoutesCreateUpdateModal