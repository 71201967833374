import React from "react";
import { Plan } from "repositories/workspace-api/plan";

const PlanCard = (props: {plan: Plan, description: string, pros: string[], selected: boolean, disabled: boolean, onClick:React.MouseEventHandler<HTMLDivElement> | undefined}) => {
  return <>
      <div className={`w-64 p-4 ${props.disabled ? "bg-light-200" : "bg-white"} shadow-lg rounded-2xl cursor-pointer ${props.selected ? "border border-primary" : ""}`} 
        onClick={props.onClick}>
        <p className="mb-4 text-xl font-medium text-gray-800 dark:text-gray-50">{props.plan.name}</p>
        <p className="text-3xl font-bold text-gray-900 dark:text-white">
            {
            props.plan.original_price > props.plan.price && props.plan.price !== -1 &&
            <span className="line-through text-sm">${props.plan.original_price}</span>
            }
            {
            props.plan.price !== -1 ?
            <>
            <span className="">${props.plan.price}</span>
            <span className="text-sm text-gray-300">/ year</span>
            </> :
            <span className="">Contact Us</span>
            }            
        </p>
        <p className="mt-4 text-xs text-gray-600 dark:text-gray-100">
            {props.description}
        </p>
        <ul className="w-full mt-6 mb-6 text-sm text-gray-600 dark:text-gray-100">
            {
            props.pros.map((p, i) => 
            <li key={`plan-${props.plan.code}-${i}`} className="mb-3 flex items-center">
                <svg className="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
                    </path>
                </svg>
                {p}
            </li>
            )}
        </ul>
    </div>
  </>
}

export default PlanCard