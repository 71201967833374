import request from "../../libs/api/request";
import { ApiSuccessResponse } from "../../libs/api/response";
import { UserToken } from "../usertoken-api/param";
import { GetByForgetPasswordTokenResp } from "./param";

export const AuthApi_LoginGoogle = (code: string, state: string) :Promise<ApiSuccessResponse<UserToken>> => {
  return request({
    url: `user/auth/login/google`,
    method: "POST",
    data: {
      code: code,
      state: state,
    }
  });
};

export const AuthApi_LoginGithub = (access_token: string) :Promise<ApiSuccessResponse<UserToken>> => {
  return request({
    url: `user/auth/login/github?access_token=${access_token}`,
    method: "GET",
  });
};

export const AuthApi_Register = (email: string, name: string, password: string) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `user/auth/register`,
    method: "POST",
    data: {
      email: email,
      name: name, 
      password: password,
    }
  });
};

export const AuthApi_VerifyUser = (email: string, verify_code: string) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `user/auth/verify`,
    method: "POST",
    data: {
      email: email,
      verify_code: verify_code, 
    }
  });
};

export const AuthApi_CreateForgetPasswordToken = (email: string) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `user/auth/password/forget`,
    method: "POST",
    data: {
      email: email,
    }
  });
};

export const AuthApi_GetByForgetPasswordToken = (token: string) :Promise<ApiSuccessResponse<GetByForgetPasswordTokenResp>> => {
  return request({
    url: `user/auth/password/forget/${token}`,
    method: "GET",
    
  });
};

 
export const AuthApi_ChangePasswordByForgetPassword = (token: string, password: string) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `user/auth/password/forget/${token}`,
    method: "PUT",
    data: {
      password: password
    }
  });
};

export const AuthApi_Login = (email: string, password: string) :Promise<ApiSuccessResponse<UserToken>> => {
  return request({
    url: `user/auth/login`,
    method: "POST",
    data: {
      email: email,
      password: password,
    }
  });
};


export const AuthApi_ChangePassword = (old_password: string, new_password: string) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `user/auth/password/change`,
    method: "POST",
    data: {
      old_password: old_password,
      new_password: new_password,
    },
    headers: {needauth: true},
  });
};
