import { useEffect, useState } from "react";
import DashboardLayout from "components/layout/dashboard-layout";
import { Button, Pagination, Spinner, Table, ToggleSwitch } from "flowbite-react";
import * as changeCase from "change-case";
import { toast } from "react-toastify";
import { ApiErrorResponse, UnknownError } from "libs/api/response";
import { ApiGenProjects } from "repositories/apigen-api/param";
import { CodegenApi } from "repositories/apigen-api";
import { FiEye, FiPlus, FiX } from "react-icons/fi";
import { SuperSEO } from "react-super-seo";
import { useNavigate } from "react-router-dom";
import CreateProjectModal from "./create-project.modal";
import DeleteProjectModal, { DeleteProjectModalState } from "./delete-project.modal";
import { useWorkspace } from "libs/context/workspace";
import { useCurrentUser } from "libs/context/user";

const BEApiGeneratorIndexPage = () => {
  const navigate = useNavigate()

  const curr_workspace =  useWorkspace()

  const curr_user = useCurrentUser()
  const [createServiceModal, setCreateServiceModal] = useState(false)
  const workspace_ctx = useWorkspace()
  const size = 10
  const [page, setPage] = useState(1)
  const [showMineOnly, setShowMineOnly] = useState(false)

  const [datas, error, mutate] = useFetchCGRest(workspace_ctx.workspace?.id ?? -1, page, size, showMineOnly)
  const [deleteModal, setDeleteModal] = useState<DeleteProjectModalState>({id:0, show:false})

  if(workspace_ctx.workspace === undefined){
    return <DashboardLayout>
      <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
      <div className="absolute w-full h-full bg-light-500/50 z-[100]">
        <div className="flex h-screen">
          <div className="m-auto">
            <Spinner />
          </div>
        </div>
      </div>
    </DashboardLayout>
  }

  return <DashboardLayout>
    <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
    <h1 className="text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white">
      Backend Tools: Api Generator
    </h1>
    <p className="mb-4 mt-2 text-md font-normal text-gray-500 lg:text-lg dark:text-gray-400">More than just CRUD Generator. Give us schema, you got source code</p>

    <hr className="mb-4"></hr>

    <CreateProjectModal show={createServiceModal} setShow={setCreateServiceModal} workspace_id={workspace_ctx.workspace.id} onSuccess={(code)=>navigate(`/dashboard/be/api-generator/${code}`)}></CreateProjectModal>
    <DeleteProjectModal state={deleteModal} setState={setDeleteModal} onSuccess={()=>mutate()}></DeleteProjectModal>
    
    <div className="flex gap-x-2">
      <div className="grow self-center">
        {
        workspace_ctx.workspace.role === "owner" &&
        <ToggleSwitch checked={showMineOnly} label="Show My Project Only" onChange={() => setShowMineOnly(!showMineOnly)} />
        }
      </div>
      <div className="">
        <Button size="md" color="primary" onClick={ ()=> setCreateServiceModal(true) }><FiPlus className="mr-1"></FiPlus>New Project</Button>
      </div>
    </div>
    <div className="mt-5"></div>
    {
    (curr_workspace.workspace?.max_codegen_project ?? -1) > -1 &&
    <div className="text-xs text-right text-danger font-medium mb-1">*Maximum project for {curr_workspace.workspace?.plan_name} plan: {curr_workspace.workspace?.max_codegen_project ?? 1}</div>
    }
    <Table striped={true}>
      <Table.Head className="bg-primary text-white">
        <Table.HeadCell> No </Table.HeadCell>
        <Table.HeadCell> Name </Table.HeadCell>
        <Table.HeadCell> Language </Table.HeadCell>
        <Table.HeadCell> Database </Table.HeadCell>
        <Table.HeadCell> Creator </Table.HeadCell>
        <Table.HeadCell>  </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">
        {
          datas.projects.length === 0 && <Table.Row className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
          <Table.Cell colSpan={6} className="text-center"> No projects have been created. </Table.Cell>
        </Table.Row>
        }
        {
          datas.projects.map((e, i)=>{
            return <Table.Row key={"project-"+i.toString()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
              onClick={() => navigate(`/dashboard/be/api-generator/${e.code}`)}
            >
              <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white"> { ((page-1) * size) + i + 1} </Table.Cell>
              <Table.Cell className="whitespace-nowrap"> {e.project_name} </Table.Cell>
              <Table.Cell className="whitespace-nowrap"> {changeCase.capitalCase(e.programming_language)} </Table.Cell>
              <Table.Cell className="whitespace-nowrap"> {changeCase.capitalCase(e.database)} </Table.Cell>
              <Table.Cell className="whitespace-nowrap"> {e.creator_name} </Table.Cell>
              <Table.Cell className="flex items-center space-x-3">
                <button type="button" className="inline-flex items-center justify-center w-8 h-8 mr-2 text-white transition-colors duration-150 bg-primary-700 rounded-full focus:shadow-outline hover:bg-primary-800"
                  onClick={(event)=>{
                    navigate(`/dashboard/be/api-generator/${e.code}`)
                    event.stopPropagation()
                  }}
                >
                   <FiEye></FiEye>
                </button>
                {
                (workspace_ctx.workspace?.role === "owner" || curr_user.user?.id === e.creator_id) &&
                <button type="button" className="inline-flex items-center justify-center w-8 h-8 mr-2 text-white transition-colors duration-150 bg-danger-700 rounded-full focus:shadow-outline hover:bg-danger-800"
                  onClick={(event)=>{
                    setDeleteModal({show: true, id: e.id})
                    event.stopPropagation()
                  }}
                >
                  <FiX></FiX>
                </button>
                }
              </Table.Cell>
            </Table.Row>
          })
        }
      </Table.Body>
    </Table>
    {
    datas.pagination.last_page > 1 && (
    <Pagination
      currentPage={page}
      totalPages={datas.pagination.last_page}
      onPageChange={ (_page) => { setPage(_page) }}
    />
    )
    }
  </DashboardLayout>
}
export default BEApiGeneratorIndexPage;

function useFetchCGRest(workspace_id:number, page: number, size: number, showMineOnly: boolean) :[ApiGenProjects, ApiErrorResponse | undefined, ()=>void] {
  const [result, setResult] = useState<ApiGenProjects>({projects:[], pagination:{current_page:1, first_page: 1, from: 0, to: 0, per_page: 10, last_page: 1, total:0}});
  const [error, setError] = useState<ApiErrorResponse>();
  
  async function mutate() {
    if(workspace_id !== -1){
      fetch(workspace_id, page, showMineOnly)
    }
  }

  async function fetch(_workspace_id: number, _page:number, _showMineOnly: boolean) {
    try {
      var res = await CodegenApi.ApiGenProject_GetByWorkspaceId(_workspace_id, _page, size, _showMineOnly);
      setResult(res.data);
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
        setError((error as ApiErrorResponse));
      } else {
        console.log("Unknown error:",error);
        setError(UnknownError(""));
        toast.error("Internal Error")
      }
    } finally {
    }
  }
  useEffect(() => {
    if(workspace_id !== -1){
      fetch(workspace_id, page, showMineOnly)
    }
  }, [workspace_id, page, showMineOnly]);
  
  return [result, error, mutate];
}
