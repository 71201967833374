import request from "../../libs/api/request";
import { ApiSuccessResponse } from "../../libs/api/response";
import { CGRestRoute, CGRestRoutes, ApiGenRoutes, ApiGenRoute } from "./param";

const prefix = `codegen/rest`


export const ApiGenRoute_GetByProjectCode = (project_code: string) :Promise<ApiSuccessResponse<ApiGenRoutes>> => {
  return request({
    url: `codegen/api/project/${project_code}/routes`,
    method: "GET",
    headers: {needauth: true},
  });
};

export const ApiGenRoute_CreateCrud = (schema_id: number) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `codegen/api/routes/generate-crud`,
    method: "POST",
    headers: {needauth: true},
    data: {
      schema_id:schema_id
    }
  });
};

export const ApiGenRoute_DeleteById = (id: number) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `codegen/api/routes/${id}/delete`,
    method: "DELETE",
    headers: {needauth: true},
  });
};

export const ApiGenRoute_Create = (param: ApiGenRoute) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `codegen/api/routes`,
    method: "POST",
    headers: {needauth: true},
    data: {
      schema_id: param.schema_id,
      name: param.name,
      http_method: param.http_method,
      endpoint_type: param.endpoint_type,
      path: param.path,
      requests: param.requests,
      responses: param.responses,        
    }
  });
};



export const CodeGenRestRoutesApi_GetByServiceId = (service_id: number) :Promise<ApiSuccessResponse<CGRestRoutes>> => {
    return request({
      url: `${prefix}/service/${service_id}/routes`,
      method: "GET",
      headers: {needauth: true},
    });
};



export const CodeGenRestRoutesApi_GetById = (id: number) :Promise<ApiSuccessResponse<CGRestRoute>> => {
  return request({
    url: `${prefix}/routes/${id}`,
    method: "GET",
    headers: {needauth: true},
  });
};

export const ApiGenRoute_Update = (param: ApiGenRoute) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `codegen/api/routes/${param.id}/update`,
    method: "POST",
    headers: {needauth: true},
    data: {
      id: param.id,
      name: param.name,
      http_method: param.http_method,
      endpoint_type: param.endpoint_type,
      path: param.path,
      requests: param.requests,
      responses: param.responses,        
    }
  });
};

