import img_rest_api from "assets/images/home/kodingless-rest-api.png";
import img_more_than_crud from "assets/images/home/kodingless-more-than-crud.png";

const FeatureSection = () => {
  return <section className="bg-secondary-50 dark:bg-gray-900" id="features">
    <div className="pt-8 text-center">
        <h2 className="text-4xl font-extrabold text-gray-900 dark:text-white">Features</h2>
        <p className="text-gray-500 sm:text-xl dark:text-gray-400"></p>
    </div>
    <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
      <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400 self-baseline">
        <h3 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white">Generate Backend API Boilerplate</h3>
        <p className="text-gray-500 sm:text-xl dark:text-gray-400 mb-8">
          Turn database schema into Backend API source code
        </p>
        <p className='text-primary font-bold'>Thousands line of code will be generated in 4 steps:</p>
        <ol className='text-black'>
          <li><span className=''>1.</span> Create new project</li>
          <li><span className=''>2.</span> Setup Database Schemas</li>
          <li><span className=''>3.</span> Configure HTTP Routes</li>
          <li><span className=''>4.</span> Download the generated source code and use it in your project</li>
        </ol>
      </div>
      <div className="">
          <img className="w-full rounded-lg" src={img_rest_api} alt="kodingless rest generator example" />
      </div>
    </div>
    <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
      {/* <div className="grid grid-cols-11 gap-1 mt-8">
        <div className='col-span-5'>
          <img className="w-full rounded-lg" src={img_db_seeder_domain} alt="db seeder domain example" />
        </div>
        <div className='col-span-6 mt-4'>
          <img className=" w-full rounded-lg" src={img_db_seeder_gen} alt="db seeder domain generated" />
        </div>
      </div> */}
      <div className="">
          <img className="w-full rounded-lg" src={img_more_than_crud} alt="kodingless more than crud" />
      </div>
      <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400 self-baseline">
        {/* <div className='w-fit'><Badge color={"success"}>Coming Soon</Badge></div> */}
        <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">More than CRUD Generator</h2>
        <p className="text-gray-500 sm:text-xl dark:text-gray-400 mb-8">
          CRUD are simple, we can generate more complex code
        </p>
        <p className='text-primary font-bold'>Examples:</p>
        <ol className='text-black'>
          <li><span className=''>1.</span> Soft delete or hard delete, up tp you</li>
          <li><span className=''>2.</span> Read API with pagination capability.</li>
          <li><span className=''>3.</span> Read API with search feature</li>
          <li><span className=''>4.</span> And many more</li>
        </ol>
      </div>
    </div>
    {/* <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
      <div className="grid grid-cols-11 gap-1 mt-8">
        <div className='col-span-5'>
          <img className="w-full rounded-lg" src={img_db_seeder_domain} alt="db seeder domain example" />
        </div>
        <div className='col-span-6 mt-4'>
          <img className=" w-full rounded-lg" src={img_db_seeder_gen} alt="db seeder domain generated" />
        </div>
      </div>
      <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400 self-baseline">
        <div className='w-fit'><Badge color={"success"}>Coming Soon</Badge></div>
        <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">Generate Database Seeder</h2>
        <p className="text-gray-500 sm:text-xl dark:text-gray-400 mb-8">
          Give us schema (domains), you get contextual database seed
        </p>
        <p className='text-primary font-bold'>Get contextual database seeds in 3 steps:</p>
        <ol className='text-black'>
          <li><span className=''>1.</span> Create Project</li>
          <li><span className=''>2.</span> Setup schemas. We have a lot of mock datas from people related, accounts, etc.</li>
          <li><span className=''>3.</span> Download the code and you are ready to use it in your projects for testing, mocking, etc.</li>
        </ol>
      </div>
    </div> */}
</section>
}
export default FeatureSection;