import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Plan } from "repositories/workspace-api/plan";
import { WorkspaceApi } from "repositories/workspace-api";
import PlanCard from "./plan.card";
import { TransactionOrderSimulation } from "repositories/workspace-api/transaction";
import { MyWorkspace } from "repositories/workspace-api/workspace";

const UpgradeWorkspaceModal = (props: {state: {show: boolean, workspace?: MyWorkspace}, setState: React.Dispatch<React.SetStateAction<{show: boolean, workspace?: MyWorkspace}>>, onSuccess: () => void}) => {
  const plans = usePlans()
  const [selectedPlan, setSelectedPlan] = useState<Plan>()

  useEffect( () => {
    if(props.state.show === true && plans.length>0) {
      setSelectedPlan(plans[0])
    }
  }, [props.state.show, plans])
  
  if(plans.length === 0 || props.state.workspace === undefined) {
    return <>
    <Modal show={props.state.show} size="4xl" onClose={ ()=> props.setState({...props.state, show: false})} >
      <Modal.Header>Upgrade Workspace</Modal.Header>
      <Modal.Body>
      <div className="flex ">
        <div className="m-auto">
          <Spinner/>
        </div>
      </div>
      </Modal.Body>
    </Modal>
    </>
  }

  return <>
      <Modal show={props.state.show} size="4xl" onClose={ ()=> props.setState({...props.state, show: false})} >
      <Modal.Header>Upgrade Workspace</Modal.Header>
      <Modal.Body>
        <div className="flex flex-row gap-4">
        {
        plans.map((p) => 
        <PlanCard key={`plan-card-${p.code}`} 
          plan={p} 
          description={p.code === "professional" ? "All feature for 1 user" : "All feature for teams/company that want to grow"}
          selected={selectedPlan?.code === p.code}
          disabled={false}
          pros={p.code === "professional" ? 
            ["Unlimited Project", "Unlimited schemas", "1 Members Only"] :
            ["Unlimited Project", "Unlimited schemas", "50 Members"]}
          onClick={() => setSelectedPlan(p)}
          ></PlanCard>
        )}
        <PlanCard plan={{code:"enterprise", name:"Enterprise", category:"workspace", max_workspace_member: 999999, original_price:-1, price:-1, id: 0}}
          description={"For more complex tools"}
          selected={false}
          disabled={true}
          pros={["Unlimited Project", "Unlimited schemas", "Adjustable number of members", "Custom codegen pattern/framework"]}
          onClick={undefined}
        ></PlanCard>
        </div>

        <hr className="my-4"></hr>

        {
        props.state.workspace.plan_code === selectedPlan?.code ?
        <div>This is your current plan</div> : 
        <ReviewOrderSection workspace_id={props.state.workspace.id} plan={selectedPlan} onSuccessPaid={() => {
          props.setState({...props.state, show: false});
        }}></ReviewOrderSection>
        }
        
        
      </Modal.Body>
    </Modal>
  </>
}

export default UpgradeWorkspaceModal

function usePlans() :Plan[] {
  const [result, setResult] = useState<Plan[]>([]);
  
  async function fetch() {
    try {
      var res = await WorkspaceApi.PlanApi_GetPublicPlans("workspace")
      setResult(res.data.plans);
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
    }
  }
  useEffect(() => { fetch() }, []);
  
  return result
}

const ReviewOrderSection = (props: {workspace_id: number, plan?: Plan, onSuccessPaid: () => void}) => {
  const [discountCode, setDiscountCode] = useState("")
  const [discountCodeInput, setDiscountCodeInput] = useState("")
  const [simulate_order, mutate_simulate_order] = useSimulateOrder(props.workspace_id, props.plan?.code ?? "", discountCode)

 
  if(props.plan === undefined || simulate_order === undefined) {
    return <div className="flex ">
      <div className="m-auto">
        <Spinner/>
      </div>
    </div>
  }

  return <div>
    <h2 className="text-lg font-bold mb-4">Review Order</h2>
    <dl>
      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Plan </dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{props.plan.name} (Annually)</dd>
      </div>
      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Price </dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{simulate_order.currency} {simulate_order.amount}</dd>
      </div>
      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500 self-center">Discount </dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <div className="flex gap-x-2 items-center">
            <div className="text-danger">({simulate_order.currency} {simulate_order.discount})</div>
            <TextInput value={discountCodeInput} onChange={(event) => setDiscountCodeInput(event.target.value.toUpperCase().replace(/\s/g, ""))} placeholder="Enter promo code"></TextInput>
            <Button color={"primary"} onClick={() => discountCodeInput !== discountCode ? setDiscountCode(discountCodeInput) : mutate_simulate_order()}>Apply Promo Code</Button>
          </div>
        </dd>
      </div>
      <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Total </dt>
        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{simulate_order.currency} {simulate_order.total_amount}</dd>
      </div>
    </dl>
    <div className={`mt-8 w-full text-center`} >
      <div className="text-light text-sm text-center mb-2">After you pay, don't close the modal until the page automatically reloaded</div>
      {
      process.env.REACT_APP_PAYPAL_CLIENT_ID !== undefined &&
      <PayPalScriptProvider options={{ 
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID, 
        components: "buttons", 
        currency: "USD", 
        // intent: "subscription", vault: true 
      }}>
        <PayPalButtons style={{ layout: "vertical", label:"paypal" }} 
          // forceReRender={[ simulate_order.total_amount, simulate_order.currency, { layout: "vertical" }]}
          createOrder={ (data, actions) => {
            return WorkspaceApi.TransactionApi_CreatePaypalOrder(props.workspace_id, props.plan?.code ?? "", discountCode).then((res) => {
              return res.data.paypal_order_id
            }).catch((error) => {
                if(error as ApiErrorResponse){
                  toast.error((error as ApiErrorResponse).message)
                } else {
                  console.log("Unknown error:",error);
                  toast.error("Internal Error")
                }
                return ""
            }) }}
            onApprove={ (data, actions) => {

              return actions.order?.capture().then(
                async (orderData): Promise<void> => {
                  try{
                    await WorkspaceApi.TransactionApi_SettlePaypalPayment(data.orderID)
                  }catch (error) {
                    if(error as ApiErrorResponse){
                      toast.error((error as ApiErrorResponse).message)
                    } else {
                      console.log("Unknown error:",error);
                      toast.error("Internal Error")
                    }
                  } finally {
                  }
                  console.log("body",orderData)
                  actions.redirect(window.location.href)
                return 
              }) ?? new Promise<void>(() => {})                   
            }}
        />
      </PayPalScriptProvider>
      }
      
    
    </div>
  </div>
}

function useSimulateOrder(workspace_id: number, plan_code: string, discount_code: string) :[TransactionOrderSimulation | undefined, () => void] {
  const [result, setResult] = useState<TransactionOrderSimulation>();
  
  async function fetch() {
    setResult(undefined)
    if(workspace_id > 0 && plan_code !== ""){
      try {
        var res = await WorkspaceApi.TransactionApi_SimulateOrder(workspace_id, plan_code, discount_code)
        setResult(res.data);
        if(res.data.discount_code_error !== ""){
          toast.error(res.data.discount_code_error)
        } else if (discount_code !== "") {
          toast.success("Apply code success")
        }
      } catch (error) {
        if(error as ApiErrorResponse){
          toast.error((error as ApiErrorResponse).message)
        } else {
          console.log("Unknown error:",error);
          toast.error("Internal Error")
        }
      } finally {
      }
    }
  }
  useEffect(() => { 
    fetch() 
  }, [workspace_id, plan_code, discount_code]);
  
  return [result, fetch]
}