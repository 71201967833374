import React, { useEffect, useState } from "react";
import { Badge, Button, Label, Modal, Select, Spinner, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import { ApiGenProject, DatabaseServer, Framework, ProgrammingLanguage } from "repositories/apigen-api/param";
import { CodegenApi } from "repositories/apigen-api";
import { slugify } from "libs/strings/slugify";
import { useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const CreateProjectModal = (props: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, workspace_id: number, onSuccess: (code:string) => void}) => {
  const [submitting, setSubmitting] = useState(false)
  const [serviceNameEditMode, setServiceNameEditMode] = useState(false)

  const ValidationSchema = yup.object().shape({
    project_name: yup.string().required(),
    description: yup.string(),
    service_name: yup.string().required(),
    programming_language: yup.string().is(['go']),
    framework: yup.string().when('programming_language', {
      is: 'go',
      then: yup.string().is(['go:gin']),
    }),
    database: yup.string().is(['postgres', 'mysql']),
  })
  const { register, handleSubmit, formState: { errors  }, reset, setValue, setError } = useForm<ApiGenProject>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });
  
  useEffect(() => {
    if(props.show){ 
    }
  }, [props.show]);

  const onSubmitHandler = async (data: ApiGenProject) => {
    if(props.workspace_id <= 0) return
    try {
      setSubmitting(true)
      data.workspace_id = props.workspace_id
      const resp = await CodegenApi.ApiGenProject_Create(data)
      props.onSuccess(resp.data.code)
      reset()
      props.setShow(false)
      toast.success("Create Project Success")
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "workspace_id": setError("workspace_id", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "project_name": setError("project_name", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "description": setError("description", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "service_name": setError("service_name", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "programming_language": setError("programming_language", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "framework": setError("framework", { type: "focus", message: e.message }, { shouldFocus: true }); break;
            case "database": setError("database", { type: "focus", message: e.message }, { shouldFocus: true }); break;
         }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };
  
  return <>
      <Modal show={props.show} onClose={ ()=> { props.setShow(false) }} >
          <Modal.Header>Create Project</Modal.Header>
          <Modal.Body>
            <form key="create-project-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)} >
              <div>
                <div className="mb-2 block"> <Label htmlFor="name" value="Project Name"/></div>
                <TextInput {...register("project_name")} id="name"  defaultValue={""} placeholder="ex: Product Api" onChange={(e) => {
                  if (!serviceNameEditMode) {
                    setValue("service_name", slugify(e.target.value.toLowerCase(), "_"));
                  }
                  setValue("project_name", e.target.value);
                }}/>
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.project_name && <>{errors.project_name.message}</>) }</p>
              </div>
              <div>
                <div className="mb-2 block">
                  <div className="flex justify-between">
                    <Label htmlFor="service_name" value="Service / App Name"/>
                    <div className="cursor-pointer">
                      <Badge onClick={() => { setServiceNameEditMode(!serviceNameEditMode) }} color={serviceNameEditMode ? "success": "failure"}>
                        Edit: {serviceNameEditMode ? "On" : "Off"}
                      </Badge>
                    </div>
                  </div>
                </div>
                <TextInput {...register("service_name")} id="service_name"  defaultValue={""} disabled={!serviceNameEditMode} placeholder="ex: product-api"
                onChange={(e) => {
                  setValue("service_name", slugify(e.target.value.toLowerCase(), "_"));
                }}/>
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.service_name && <>{errors.service_name.message}</>) }</p>
              </div>
              
            
              <div>
                <div className="mb-2 block"> <Label htmlFor="description" value="Description"/></div>
                <TextInput {...register("description")} id="description"  defaultValue={""} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.description && <>{errors.description.message}</>) }</p>
              </div>
              <hr/>
              <div id="select">
                <div className="mb-2 block"> <Label htmlFor="programming_language" value="Programming Language" /></div>
                <Select {...register("programming_language")} id="programming_language" required={true} defaultValue={ProgrammingLanguage.Go}>
                  <option value={ ProgrammingLanguage.Go }> Golang </option>
                </Select>
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.programming_language && <>{errors.programming_language.message}</>) }</p>
              </div>

              <div id="select">
                <div className="mb-2 block"> <Label htmlFor="framework" value="Framework" /></div>
                <Select {...register("framework")} id="framework" required={true} defaultValue={Framework.Go_Gin}>
                  <option value={ Framework.Go_Gin }> Gin Framework </option>
                </Select>
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.programming_language && <>{errors.programming_language.message}</>) }</p>
              </div>

              <div id="select">
                <div className="mb-2 block"> <Label htmlFor="database" value="Database" /></div>
                <Select  {...register("database")} id="database" required={true} defaultValue={DatabaseServer.Postgres}>
                <option value={ DatabaseServer.Postgres }> Postgres </option>
                <option value={ DatabaseServer.MySql }> MySql </option>
                </Select>
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.database && <>{errors.database.message}</>) }</p>
              </div>
              
              <div className="self-center">
                <Button type="submit" color="primary" disabled={submitting}>
                  Create
                  {submitting && (
                  <div className="ml-3">
                    <Spinner size="sm" light={true} />
                  </div>
                  )}
                </Button>
              </div>
            </form> 
          </Modal.Body>
      </Modal>
  </>
}

export default CreateProjectModal