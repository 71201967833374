import request from "../../libs/api/request";
import { ApiSuccessResponse } from "../../libs/api/response";
import { ApiGenProject, ApiGenProjects, Framework } from "./param";

const prefix = `codegen/api`

export const ApiGenProject_Create = (param :ApiGenProject) :Promise<ApiSuccessResponse<{code: string}>> => {
    console.log(param)  
  return request({
      url: `${prefix}/project`,
      method: "POST",
      headers: {needauth: true},
      data: {
        workspace_id: param.workspace_id,
        project_name: param.project_name,
        service_name: param.service_name,
        description: param.description,
        framework: param.framework,
        database: param.database,
      }
    });
};


export const ApiGenProject_GetByWorkspaceId = (workspace_id:number, page:number, size:number, show_mine_only: boolean) :Promise<ApiSuccessResponse<ApiGenProjects>> => {
  return request({
    url: `${prefix}/project?workspace_id=${workspace_id}&page=${page}&size=${size}&show_mine_only=${show_mine_only}`,
    method: "GET",
    headers: {needauth: true},
  });
};

export const ApiGenProject_Delete = (id:number) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `${prefix}/project/${id}`,
    method: "DELETE",
    headers: {needauth: true},
  });
};

export const ApiGenProject_GetByCode = (code: string) :Promise<ApiSuccessResponse<ApiGenProject>> => {
  return request({
    url: `${prefix}/project/${code}`,
    method: "GET",
    headers: {needauth: true},
  });
};


export const ApiGenProject_GenerateCode = (id:number, framework: Framework) :Promise<ApiSuccessResponse<ApiGenProjects>> => {
  return request({
    url: `${prefix}/project/${id}/generate`,
    method: "POST",
    headers: {needauth: true},
    data: {
      framework: framework
    }
  });
};
