import { Accordion } from "flowbite-react";

const FAQSection = () => {
  return <section className="bg-white dark:bg-gray-900" id="faq">
    <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6 ">
      <h2 className="mb-6 mt-6 text-3xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 lg:text-3xl dark:text-white">Frequently asked questions</h2>
      <div className="max-w-screen-md mx-auto">
        <Accordion flush={false}>
          <Accordion.Panel>
            <Accordion.Title>What is Kodingless?</Accordion.Title>
            <Accordion.Content>
            <p className="mb-2 ">
              Kodingless is tools to help Backend Software Engineers to dynamically generate source code / boilerplate / etc.
            </p>
            <p className="">
              Right now our features are:
              <br/>
              Backend Service (REST API) Boilerplate Code Generator <br/>
              Database Seeder Generator (Coming Soon)
            </p>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>Does this cost anything?</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 ">
                For the limited features is free (forever). 
                For the advanced one you can upgrade the plan with affordable price
              </p>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>What are the programming languages used in the code generated backend API?</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 ">
                Right now only Golang, other languages/frameworks such as node.js, python, java, laravel already on our roadmap
              </p>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>What database choice can be used in the code generated backend API?</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 ">
                Postgres & MySql
              </p>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title>How come you tell us that this is better than CRUD generator?</Accordion.Title>
            <Accordion.Content>
              <p className="mb-2 ">
                CRUD generator usually just generate a simple Create Read Update Delete functions. 
                With kodingless, you able to generate more complex one such as capability for soft deleting, full text search (for example, API for search by name), getting list of datas with pagination, etc.
              </p>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </div>               
    </div>
  </section>
}

export default FAQSection