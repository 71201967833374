import { useEffect, useState } from "react";
import DashboardLayout from "components/layout/dashboard-layout";
import { Badge, Button, Pagination, Spinner, Table, Tabs } from "flowbite-react";
import { toast } from "react-toastify";
import { SuperSEO } from "react-super-seo";
import { useCurrentUser } from "libs/context/user";
import { ApiErrorResponse, UnknownError } from "libs/api/response";
import { useWorkspace } from "libs/context/workspace";
import { capitalCase } from "change-case";
import { FiEdit, FiPlus, FiX } from "react-icons/fi";
import AddWorkspaceMemberModal from "./add-member.modal";
import DeleteWorkspaceMemberModal from "./delete-member.modal";
import UpgradeWorkspaceModal from "./upgrade.modal";
import { MyWorkspace, Workspace } from "repositories/workspace-api/workspace";
import { WorkspaceApi } from "repositories/workspace-api";
import UpdateWorkspaceNameModal from "./update-workspace.modal";
import { TransactionOrderHistories } from "repositories/workspace-api/transaction";
import moment from "moment";
import { User } from "repositories/user-api/param";

const WorkspaceOverviewPage = () => {
  const current_user = useCurrentUser()
  const user_workspace = useWorkspace()

  const [update_name_state, set_update_name_state] = useState<{show: boolean, workspace: MyWorkspace}>({show: false, workspace:{id: 0, name: "", plan_code:"free", plan_name:"Free", role: "member", max_codegen_project: 1, max_codegen_schema_columns: 20, max_workspace_member: 1}})
  const [upgrade_workspace, set_upgrade_workspace] = useState<{show: boolean, workspace?: MyWorkspace}>({show: false})


  if(current_user.user === undefined || user_workspace.workspace === undefined) {
    return <DashboardLayout>
      <SuperSEO title="Dashboard: Account & Profile" description="Dashboard: Account & Profile" lang="en" />
      <div className="absolute w-full h-full bg-light-500/50 z-[100]">
        <div className="flex h-screen">
          <div className="m-auto">
            <Spinner />
          </div>
        </div>
      </div>
    </DashboardLayout>
  }
  return (
    <DashboardLayout>
      <SuperSEO title="Dashboard: Workspace & Subscribtion" description="Dashboard: Workspace & Subscribtion" lang="en" />
      <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-5">
        Workspace & Plan
      </h1>
      
      <UpdateWorkspaceNameModal state={update_name_state} setState={set_update_name_state} onSuccess={() => {user_workspace.mutate()}}></UpdateWorkspaceNameModal>
      <UpgradeWorkspaceModal state={upgrade_workspace} setState={set_upgrade_workspace} onSuccess={() => {user_workspace.mutate()}}></UpgradeWorkspaceModal>

      <dl>
        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Workspace </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <div className="flex items-center">
              <span>{user_workspace.workspace.name}</span>
              <Badge className="ml-2 w-fit" color={user_workspace.workspace.role === "owner" ? "success": "light"} >{capitalCase(user_workspace.workspace.role)}</Badge> 
              {
              user_workspace.workspace.role === "owner" &&
              <button type="button" className="inline-flex items-center justify-center w-6 h-6 ml-2 text-white transition-colors duration-150 bg-tertiary-700 rounded-full focus:shadow-outline hover:bg-tertiary-800"
                onClick={(event) => {
                  if(user_workspace.workspace !== undefined)
                    set_update_name_state({show:true, workspace: user_workspace.workspace}); event.stopPropagation()
                  }}
              >
                <FiEdit className="h-3 w-3"></FiEdit>
              </button>
              }
            </div>
          </dd>
        </div>
        <div className="bg-gray-100 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-500">Plan </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
          <div className="flex items-center">
          <span>{user_workspace.workspace.plan_name}</span>
          { user_workspace.workspace.role === "owner" && 
            user_workspace.workspace.plan_code === "free" && 
          <Button className="ml-2" size={"xs"} color="primary" onClick={() => set_upgrade_workspace({show: true, workspace: user_workspace.workspace })}>Upgrade</Button>
          }
          </div>
          </dd>
        </div>
      </dl>
      
      {
      user_workspace.workspace !== undefined && user_workspace.workspace.role === "owner" ? 
      <Tabs.Group color="primary" aria-label="Tabs with underline" className="mt-4">
        <Tabs.Item active={true} title={`Members`}>
          <MemberSection workspace={user_workspace.workspace} current_user={current_user.user}></MemberSection>
        </Tabs.Item>
        <Tabs.Item title="Transactions" aria-hidden={true} className={(user_workspace.workspace !== undefined && user_workspace.workspace.role === "owner") ? "" : "hidden"}>
          <HistorySection workspace={user_workspace.workspace}></HistorySection>
        </Tabs.Item>
      </Tabs.Group> :
      <div className="mt-4">
        <MemberSection workspace={user_workspace.workspace} current_user={current_user.user}></MemberSection>
      </div>
      }
      
    </DashboardLayout>
  );
}

export default WorkspaceOverviewPage

const MemberSection = (props: {workspace: MyWorkspace, current_user: User}) => {
  const [workspace_detail, selected_workspace_err, workspace_detail_mutate] = useWorkspaceWithMember(props.workspace.id)
  const [add_member, set_add_member] = useState<{show: boolean, workspace_id: number}>({show: false, workspace_id:0})
  const [delete_member, set_delete_member] = useState<{show: boolean, workspace_id: number, email: string}>({show: false, workspace_id:0, email: ""})

  return <>
    <div className="flex items-center mb-4">
      <div className="grow">
        <span className="text-sm">
          Members: ({workspace_detail?.members.length ?? 0}/{workspace_detail?.max_workspace_member ?? 1})
        </span>
      </div>
      { props.workspace.role === "owner" &&
      <Button color={"primary"} type="button" size="sm" onClick={() => {set_add_member({show: true, workspace_id: props.workspace.id})}}><FiPlus className="mr-1" /> Add Member</Button>
      }
    </div>
    <AddWorkspaceMemberModal state={add_member} setState={set_add_member} onSuccess={() => workspace_detail_mutate()}></AddWorkspaceMemberModal>
    <DeleteWorkspaceMemberModal state={delete_member} setState={set_delete_member} onSuccess={() => {workspace_detail_mutate()}}></DeleteWorkspaceMemberModal>

    <Table striped={false} className={""}>
      <Table.Head className="bg-primary text-white">
        <Table.HeadCell className=""> No </Table.HeadCell>
        <Table.HeadCell> Email </Table.HeadCell>
        <Table.HeadCell> Name </Table.HeadCell>
        <Table.HeadCell> Status </Table.HeadCell>
        <Table.HeadCell> Role </Table.HeadCell>
        <Table.HeadCell>  </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">{
        workspace_detail !== undefined &&
        workspace_detail.members.map((swd, index) => 
        <Table.Row key={`member-list-${swd.id}`} className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ${swd.email === props.current_user.email ? 'bg-warning-50' : ''}`}>
          <Table.Cell className="whitespace-nowrap">{index+1}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">
            <div className="flex items-center">
              <span>{swd.email}</span>
              {!swd.is_register && 
              <Badge className="ml-1 w-fit" color={"light"} >Not Register</Badge> 
              }
            </div>
          </Table.Cell>
          <Table.Cell className="whitespace-nowrap">{swd.name}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">{swd.status ? "Active" : "Not Active"}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">{capitalCase( swd.role)}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">
            {
            props.workspace?.role === "owner" && props.current_user.email !== swd.email &&
            <button type="button" className="inline-flex items-center justify-center w-8 h-8 ml-2 text-white transition-colors duration-150 bg-danger-700 rounded-full focus:shadow-outline hover:bg-danger-800"
              onClick={() => {set_delete_member({show: true, email: swd.email, workspace_id: props.workspace.id})}}
            >
              <FiX className="h-4 w-4"></FiX>
            </button>
            }
          </Table.Cell>
        </Table.Row>
        )}
        
      </Table.Body>
    </Table>
  </>
}

const HistorySection = (props: {workspace: MyWorkspace}) => {
  const [historyPage, setHistoryPage] = useState(1)
  const [histories] = useWorkspaceHistory(props.workspace.id, historyPage, 10 )

  return <><Table striped={false} className={""}>
      <Table.Head className="bg-primary text-white">
        <Table.HeadCell className=""> Date </Table.HeadCell>
        <Table.HeadCell className=""> Tx Code </Table.HeadCell>
        <Table.HeadCell> Plan </Table.HeadCell>
        <Table.HeadCell> Amount </Table.HeadCell>
        <Table.HeadCell> Discount </Table.HeadCell>
        <Table.HeadCell> Total </Table.HeadCell>
        <Table.HeadCell> Status </Table.HeadCell>
        <Table.HeadCell> Active </Table.HeadCell>
      </Table.Head>
      <Table.Body className="divide-y">{
        histories.transactions.map((h, index) => 
        <Table.Row key={`history-list-${h.id}`} className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 `}>
          <Table.Cell className="whitespace-nowrap">{moment(h.created_at).format('DD-MM-YYYY hh:mm (UTC)')}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">{h.ref_id}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">{h.plan_name}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">{h.currency} {h.amount}</Table.Cell>
          <Table.Cell className="whitespace-nowrap text-danger">({h.currency} {h.discount})</Table.Cell>
          <Table.Cell className="whitespace-nowrap">{h.currency} {h.total_amount}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">{h.status}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">
            {
            h.workspace_plan_id > 0 &&
            <div>
              <span>{moment(h.start_at).format('DD-MM-YYYY hh:mm')}</span>
              <span className="mx-1">-</span>
              <span>{moment(h.end_at).format('DD-MM-YYYY hh:mm')}</span>
            </div>
            }
          </Table.Cell>
        </Table.Row>
        )}
        
      </Table.Body>
    </Table>
    {
    histories.pagination.last_page > 1 &&
    <div className="flex items-center justify-center text-center">
      <Pagination
        currentPage={histories.pagination.current_page}
        layout="table"
        onPageChange={(p) => {setHistoryPage(p)}}
        totalPages={histories.pagination.last_page}
      />
    </div>
    }
    </>
}

function useWorkspaceWithMember(workspace_id :number) :[Workspace | undefined, ApiErrorResponse | undefined, ()=>void] {
  const [result, setResult] = useState<Workspace>();
  const [error, setError] = useState<ApiErrorResponse>();
  
  async function mutate() {
    if(workspace_id > 0){
      fetch(workspace_id)
    }
  }

  async function fetch(_workspace_id: number) {
    try {
      var res = await WorkspaceApi.WorkspaceApi_GetById(_workspace_id)
      setResult(res.data);
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
        setError((error as ApiErrorResponse));
      } else {
        console.log("Unknown error:",error);
        setError(UnknownError(""));
        toast.error("Internal Error")
      }
    } finally {
    }
  }
  useEffect(() => {
    if(workspace_id > 0){
      fetch(workspace_id)
    }
  }, [workspace_id]);
  
  return [result, error, mutate];
}

function useWorkspaceHistory(workspace_id :number, page: number, size: number) :[TransactionOrderHistories, ApiErrorResponse | undefined, ()=>void] {
  const [result, setResult] = useState<TransactionOrderHistories>({transactions:[],pagination:{current_page: 1,first_page: 1, from: 1, last_page: 1, per_page: 1,to:1, total:0 }});
  const [error, setError] = useState<ApiErrorResponse>();
  
  async function mutate() {
    if(workspace_id > 0){
      fetch(workspace_id, page, size)
    }
  }

  async function fetch(_workspace_id: number, _page:number, _size:number) {
    try {
      var res = await WorkspaceApi.TransactionApi_GetTransactionHistory(_workspace_id, _page, _size)
      setResult(res.data);
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
        setError((error as ApiErrorResponse));
      } else {
        console.log("Unknown error:",error);
        setError(UnknownError(""));
        toast.error("Internal Error")
      }
    } finally {
    }
  }
  useEffect(() => {
    if(workspace_id > 0){
      fetch(workspace_id, page,size)
    }
  }, [workspace_id, page]);
  
  return [result, error, mutate];
}
