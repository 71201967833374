import { PlanApi_GetPublicPlans } from "./plan";
import { TransactionApi_CreatePaypalOrder, TransactionApi_GetTransactionHistory, TransactionApi_SettlePaypalPayment, TransactionApi_SimulateOrder } from "./transaction";
import { WorkspaceApi_GetMyWorkspaces, WorkspaceApi_GetMyWorkspaceById, WorkspaceApi_GetById, WorkspaceApi_UpdateWorkspace, WorkspaceApi_AddMember, WorkspaceApi_DeleteMember, } from "./workspace";

export const WorkspaceApi = {
  PlanApi_GetPublicPlans,

  TransactionApi_SimulateOrder,
  TransactionApi_CreatePaypalOrder,
  TransactionApi_SettlePaypalPayment,
  TransactionApi_GetTransactionHistory,
  
  WorkspaceApi_GetMyWorkspaces,
  WorkspaceApi_GetMyWorkspaceById,
  WorkspaceApi_GetById,
  WorkspaceApi_UpdateWorkspace,
  WorkspaceApi_AddMember,
  WorkspaceApi_DeleteMember,
  
};
  


