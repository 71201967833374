import { Button } from "flowbite-react";
import { FiArrowRight } from 'react-icons/fi';

const HomeSection = () =>{
  return <section className="dark:bg-gray-900 bg-secondary-50 pt-10 pb-10" id="home">
    <div className=" py-8 px-4 mx-auto max-w-screen-xl text-center">
      <h1 className="mb-4  text-2xl font-extrabold leading-none md:text-3xl xl:text-4xl dark:text-white">
      Backend API Code Generated Boilerplate.
      </h1>
      <p className="mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
      Save 70-80% Time to Coding Backend API in <b className="text-primary-700">Golang</b> <br/> 
        {/* You give us database schemas (domains), we give you <b>source codes</b>. */}
        Turn you database schema into source code
      </p>
      <div className="items-center text-center flex justify-center">
      <Button color={"primary"} size={"lg"} href="/login">
        Try Now for Free
        <FiArrowRight className='ml-2 w-5 h-5'  viewBox="0 0 20 20"></FiArrowRight>
      </Button>
      </div>
      <div className='text-sm mt-2 text-gray-500'>
      No Credit Card Required
      </div>
      <div className="flex justify-center mt-8">
      <a href="https://www.producthunt.com/posts/kodingless?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-kodingless" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=373312&theme=light" alt="Kodingless - Turn&#0032;database&#0032;schema&#0032;into&#0032;Backend&#0032;API&#0032;source&#0032;codes | Product Hunt" style={{"width": "175px", "height": "54px"}} width="175" height="54" /></a>
      </div>
    </div>
  </section>
} 

export default HomeSection;