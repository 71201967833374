import { Button, Spinner } from 'flowbite-react'
import { useEffect, useState } from 'react';
import { ApiErrorResponse } from 'libs/api/response';
import { toast } from 'react-toastify';
import { useAuth } from 'libs/context/auth';
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import logoLogin from "assets/images/logo-with-text.svg";
import { UserApi } from 'repositories/user-api';
import { Link } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';
import {
  LoginSocialGithub,
  IResolveParams,
} from 'reactjs-social-login'
import { GithubLoginButton } from 'react-social-login-buttons';

const LoginPage = () => {
  const { login } = useAuth()
  const [isLoading, setIsLoading] =  useState(false)
  const [loginViaGithub, setLoginViaGithub] =  useState("")

  useEffect(() => {
    if(loginViaGithub === "") return;
    doLoginViaGithub({_access_token: loginViaGithub})
  }, [loginViaGithub])

  const ValidationSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  
  const { register, handleSubmit, formState: { errors }, setError } = useForm<{email: string, password: string}>({ mode: 'all', resolver: yupResolver(ValidationSchema) });

  const doLoginViaGithub = async (data: {_access_token:string}) => {
    try {
      setIsLoading(true)
      const resp = await UserApi.AuthApi_LoginGithub(data._access_token)
      if(resp.data.auth_token !== "" && resp.data.refresh_token !== "") {
        login(resp.data)
        toast.success("Login via github success")
      }
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setIsLoading(false)
    }
  }


  const onSubmitHandler = async (data: {email: string, password: string}) => {
    try {
      setIsLoading(true)
      const resp = await UserApi.AuthApi_Login(data.email, data.password)
      login(resp.data)
      toast.success("Login success")
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "email" : 
              setError("email", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
            case "password": 
              setError("password", { type: "focus", message: e.message }, { shouldFocus: true });
              break;
        }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setIsLoading(false)
    }
  };
  

  return <>
    <SuperSEO
      title="Login to Dashboard"
      description="Login to Dashboard"
      lang="en"
    />
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className='mb-5'>
          <Link to="/" className=''>
            <img src={logoLogin} alt="logo" className="max-w-[400px]" />
          </Link>
        </div>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                      Login to your account
                  </h1>
                  <form className="space-y-4 md:space-y-6" onSubmit={ handleSubmit(onSubmitHandler) }>
                      <div>
                          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                          <input {...register("email")} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="email@domain.com" required />
                          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.email && <>{errors.email.message}</>)}</p>
                      </div>
                      <div>
                          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                          <input {...register("password")} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.password && <>{errors.password.message}</>)}</p>
                      </div>
                     
                      <Button type="submit" color={"primary"} disabled={isLoading} style={{width: "100%"}} >
                        Login
                        {isLoading && (
                        <div className="ml-3"><Spinner size="sm" light={true} /></div>
                        )}
                      </Button>

                      <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                          Don’t have an account yet? <Link to="/register" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Register</Link>
                      </p>
                      {/* <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                          Forget password? <Link to="/" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Click Here</Link>
                      </p> */}
                  </form>
                  <div className="inline-flex justify-center items-center w-full">
                      <hr className="my-2 w-64 h-px bg-gray-200 border-0 dark:bg-gray-700" />
                      <span className="absolute left-1/2 px-3 font-medium text-gray-900 bg-white -translate-x-1/2 dark:text-white dark:bg-gray-900">or</span>
                  </div>
                  <LoginSocialGithub 
                    client_id={'e39c13d6ec4a80164991'}
                    client_secret={'54ca02cbbf4402577aab4f3e3d01a1cd33c11abb'}
                    redirect_uri={`${window.location.origin}/login`}
                    isOnlyGetToken={true}
                    scope={"gist,repo,read:user,user:email"}
                    onLoginStart={ () => {  } }
                    onLogoutSuccess={() => {  }}
                    onResolve={({ provider, data }: IResolveParams) => {
                      if(data !== undefined){
                        setLoginViaGithub(data.access_token)
                      }
                    }}
                    onReject={(err: any) => {
                      console.log(err);
                    }}
                  >
                    <GithubLoginButton className='!text-sm !h-[42px]' align='left' style={{"borderRadius": "8px"}}  />
                  </LoginSocialGithub>
                  {/* <LoginGithub clientId="e39c13d6ec4a80164991"
                    onSuccess={(response:any) => {console.log(response)}}
                    onFailure={(response:any) => {console.log(response)}}
                    onRequest={(response:any) => {console.log(response)}}
                    redirectUri="https://kodingless.com"
                  ></LoginGithub> */}
              </div>
          </div>
      </div>
    </section>
  </>
};

export default LoginPage;
