interface TabHeaderStaticItem{
    key: string 
    name: string
    href?: string
}

const TabNavigation = (props :{items:TabHeaderStaticItem[], active: string}) => {
    return <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">
            {
                props.items.map((e, i)=>{
                    if(e.key === props.active){
                        return <li key={"ths-" + i.toString()} className="mr-2">
                        <a href={e.href ?? '#'} className="inline-block p-4 text-primary-600 rounded-t-lg border-b-2 border-primary-600 active dark:text-primary-500 dark:border-primary-500" aria-current="page">{e.name}</a>
                    </li>
                    }

                    return <li key={"ths-" + i.toString()} className="mr-2">
                    <a href={e.href ?? '#'} className="inline-block p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">{e.name}</a>
                </li>
                })
            }
        </ul>
    </div>
}

export default TabNavigation
