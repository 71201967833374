import request from "../../libs/api/request";
import { ApiSuccessResponse } from "../../libs/api/response";

export const PlanApi_GetPublicPlans = (category: string) :Promise<ApiSuccessResponse<Plans>> => {
    return request({
      url: `workspace/master/plan?category=${category}`,
      method: "GET",
    });
};

export type Plans = {
  plans: Plan[]
}

export type Plan = {
  id: number
  code: string
  category: string
  name: string
  price: number
  original_price: number
  max_workspace_member: number
}
