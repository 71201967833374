import { Spinner } from 'flowbite-react'
import { useEffect, useState } from 'react';
import { ApiErrorResponse } from 'libs/api/response';
import { toast } from 'react-toastify';
import logoLogin from "assets/images/logo-with-text.svg";
import { UserApi } from 'repositories/user-api';
import { Link, useSearchParams } from 'react-router-dom';
import { SuperSEO } from 'react-super-seo';

const UserVerifyPage = () => {
  const [mode, setMode] =  useState<"loading" | "success" | "failed">("loading")
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email")
  const token = searchParams.get("token")

  useEffect( () => {
    if(email !== null && token !== null){
      verify_user(email, token)
    }
  }, [email, token])

  async function verify_user(_email: string, _token: string) {
    try {
      await UserApi.AuthApi_VerifyUser(_email, _token)
      setMode("success")
    } catch (error) {
      if(error as ApiErrorResponse){
        if ((error as ApiErrorResponse).code === 60103){
          setMode("failed")
        } else {
          setMode("failed")
          // toast.error((error as ApiErrorResponse).message)
        }
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
    }
  }

  if(mode === "loading") {
    return <>
    <SuperSEO
      title="User Verification"
      description="User Verification"
      lang="en"
    />
    <div className="absolute w-full h-full bg-light-500/50 z-[100]">
      <div className="flex h-screen">
        <div className="m-auto">
          <Spinner />
        </div>
      </div>
    </div>
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className='mb-5'>
          <Link to="/" className=''>
            <img src={logoLogin} alt="logo" className="max-w-[400px]" />
          </Link>
        </div>
        </div>
    </section>
    </>
  }

  return <>
    <SuperSEO
      title="User Verification"
      description="User Verification"
      lang="en"
    />
     {
      mode === 'failed' &&
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">...</h1>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Failed to Verify</p>
                <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Either the account hasn't exists, you enter wrong verification token or the token already expired. Check again your email. </p>
                <a href={"/"} className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Back to Homepage</a>
            </div>   
        </div>
      </section>
    }
    {
      mode === 'success' &&
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
                <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 dark:text-primary-500">Great!!</h1>
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">Your account has verified.</p>
                <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Now you can login to your account </p>
                <a href={"/login"} className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">Login Now</a>
            </div>   
        </div>
      </section>
    }
  </>
};

export default UserVerifyPage;

