import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
// import { logoutRedux } from "../redux/auth/auth.actions";
// import AuthActionTypes from "../redux/auth/auth.types";
// import { store } from "../redux/store";
import { ApiErrorResponse, ForbiddenError, isApiResponseError, UnauthorizedError, UnknownError } from "./response";
import Cookies from 'js-cookie'
import { UsertokenApi } from "../../repositories/usertoken-api";

// store.subscribe(listener);

// function select(state: any) {
//   return state.auth.token;
// }

// function listener() {
//   const token = select(store.getState());
//   axios.defaults.headers.common["Authorization"] = `bearer ${token}`;
// }

const client = axios.create({
  baseURL: process.env.REACT_APP_REST_HOST,
  maxRedirects: 5,
});

client.interceptors.request.use(
  (request) => {
      if (request.headers && request.headers['needauth'] !== null && request.headers['needauth'] !== undefined && request.headers['needauth'] !== '' ){
          const current_auth_token = Cookies.get("auth_token")
          request.headers['Authorization'] =  "Bearer " + (current_auth_token !== undefined && current_auth_token !== null && current_auth_token !== "" ? current_auth_token : "");
          delete request.headers['needauth'] 
          request.params = (request.params === undefined || request.params === null) ? {__retry: 0} : {...request.params, __retry: 0}
      }
      return request;
  },
  (error) => {
      return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
      // console.log('Response:', response)
      return response;
  },
  async (error: AxiosError<ApiErrorResponse>) => {
      const max_retry = 3
      if (error.response?.status !== 401) {
          return Promise.reject(error);
      }

      if (Number(error.config.params.__retry) >= max_retry) {
        // logout
        Cookies.remove("auth_token")  
        Cookies.remove("refresh_token")

        window.location.href = "/login";
        return Promise.reject(error)
      }
      
      // Try request again with new token
      const currentRefreshToken = Cookies.get("refresh_token")
      try {        
          const usertokenData = await UsertokenApi.RefreshToken( currentRefreshToken ?? '' )
          Cookies.set("auth_token", usertokenData.data.auth_token)
          Cookies.set("refresh_token", usertokenData.data.refresh_token)

          const config = error.config;
          if(config.headers){
            config.headers['Authorization'] = "Bearer " + usertokenData.data.auth_token;
            error.config.params.__retry = 1 + Number(error.config.params.__retry)
            config.params.__retry = error.config.params.__retry
          }
          
          return new Promise((resolve, reject) => {
            client.request(config).then((response) => {
                  resolve(response);
              }).catch( (error) => {
                  reject(error);
              })
          });

      } catch (err) {
          if(isApiResponseError(err)){
              if(err.http_status === 403 && err.code === 11001){
                  Cookies.remove("auth_token")
                  Cookies.remove("refresh_token")
                  window.location.href = "/login";
              }
          }
          return Promise.reject(err);
      } 
  }
);

const request = (options: any) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = function (error: any) :Promise<ApiErrorResponse>{
    if (error.response.status === 401) {
      // store.dispatch({
      //   type: AuthActionTypes.LOGOUT,
      // });

      return Promise.reject(UnauthorizedError);
    }

    if (error.response.status === 500) {
      if(isApiResponseError(error.response.data)){
        if(error.response.data.http_status === 500 && error.response.data.code === 12002){
          Cookies.remove("auth_token")
          Cookies.remove("refresh_token")
          // window.location.href = "/login";
        }
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error);
    }

    if (error.response.status === 403) {
      if(isApiResponseError(error.response.data)){
        if(error.response.data.http_status === 403 && error.response.data.code === 11001){
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.response.data);
      }
      return Promise.reject(ForbiddenError);
    }

    if(isApiResponseError(error.response.data)){
      return Promise.reject(error.response.data as ApiErrorResponse);
    }
    if(error.message) {
      return Promise.reject(UnknownError(error.message));
    }
    return Promise.reject(UnknownError(error.toString()));
  };
  // const token = select(store.getState());

  return client({
    // headers: {
    //   Authorization: `bearer ${token}`,
    // },
    ...options,
  })
    .then(onSuccess)
    .catch(onError);
};

export default request;


// function makeid(length: number) {
//   var result           = '';
//   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   var charactersLength = characters.length;
//   for ( var i = 0; i < length; i++ ) {
//     result += characters.charAt(Math.floor(Math.random() * 
// charactersLength));
//  }
//  return result;
// }

// export const request_file = (filename: string, options: any) => {
//   const onSuccess = (response: AxiosResponse) => {
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', filename); //or any other extension
//     document.body.appendChild(link);
//     link.click();
//   };

//   const onError = function (error: any) :Promise<ApiErrorResponse>{
//     if (error.response.status === 401) {
//       store.dispatch({
//         type: AuthActionTypes.LOGOUT,
//       });

//       return Promise.reject(UnauthorizedError);
//     }

//     if (error.response.status === 403) {
//       return Promise.reject(ForbiddenError);
//     }

//     if(isApiResponseError(error.response.data)){
//       return Promise.reject(error.response.data as ApiErrorResponse);
//     }
//     if(error.message) {
//       return Promise.reject(UnknownError(error.message));
//     }
//     return Promise.reject(UnknownError(error.toString()));
//   };
//   const token = select(store.getState());

//   return client({
//     headers: {
//       Authorization: `bearer ${token}`,
//     },
//     responseType:"blob",
//     ...options,
//   })
//     .then(onSuccess)
//     .catch(onError);
// };