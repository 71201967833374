import request from "../../libs/api/request";
import { ApiSuccessResponse } from "../../libs/api/response";
import { ApiGenSchemas, ApiGenSchema } from "./param";

const prefix = `codegen/api`

export const ApiGenSchema_GetByProjectCode = (project_code:string) :Promise<ApiSuccessResponse<ApiGenSchemas>> => {
  return request({
    url: `${prefix}/project/${project_code}/schema`,
    method: "GET",
    headers: {needauth: true},
  });
};

export const ApiGenSchema_Create = (project_id: number, name: string) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `${prefix}/schema`,
    method: "POST",
    headers: {needauth: true},
    data: {
      project_id: project_id,
      name: name,
    }
  });
};

export const ApiGenSchema_Delete = (id: number) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `${prefix}/schema/${id}`,
    method: "DELETE",
    headers: {needauth: true},
  });
};


export const ApiGenSchema_GetByName = (project_code: string, name: string) :Promise<ApiSuccessResponse<ApiGenSchema>> => {
  return request({
    url: `${prefix}/project/${project_code}/schema/${name}`,
    method: "GET",
    headers: {needauth: true},
  });
};


export const ApiGenSchema_Update = (schema: ApiGenSchema) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `${prefix}/schema/${schema.id.toString()}`,
    method: "POST",
    headers: {needauth: true},
    data: {
      id: schema.id,
      name: schema.name,
      columns: schema.columns,
      primary_key: schema.primary_key,
      timestamp_created: schema.timestamp_created,
      timestamp_updated: schema.timestamp_updated,
      softdelete: schema.softdelete,
    }
  });
};


