import { ReactNode } from "react"
import { FiFeather, FiClock, FiCode, FiCreditCard, FiTag, FiThumbsUp } from 'react-icons/fi';

const AboutSection = () =>{
    const AboutIcon = (props: {icon :ReactNode}) => {
      return <div className="flex flex-col justify-center items-center">
        <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
          {props.icon}
        </div>
      </div>
    }
  
    return <section className="bg-gray-50 dark:bg-gray-800" id="about">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        {/* Header */}
        <div className="mb-8 lg:mb-16 text-center">
          <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">Built for Lazy Smart Software Engineers</h2>
          <p className="text-gray-500 sm:text-xl dark:text-gray-400">
            We believe that a lot of code <b className='text-primary'>doesn&apos;t need to be written</b>,
            but we also believe that <b className='text-primary'>engineer&apos;s touch</b> in code is still needed.
          </p>
        </div>
        {/* About List */}
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
          <div className='text-center'>
            <AboutIcon icon={<FiClock className="text-primary-600 w-5 h-5 lg:w-6 lg:h-6 dark:text-primary-300"></FiClock>}></AboutIcon>
            <h3 className="mb-2 text-xl font-bold dark:text-white">Time is Precious</h3>
            <p className="text-gray-500 dark:text-gray-400">
              <b className="text-primary">Save time</b>, days or even weeks in writing thousands of lines of code. You can focus on writing code that you need to care of.
            </p>
          </div>
          <div className='text-center'>
            <AboutIcon icon={<FiThumbsUp className="text-primary-600 w-5 h-5 lg:w-6 lg:h-6 dark:text-primary-300"></FiThumbsUp>}></AboutIcon>
            <h3 className="mb-2 text-xl font-bold dark:text-white">Best Practice</h3>
            <p className="text-gray-500 dark:text-gray-400">
            Use with confidence for your projects or learning. Our code-generated platform will generate a <b className='text-primary'>clean</b> and <b className='text-primary'>easy to read</b> codes and always follows the best practices.
            </p>
          </div>
          <div className='text-center'>
            <AboutIcon icon={<FiCode className="text-primary-600 w-5 h-5 lg:w-6 lg:h-6 dark:text-primary-300"></FiCode>}></AboutIcon>
            <h3 className="mb-2 text-xl font-bold dark:text-white">Take Full Control</h3>
            <p className="text-gray-500 dark:text-gray-400">
              You got the <b className='text-primary'>source code</b>. Whether you run it directly or modify the code, up to you.
            </p>
          </div>
          <div className='text-center'>
            <AboutIcon icon={<FiTag className="text-primary-600 w-5 h-5 lg:w-6 lg:h-6 dark:text-primary-300"></FiTag>}></AboutIcon>
            <h3 className="mb-2 text-xl font-bold dark:text-white">Try Before Buy</h3>
            <p className="text-gray-500 dark:text-gray-400">
              We have basic plan which always free, <b className="text-primary">no credit card required</b>. You can upgrade to get full features anytime you like
            </p>
          </div>
          <div className='text-center'>
            <AboutIcon icon={<FiCreditCard className="text-primary-600 w-5 h-5 lg:w-6 lg:h-6 dark:text-primary-300"></FiCreditCard>}></AboutIcon>
            <h3 className="mb-2 text-xl font-bold dark:text-white">Affordable</h3>
            <p className="text-gray-500 dark:text-gray-400">                   
              We are Software Engineers and understand that sometimes SAAS is overpriced. We always focus on bringing you <b className='text-primary'>worth to buy</b> products.
            </p>
          </div>
          <div className='text-center'>
            <AboutIcon icon={<FiFeather className="text-primary-600 w-5 h-5 lg:w-6 lg:h-6 dark:text-primary-300"></FiFeather>}></AboutIcon>
            <h3 className="mb-2 text-xl font-bold dark:text-white">Costumizable</h3>
            <p className="text-gray-500 dark:text-gray-400">
              As you grow, we offer you capability to get custom generated code depend on your framework.
            </p>
          </div>
        </div>
      </div>
    </section>
}

export default AboutSection;