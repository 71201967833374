import request from "../../libs/api/request";
import { ApiSuccessResponse } from "../../libs/api/response";
import { UserToken } from "../usertoken-api/param";
import { AuthApi_ChangePassword, AuthApi_Login, AuthApi_LoginGithub, AuthApi_Register, AuthApi_VerifyUser } from "./auth";
import { User, UserSubscriptions } from "./param";

const GetAuthenticated = () :Promise<ApiSuccessResponse<User>> => {
    return request({
      url: `user/current`,
      method: "GET",
      headers: {needauth: true},
    });
};

const GetSubscriptionHistory = () :Promise<ApiSuccessResponse<UserSubscriptions>> => {
  return request({
    url: `user/current/subscriptions`,
    method: "GET",
    headers: {needauth: true}
  });
};

const Login = (email: string, password: string) :Promise<ApiSuccessResponse<UserToken>> => {
  return request({
    url: `user/auth/login`,
    method: "POST",
    data: {
      email: email,
      password: password,
    }
  });
};


const UserApi_UpdateProfile = (name: string) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `user/current/profile`,
    method: "PUT",
    data: {
      name: name,
    },
    headers: {needauth: true},
  });
};

export const UserApi = {
  GetAuthenticated,
  GetSubscriptionHistory,
  

  UserApi_UpdateProfile,
  AuthApi_ChangePassword,
  AuthApi_Register,
  AuthApi_VerifyUser,
  AuthApi_Login,
  AuthApi_LoginGithub,

  // WorkspaceApi_GetMyWorkspaces,
  // WorkspaceApi_GetMyWorkspaceById,
  // WorkspaceApi_UpdateWorkspace,
  // WorkspaceApi_GetById,
  // WorkspaceApi_AddMember,
  // WorkspaceApi_DeleteMember,
};
  