import request from "../../libs/api/request";
import { ApiSuccessResponse } from "../../libs/api/response";

export const TransactionApi_SimulateOrder = (workspace_id: number, plan_code: string, discount_code: string) :Promise<ApiSuccessResponse<TransactionOrderSimulation>> => {
    return request({
      url: `workspace/transaction/order/simulate`,
      method: "POST",
      headers: {needauth: true},
      data: {
        workspace_id:workspace_id, 
        plan_code:plan_code,
        discount_code:discount_code,
      }
    });
};

export const TransactionApi_CreatePaypalOrder = (workspace_id: number, plan_code: string, discount_code: string) :Promise<ApiSuccessResponse<CreatePaypalOrderResp>> => {
  return request({
    url: `workspace/transaction/order/paypal`,
    method: "POST",
    headers: {needauth: true},
    data: {
      workspace_id:workspace_id, 
      plan_code:plan_code,
      discount_code:discount_code,
    }
  });
};

export const TransactionApi_SettlePaypalPayment = (paypal_order_id: string) :Promise<ApiSuccessResponse<void>> => {
  return request({
    url: `workspace/transaction/order/paypal/settlement`,
    method: "POST",
    headers: {needauth: true},
    data: {
      paypal_order_id:paypal_order_id, 
    }
  });
};

export const TransactionApi_GetTransactionHistory = (workspace_id: number, page: number, size: number) :Promise<ApiSuccessResponse<TransactionOrderHistories>> => {
  return request({
    url: `workspace/transaction/order/${workspace_id}/history?page=${page}&size=${size}`,
    method: "GET",
    headers: {needauth: true},
  });
};

export type TransactionOrder = {
  id: number
  workspace_id: number
  plan_code: string
  plan_length: string
  discount_code: string
  currency: string
  original_amount: number
  amount: number
  discount: number
  total_amount: number
  status: string
  ref_id: string
  ref_url: string
  ref_type: string
}

export type TransactionOrderSimulation = {
  id: number
  workspace_id: number
  plan_code: string
  plan_length: string
  discount_code: string
  currency: string
  original_amount: number
  amount: number
  discount: number
  total_amount: number
  status: string
  ref_id: string
  ref_url: string
  ref_type: string
  discount_code_error: string
}

export type CreatePaypalOrderResp = {
  paypal_order_id: string
}

export type TransactionOrderHistories = {
  transactions: TransactionOrderHistory[]
  pagination: Pagination
}

export type TransactionOrderHistory = {
  id: number
  workspace_id: number
  workspace_plan_id: number
  plan_code: string
  plan_name: string
  plan_length: string
  discount_code: string
  currency: string
  original_amount: number
  amount: number
  discount: number
  total_amount: number
  status: string
  ref_id: string
  ref_url: string
  ref_type: string
  start_at: Date 
  end_at: Date
  created_at: Date
}

export type Pagination = {
  current_page: number
  last_page: number
  from: number
  to: number
  first_page: number
  per_page: number
  total: number
}


