import DashboardLayout from "components/layout/dashboard-layout";
import { Breadcrumb, Spinner } from "flowbite-react";
import { SuperSEO } from "react-super-seo";
import { useParams } from "react-router-dom";
import TabNavigation from "components/tabs/tab-navigation.component";
import useApiGenProject from "hook/useApiGenProject";

const BEApiGeneratorOverviewPage = () => {
  const { code } = useParams()
  const [ data ] = useApiGenProject(code)

  if(data === undefined){
    return <div>
      <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
      <div className="fixed w-full h-screen flex items-center justify-center z-50 bg-slate-500/50">
        <Spinner></Spinner>
      </div>
      <DashboardLayout></DashboardLayout>   
    </div>
  }

  return <div>
    <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
    
    <DashboardLayout>   
      <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/dashboard/be/api-generator">API Generator</Breadcrumb.Item>
          <Breadcrumb.Item>{data.project_name}</Breadcrumb.Item>
      </Breadcrumb>

      <TabNavigation items={[
        { key:"overview", name:"Overview", href:`/dashboard/be/api-generator/${data.code}` },
        { key:"schema", name:"Schema", href:`/dashboard/be/api-generator/${data.code}/schema` },
        { key:"routes", name:"Routes", href:`/dashboard/be/api-generator/${data.code}/routes` },
        { key:"generate", name:"Code Generate", href:`/dashboard/be/api-generator/${data.code}/generate` },
      ]} active={"overview"}></TabNavigation>
      
      <div className="overflow-hidden bg-white shadow mt-4">
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Project Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.project_name}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Service Name</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.service_name}</dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.description}</dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Stack</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.stack}</dd>
            </div>
            
          </dl>
        </div>
      </div>
    </DashboardLayout>
  </div>
}

export default BEApiGeneratorOverviewPage;
