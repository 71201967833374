import DashboardLayout from "components/layout/dashboard-layout";
import { Badge, Breadcrumb, Button, Spinner } from "flowbite-react";
import { SuperSEO } from "react-super-seo";
import { useParams } from "react-router-dom";
import TabNavigation from "components/tabs/tab-navigation.component";
import useApiGenProject from "hook/useApiGenProject";
import { FiPlus, FiTrash } from "react-icons/fi";
import { useEffect, useState } from "react";
import { ApiGenSchemas, ApiGenSchema, Column, ApiGenRoutes, HTTPMethod } from "repositories/apigen-api/param";
import { ApiErrorResponse, UnknownError } from "libs/api/response";
import { CodegenApi } from "repositories/apigen-api";
import { toast } from "react-toastify";
import RoutesCreateCrudModal, { CreateCrudModalState } from "./routes.create-crud.modal";
import RoutesDeleteModal, { RoutesDeleteModalState } from "./routes.delete.modal";
import RoutesCreateUpdateModal, { RoutesCreateUpdateModalState } from "./routes.create-update.modal";

const http_method_style_tw = new Map<HTTPMethod, string>(
  [
    [HTTPMethod.GET, "primary"],
    [HTTPMethod.POST, "success"],
    [HTTPMethod.PUT, "warning"],
    [HTTPMethod.PATCH, "secondary"],
    [HTTPMethod.DELETE, "danger"],
  ]
)

const BEApiGeneratorRoutesPage = () => {
  const { code } = useParams()
  const [ project ] = useApiGenProject(code)
  const [ schemas, schemas_err, schemas_mutate ] = useApiGenSchemas(code)
  const [ routes, routes_err, routes_mutate ] = useApiGenRoutes(code)
  
  const [ createCrudModal, setCreateCrudModal ] = useState<CreateCrudModalState>({show: false})
  const [ deleteModal, setDeleteModal ] = useState<RoutesDeleteModalState>({show: false, id:0})

  const [createRoutesModal, setCreateRoutesModal] = useState<RoutesCreateUpdateModalState>({show: false})  
  
  if(project === undefined){
    return <div>
      <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
      <div className="fixed w-full h-screen flex items-center justify-center z-50 bg-slate-500/50">
        <Spinner></Spinner>
      </div>
      <DashboardLayout></DashboardLayout>   
    </div>
  }

  return <div>
    <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
    
    <DashboardLayout>   
      <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/dashboard/be/api-generator">API Generator</Breadcrumb.Item>
          <Breadcrumb.Item>{project.project_name}</Breadcrumb.Item>
      </Breadcrumb>

      <TabNavigation items={[
        { key:"overview", name:"Overview", href:`/dashboard/be/api-generator/${project.code}` },
        { key:"schema", name:"Schema", href:`/dashboard/be/api-generator/${project.code}/schema` },
        { key:"routes", name:"Routes", href:`/dashboard/be/api-generator/${project.code}/routes` },
        { key:"generate", name:"Code Generate", href:`/dashboard/be/api-generator/${project.code}/generate` },
      ]} active={"routes"}></TabNavigation>
      
      <RoutesCreateUpdateModal state={createRoutesModal} setState={setCreateRoutesModal} onSuccess={() => routes_mutate()}></RoutesCreateUpdateModal>
      <RoutesCreateCrudModal state={createCrudModal} setState={setCreateCrudModal} onSuccess={() => routes_mutate()}></RoutesCreateCrudModal>
      <RoutesDeleteModal state={deleteModal} setState={setDeleteModal} onSuccess={() => routes_mutate()}></RoutesDeleteModal>

      <div className="mt-4"></div>
      {
        schemas.schemas.map((s, i) => 
        <div key={`schemas-${s.name}`}>
          <div className="flex flex-row mt-4">
            <div className="text-xl text-light-700 grow">{s.name}</div>
            <Button color={"primary"} type="button" size="xs" 
              onClick={() => setCreateRoutesModal({show: true, schema: s, route: undefined})}
            ><FiPlus className="mr-1" /> Add Route</Button>
          </div>
          <hr className="mb-2 mt-1"/>
          <div className="">
            {routes.routes.filter((r) => r.schema_id === s.id).length === 0 && 
            <div className="">
            <div className="text-sm text-center text-light mt-4">No routes has been created, create your first route. </div>
            <div className="flex justify-center mt-2">
              <Button type="button" color={"primary"} className="self-center" size={"xs"} onClick={ ()=> {
                setCreateCrudModal({show: true, schema: s})
              }}>Generate CRUD Routes</Button>
            </div>
            </div>
            }
            {
            routes.routes.filter((r) => r.schema_id === s.id).map( (r, r_idx) => 
            <div key={`schemas-${s.name}-routes-${r.id}`} className={`flex gap-x-2 items-center w-full border-2 border-light bg-white rounded p-2 mb-2 cursor-pointer`}
              onClick={ () => {
                setCreateRoutesModal({show: true, schema: s, route: r})
              }}
            >
              <Badge color={http_method_style_tw.get(r.http_method)} className="w-fit !p-2 w-[65px] justify-center">{ r.http_method }</Badge>
              <div className="text-light-800 font-medium">{r.path}</div>
              <div className="ml-2 text-sm text-light font-normal">{r.name}</div>
              <div className="grow"></div>
              <button type="button" className={`inline-flex items-center justify-center w-8 h-8 text-primary-50 transition-colors duration-150 bg-danger-700 rounded-full focus:shadow-outline hover:bg-danger-800`} 
                onClick={(e) => { 
                  setDeleteModal({ id: r.id, show: true })
                  e.stopPropagation()
                }}
                >
                <FiTrash/>
              </button>
            </div>
            )}
            
          </div>
        </div>)
      }
      
    </DashboardLayout>
  </div>
}

export default BEApiGeneratorRoutesPage;

function useApiGenSchemas(project_code: string | undefined) :[ApiGenSchemas, ApiErrorResponse | undefined, ()=>void] {
    const [result, setResult] = useState<ApiGenSchemas>({schemas: []});
    const [error, setError] = useState<ApiErrorResponse>();

    async function fetch(_project_code: string) {
      try {
        var res = await CodegenApi.ApiGenSchema_GetByProjectCode(_project_code);
        setResult(res.data);
      } catch (error) {
        if(error as ApiErrorResponse){
        
          toast.error((error as ApiErrorResponse).message)
          setError((error as ApiErrorResponse));
        } else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
          toast.error("Internal Error")
        }
      } finally {
      }
    }

    useEffect(() => {
      if(project_code !== undefined){
        fetch(project_code)
      }
    }, [project_code]);
  
    
    return [result, error, () => {
      if(project_code !== undefined){
        fetch(project_code)
      }
    }];
}

function useApiGenRoutes(project_code: string | undefined) :[ApiGenRoutes, ApiErrorResponse | undefined, ()=>void] {
  const [result, setResult] = useState<ApiGenRoutes>({routes: []});
  const [error, setError] = useState<ApiErrorResponse>();

  async function fetch(_project_code: string) {
    try {
      var res = await CodegenApi.ApiGenRoute_GetByProjectCode(_project_code);
      setResult(res.data);
    } catch (error) {
      if(error as ApiErrorResponse){
      
        toast.error((error as ApiErrorResponse).message)
        setError((error as ApiErrorResponse));
      } else {
        console.log("Unknown error:",error);
        setError(UnknownError(""));
        toast.error("Internal Error")
      }
    } finally {
    }
  }

  useEffect(() => {
    if(project_code !== undefined){
      fetch(project_code)
    }
  }, [project_code]);

  
  return [result, error, () => {
    if(project_code !== undefined){
      fetch(project_code)
    }
  }];
}



  
// function useApiGenSchemaByName(project_code: string | undefined, name: string) :[ApiGenSchema | undefined, ApiErrorResponse | undefined] {
//   const [result, setResult] = useState<ApiGenSchema>();
//   const [error, setError] = useState<ApiErrorResponse>();

//   async function fetch(_project_code: string, _name: string) {
//     try {
//       var res = await CodegenApi.ApiGenSchema_GetByName(_project_code, _name);
//       setResult(res.data);
//     } catch (error) {
//       if(error as ApiErrorResponse){
      
//         toast.error((error as ApiErrorResponse).message)
//         setError((error as ApiErrorResponse));
//       } else {
//         console.log("Unknown error:",error);
//         setError(UnknownError(""));
//         toast.error("Internal Error")
//       }
//     } finally {
//     }
//   }

//   useEffect(() => {
//     if(project_code !== undefined){
//       if(name !== ""){
//         fetch(project_code, name)
//       } else {
//         setResult(undefined)
//       }
//     }
//   }, [project_code, name]);

  
//   return [result, error];
// }
