import { Button, Checkbox, Label, Spinner, Table, TextInput } from "flowbite-react";
import { FiX, FiPlus, FiArrowUp, FiArrowDown, FiEdit } from "react-icons/fi";
import { useEffect, useState } from "react";
import { ApiGenSchema, Column, ApiGenSchemaColumn, DatabaseServer, ApiGenProject, ColumnType } from "repositories/apigen-api/param";
import { ApiErrorResponse } from "libs/api/response";
import { CodegenApi } from "repositories/apigen-api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { slugify } from "libs/strings/slugify";
import SchemaColumnAddModal from "./schema.column.add.modal";
import SchemaColumnEditModal, { SchemaColumnEditModalState } from "./schema.column.edit.modal";


const SchemaEditComp = (props: { project: ApiGenProject, schema: ApiGenSchema, onSaved: (data: ApiGenSchema) => void }) => {
  const [submitting, setSubmitting] = useState(false)

  const [addColumnModal, setAddColumnModal] = useState(false)
  const [editColumnModal, setEditColumnModal] = useState<SchemaColumnEditModalState>({show: false, current: ApiGenSchemaColumn.primaryKeyDefault(DatabaseServer.Postgres), onSuccess: (data) => {}})
  const [timestampsModal, setTimestampsModal] = useState(false)

  const [columnPk, setColumnPk] = useState(ApiGenSchemaColumn.primaryKeyDefault(props.project.database))
  const [columns, setColumns] = useState<ApiGenSchemaColumn[]>([])
  const [columnTimestamp, setColumnTimestamp] = useState<ApiGenSchemaColumn[]>([])

  const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
    timestamp_created: yup.string(),
    timestamp_updated: yup.string(),
    softdelete: yup.string(),
    columns: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        column_type: yup.string().required(),
        column_size: yup.number().min(0),
        column_decimal: yup.number().min(0),
        enum_values: yup.array().of(yup.string()).when("column_type", {
          is: "enum",
          then: yup.array().of(yup.string()).test({
            message: 'enum values is required',
            test:  arr => arr !== undefined && arr.length > 0,
          })
        }),
        nullable: yup.boolean(),
    }))
  })
  const { register, handleSubmit, formState: { errors  }, setError, setValue } = useForm<ApiGenSchema>({ mode: 'onChange', resolver: yupResolver(ValidationSchema), defaultValues:{} });

  const [timestampCreated, setTimestampCreated] = useState(props.schema.timestamp_created)
  const [timestampUpdated, setTimestampUpdated] = useState(props.schema.timestamp_updated)
  const [softdelete, setSoftdelete] = useState(props.schema.softdelete)

  useEffect( () => {
    setValue('name', props.schema.name, { shouldValidate: true })
    setTimestampCreated(props.schema.timestamp_created)
    setTimestampUpdated(props.schema.timestamp_updated)
    setSoftdelete(props.schema.softdelete)

    const _columnPk = props.schema.columns.find( (e) => e.name === props.schema.primary_key )
    if(_columnPk !== undefined) { setColumnPk(_columnPk)}
    
    const _columns = props.schema.columns.filter( (e) => 
      e.name !== props.schema.primary_key
    ) ?? []
    setColumns(_columns)

  }, [props.schema] )


  const onSubmitHandler = async (data: ApiGenSchema) => {
    if((timestampCreated !== "" && timestampUpdated === "") || (timestampCreated === "" && timestampUpdated !== "")){
      toast.error("either both 'Timestamp Created' & 'Timestamp Updated' checked or both unchecked")
      return
    }

    var param: ApiGenSchema = {...data}
    param.id = props.schema.id
    param.name = data.name
    param.project_id = props.project.id
    param.primary_key = columnPk.name
    param.timestamp_created = timestampCreated
    param.timestamp_updated = timestampUpdated
    param.softdelete = softdelete
    param.columns = []
    param.columns.push(columnPk, ...columns)

    try {
      setSubmitting(true)
      await CodegenApi.ApiGenSchema_Update(param)
      toast.success("Update Success")
      props.onSaved(param)
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "name": setError("name", { type: "focus", message: e.message }, { shouldFocus: true }); break;
          }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };
  
  return <div>
    <div className="flex flex-row gap-x-2">
      <div className="flex-1">
        <div className="text-light font-bold text-lg">Edit Schema</div>
      </div>
      <div className="flex gap-x-2">
      </div>
    </div>

    <SchemaColumnAddModal show={addColumnModal} setShow={setAddColumnModal} current_columns={columns} database={props.project.database} 
      onSuccess={ (data) =>{ 
        setColumns([
          ...columns.filter((c) => c.name !== timestampCreated && c.name !== timestampUpdated && c.name !== softdelete ), 
          data, 
          ...columns.filter((c) => c.name === timestampCreated || c.name === timestampUpdated || c.name === softdelete ), 
        ])
      }}
    ></SchemaColumnAddModal>
    <SchemaColumnEditModal state={editColumnModal} setState={setEditColumnModal} current_columns={columns} database={props.project.database}></SchemaColumnEditModal>

    <div className="mt-4">
      <form key="rest-api-domain-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)} >
        <div>
          <div className="mb-2 block"> <Label htmlFor="name" value="Schema Name" /></div>
          <TextInput {...register("name")} id="name"  defaultValue={""} onChange={(e) => { setValue("name", slugify(e.target.value.toLowerCase(), "_")); }}/>
          <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>) }</p>
        </div>

        <div>
          <div className="flex items-center gap-2">
            <Checkbox checked={timestampCreated !== "" && timestampUpdated !== ""} onChange={ (event) => {
              if(event.target.checked){
                setColumns([
                  ...columns.filter((e) => e.name !== "created_at" && e.name !== "updated_at"), 
                  { 
                    name: "created_at", 
                    column_type: props.project.database === DatabaseServer.Postgres ? ColumnType.Timestamptz : ColumnType.Timestamp, 
                    column_decimal: 0, 
                    column_size: 0, 
                    default: "", 
                    enum_values: [], 
                    nullable: false 
                  },
                  { 
                    name: "updated_at", 
                    column_type: props.project.database === DatabaseServer.Postgres ? ColumnType.Timestamptz : ColumnType.Timestamp, 
                    column_decimal: 0, 
                    column_size: 0, 
                    default: "", 
                    enum_values: [], 
                    nullable: false 
                  }
                ]);
                setTimestampCreated("created_at");
                setTimestampUpdated("updated_at");
              } else {
                setColumns([...columns.filter((e) => e.name !== timestampCreated && e.name !== timestampUpdated)]);
                setTimestampCreated("");
                setTimestampUpdated("");
              }
              
            }} />
            <Label htmlFor="withTimestamp"> With Timestamp </Label>
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2">
            <Checkbox checked={softdelete !== ""} onChange={ (event) => {
              if(event.target.checked){
                setColumns([
                  ...columns.filter((e) => e.name !== "deleted_at"), 
                  { 
                    name: "deleted_at", 
                    column_type: props.project.database === DatabaseServer.Postgres ? ColumnType.Timestamptz : ColumnType.Timestamp, 
                    column_decimal: 0, 
                    column_size: 0, 
                    default: "", 
                    enum_values: [], 
                    nullable: true 
                  }
                ]);
                setSoftdelete("deleted_at");
              } else {
                setColumns([...columns.filter((e) => e.name !== softdelete)]);
                setSoftdelete("");
              }
              
            }} />
            <Label htmlFor="softdelete"> Soft Delete </Label>
          </div>
        </div> 

        <div className="flex gap-x-1">
          <div className="grow"></div>
          <Button color={"primary"} type="button" size="sm" onClick={ () => setAddColumnModal(true) }><FiPlus className="mr-1" /> Add Column</Button>
        </div>

        <Table striped={false} className={""}>
          <Table.Head className="bg-primary text-white">
            <Table.HeadCell className=""> Swap </Table.HeadCell>
            <Table.HeadCell> Column </Table.HeadCell>
            <Table.HeadCell> Type </Table.HeadCell>
            <Table.HeadCell> Nullable </Table.HeadCell>
            <Table.HeadCell> Notes </Table.HeadCell>
            <Table.HeadCell> Action </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            <Table.Row key={"column-pk-id"} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <Table.Cell className="whitespace-nowrap items-center space-x-3 "></Table.Cell>
              <Table.Cell className="whitespace-nowrap">{ columnPk.name}</Table.Cell>
              <Table.Cell>{ Column.fullColumnType(columnPk) }</Table.Cell>
              <Table.Cell>{columnPk.nullable ? "Yes" : "No"}</Table.Cell>
              <Table.Cell>Primary Key</Table.Cell>
              <Table.Cell className="flex items-center space-x-3">
                {/* <Button color="primary" size="xs" onClick={()=>setEditColumnPkModal(true)}><FiEdit className="mr-1"></FiEdit> Edit</Button> */}
              </Table.Cell>
            </Table.Row>

            {
              columns.map( (e, i ) => {
                return <Table.Row key={"column-normal-" + i.toString()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <Table.Cell className="whitespace-nowrap items-center space-x-3 ">
                  <button type="button" disabled={i === 0} 
                    onClick={() => setColumns(ApiGenSchemaColumn.ArraySwap(columns, i, "up")) } 
                  className="inline-flex items-center justify-center w-8 h-8 text-light-700 transition-colors duration-150 bg-light-200 rounded-full focus:shadow-outline hover:bg-light-500 disabled:bg-light-500">
                    <FiArrowUp className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"></FiArrowUp>
                  </button>
                  <button type="button" disabled={i === columns.length - 1} 
                    onClick={() => setColumns(ApiGenSchemaColumn.ArraySwap(columns, i, "down"))} 
                    className="inline-flex items-center justify-center w-8 h-8 text-light-700 transition-colors duration-150 bg-light-200 rounded-full focus:shadow-outline hover:bg-light-500 disabled:bg-light-500">
                    <FiArrowDown className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor"></FiArrowDown>
                  </button>
                </Table.Cell>
                <Table.Cell className="whitespace-nowrap">{e.name}</Table.Cell>
                <Table.Cell>{Column.fullColumnType(e)}</Table.Cell>
                <Table.Cell>{e.nullable ? "Yes" : "No"}</Table.Cell>
                <Table.Cell>
                  {
                    (e.column_type === ColumnType.Timestamp || e.column_type === ColumnType.Timestamptz) &&
                    <div>
                      <div className="flex items-center gap-2">
                        <Checkbox checked={e.name === timestampCreated} onChange={ (event) => {
                          if(event.target.checked){
                            setTimestampCreated(e.name)
                            if(e.name === timestampUpdated) {
                              setTimestampUpdated("")
                            }
                            if(e.name === softdelete) {
                              setSoftdelete("")
                            }
                          } else {
                            setTimestampCreated("")
                          }
                        }} />
                        <Label className="text-gray-500"> Timestamp Created </Label>
                      </div>
                      <div className="flex items-center gap-2">
                        <Checkbox checked={e.name === timestampUpdated} onChange={ (event) => {
                           if(event.target.checked){
                            setTimestampUpdated(e.name)
                            if(e.name === timestampCreated) {
                              setTimestampCreated("")
                            }
                            if(e.name === softdelete) {
                              setSoftdelete("")
                            }
                          } else {
                            setTimestampUpdated("")
                          }
                        }} />
                        <Label className="text-gray-500"> Timestamp Updated </Label>
                      </div>
                      {e.nullable && 
                      <div className="flex items-center gap-2">
                        <Checkbox checked={e.name === softdelete} onChange={ (event) => {
                          if(event.target.checked){
                            setSoftdelete(e.name)
                            if(e.name === timestampCreated) {
                              setTimestampCreated("")
                            }
                            if(e.name === timestampUpdated) {
                              setTimestampUpdated("")
                            }
                          } else {
                            setSoftdelete("")
                          }
                        }} />
                        <Label> Soft Delete </Label>
                      </div>
                      }
                    </div>
                  }
                 
                </Table.Cell>
                <Table.Cell className="flex items-center space-x-3">
                  <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-primary-50 transition-colors duration-150 bg-primary-700 rounded-full focus:shadow-outline hover:bg-primary-800" 
                    onClick={()=> setEditColumnModal({...editColumnModal, show: true, current: e, onSuccess: (data) => {
                      var tmp = [...columns]
                      tmp[i] = data
                      setColumns(tmp)
                    }})}
                  >
                    <FiEdit/>
                  </button>
                  <button type="button" className="inline-flex items-center justify-center w-8 h-8 text-primary-50 transition-colors duration-150 bg-danger-700 rounded-full focus:shadow-outline hover:bg-danger-800" 
                    onClick={()=> {
                      setColumns(columns.filter( (c) => e.name !== c.name ))
                      if(timestampCreated === e.name) setTimestampCreated("")
                      if(timestampUpdated === e.name) setTimestampUpdated("")
                      if(softdelete === e.name) setSoftdelete("")
                    }}
                  >
                    <FiX/>
                  </button>
                </Table.Cell>
              </Table.Row>
              } )
            }
            {
              columnTimestamp.map( (e, i ) => {
                return <Table.Row key={"column-timestamp-" + i.toString()} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <Table.Cell className="whitespace-nowrap items-center space-x-3 "></Table.Cell>
                <Table.Cell className="whitespace-nowrap">{e.name}</Table.Cell>
                <Table.Cell>{Column.fullColumnType(e)}</Table.Cell>
                <Table.Cell>{e.nullable ? "Yes" : "No"}</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell className="flex items-center space-x-3">
                </Table.Cell>
              </Table.Row>
              } )
            }
          </Table.Body>
        </Table>
        <div className="self-center mt-8">
          <Button type="submit" color="primary" disabled={submitting}>
            Save
            {submitting && (
            <div className="ml-3">
              <Spinner size="sm" light={true} />
            </div>
            )}
          </Button>
        </div>
      </form>
    </div>
  </div>
}

export default SchemaEditComp;
