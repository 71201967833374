import React, { useEffect, useState } from "react";
import { Button, Label, Modal, Select, Spinner, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import { WorkspaceApi } from "repositories/workspace-api";

const DeleteWorkspaceMemberModal = (props: {state: {show: boolean, workspace_id: number, email: string}, setState: React.Dispatch<React.SetStateAction<{show: boolean, workspace_id: number, email: string}>>, onSuccess: () => void}) => {
  const [submitting, setSubmitting] = useState(false)

  const onSubmitHandler = async () => {
    if(props.state.workspace_id <= 0) return
    try {
      setSubmitting(true)
      await WorkspaceApi.WorkspaceApi_DeleteMember(props.state.workspace_id, props.state.email)
      props.onSuccess()
      props.setState({...props.state, show:false})
      toast.success("Delete Member Success")
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };
  
  return <>
      <Modal show={props.state.show} size="sm" onClose={ ()=> props.setState({...props.state, show: false})} >
      <Modal.Header>Delete Confirmation</Modal.Header>
      <Modal.Body>
        <form key="delete-member-form" className="flex flex-col gap-4">
          <div>
            {/* <div className="mb-2 block"> <Label htmlFor="name" value="Field / Column Name" /></div> */}
            <div className="">Are you sure to delete this member?</div>
          </div>
          <div className="flex flex-wrap gap-4 self-center">
            <Button color="light" onClick={()=>{props.setState({...props.state, show: false})}}>
              Cancel
            </Button>
            <Button color={"failure"} onClick={onSubmitHandler} disabled={submitting}>
              Delete
              {submitting && (
              <div className="ml-3">
                  <Spinner size="sm" light={true} />
              </div>
              )}
            </Button>
          </div>
        </form> 
      </Modal.Body>
    </Modal>
  </>
}

export default DeleteWorkspaceMemberModal