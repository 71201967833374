import DashboardLayout from "components/layout/dashboard-layout";
import { Breadcrumb, Button, Spinner } from "flowbite-react";
import { SuperSEO } from "react-super-seo";
import { useParams } from "react-router-dom";
import TabNavigation from "components/tabs/tab-navigation.component";
import useApiGenProject from "hook/useApiGenProject";
import { FiX, FiPlus } from "react-icons/fi";
import { useEffect, useState } from "react";
import { ApiGenSchemas, ApiGenSchema } from "repositories/apigen-api/param";
import { ApiErrorResponse, UnknownError } from "libs/api/response";
import { CodegenApi } from "repositories/apigen-api";
import { toast } from "react-toastify";
import CreateSchemaModal from "./schema.create.modal";
import DeleteSchemaModal, { DeleteSchemaModalState } from "./schema.delete.modal";
import SchemaEditComp from "./schema.edit.component";
import { useWorkspace } from "libs/context/workspace";

const BEApiGeneratorSchemaPage = () => {
  const { code } = useParams()
  const [ project ] = useApiGenProject(code)
  const [ schemas, schemas_err, schemas_mutate ] = useApiGenSchemas(code)
  const curr_workspace =  useWorkspace()

  const [ selectedSchema, setSelectedSchema ] = useState("")
  const [ schema_edit ] = useApiGenSchemaByName(project?.code, selectedSchema)

  const [ createModal, setCreateModal ] = useState<boolean>(false)
  const [ deleteModal, setDeleteModal ] = useState<DeleteSchemaModalState>({id:0, show:false})
  
  
  if(project === undefined){
    return <div>
      <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
      <div className="fixed w-full h-screen flex items-center justify-center z-50 bg-slate-500/50">
        <Spinner></Spinner>
      </div>
      <DashboardLayout></DashboardLayout>   
    </div>
  }

  return <div>
    <SuperSEO title="Backend Tools: Api Generator" description="Backend Tools: Api Generator" lang="en" />
    
    <DashboardLayout>   
      <Breadcrumb className="mb-4">
          <Breadcrumb.Item href="/dashboard/be/api-generator">API Generator</Breadcrumb.Item>
          <Breadcrumb.Item>{project.project_name}</Breadcrumb.Item>
      </Breadcrumb>

      <TabNavigation items={[
        { key:"overview", name:"Overview", href:`/dashboard/be/api-generator/${project.code}` },
        { key:"schema", name:"Schema", href:`/dashboard/be/api-generator/${project.code}/schema` },
        { key:"routes", name:"Routes", href:`/dashboard/be/api-generator/${project.code}/routes` },
        { key:"generate", name:"Code Generate", href:`/dashboard/be/api-generator/${project.code}/generate` },
      ]} active={"schema"}></TabNavigation>
      
      <CreateSchemaModal setShow={setCreateModal} show={createModal} project_id={project.id} onSuccess={() => schemas_mutate()}></CreateSchemaModal>
      <DeleteSchemaModal setState={setDeleteModal} state={deleteModal}  onSuccess={() => {schemas_mutate(); setSelectedSchema("");}}></DeleteSchemaModal>

      <div className="flex gap-4 py-8 min-h-[500px]">
        <div className="w-1/4 flex flex-col border-tertiary pr-2 mr-2 border-r ">
          <Button color={"primary"} type="button" size="xs" onClick={() => setCreateModal(true)}><FiPlus className="mr-1" /> New Schema</Button>
          {
          curr_workspace.workspace?.plan_code === "free" &&
          <div className="text-xs text-danger font-medium mt-2">*Columns for free plan: {schemas.schemas.reduce((sum, current) => sum + current.columns.length, 0 )}/{curr_workspace.workspace.max_codegen_schema_columns}</div>
          }
          {
          schemas.schemas.length <= 0 &&
          <div className="text-sm text-light mt-2">No schemas has been created, create your first schema</div>
          }
          <ul className="mt-5 flex-1 min-h-0 overflow-y-auto">
            {
            schemas.schemas.map( (s) => 
            <li key={`schema-choice-${s.name}`}>
              <div className={"pl-4 pr-2 mb-1 cursor-pointer font-bold text-sm flex items-center h-10 rounded-lg hover:bg-primary-50 " + (selectedSchema === s.name ? 'bg-primary-50' : '')} 
                onClick={() => setSelectedSchema( selectedSchema === s.name ? '' : s.name)}>
                <div className="flex-1">{s.name}</div>
                <div className="flex-0">
                  <button type="button" className="inline-flex items-center justify-center w-6 h-6 text-primary-50 transition-colors duration-150 bg-danger-700 rounded-full focus:shadow-outline hover:bg-danger-800" 
                    onClick={(e) => { 
                      setDeleteModal({ id: s.id, show: true })
                      e.stopPropagation()
                    }}>
                    <FiX/>
                  </button>
                </div>
              </div>
            </li>
            )}
          </ul>
          
        </div>
        <div className="w-3/4">
          {
          schema_edit !== undefined ?
          <SchemaEditComp project={project} schema={schema_edit} onSaved={ (data) => { 
            schemas_mutate();
            if(selectedSchema !== data.name){
              setSelectedSchema(data.name)
            }
          }}></SchemaEditComp> : 
          <div className="text-sm text-light mt-4">Select schema in the sidebar</div>  
          }
        </div>
      </div>
    </DashboardLayout>
  </div>
}

export default BEApiGeneratorSchemaPage;

function useApiGenSchemas(project_code: string | undefined) :[ApiGenSchemas, ApiErrorResponse | undefined, ()=>void] {
    const [result, setResult] = useState<ApiGenSchemas>({schemas: []});
    const [error, setError] = useState<ApiErrorResponse>();

    async function fetch(_project_code: string) {
      try {
        var res = await CodegenApi.ApiGenSchema_GetByProjectCode(_project_code);
        setResult(res.data);
      } catch (error) {
        if(error as ApiErrorResponse){
        
          toast.error((error as ApiErrorResponse).message)
          setError((error as ApiErrorResponse));
        } else {
          console.log("Unknown error:",error);
          setError(UnknownError(""));
          toast.error("Internal Error")
        }
      } finally {
      }
    }

    useEffect(() => {
      if(project_code !== undefined){
        fetch(project_code)
      }
    }, [project_code]);
  
    
    return [result, error, () => {
      if(project_code !== undefined){
        fetch(project_code)
      }
    }];
}
  
function useApiGenSchemaByName(project_code: string | undefined, name: string) :[ApiGenSchema | undefined, ApiErrorResponse | undefined] {
  const [result, setResult] = useState<ApiGenSchema>();
  const [error, setError] = useState<ApiErrorResponse>();

  async function fetch(_project_code: string, _name: string) {
    try {
      var res = await CodegenApi.ApiGenSchema_GetByName(_project_code, _name);
      setResult(res.data);
    } catch (error) {
      if(error as ApiErrorResponse){
      
        toast.error((error as ApiErrorResponse).message)
        setError((error as ApiErrorResponse));
      } else {
        console.log("Unknown error:",error);
        setError(UnknownError(""));
        toast.error("Internal Error")
      }
    } finally {
    }
  }

  useEffect(() => {
    if(project_code !== undefined){
      if(name !== ""){
        fetch(project_code, name)
      } else {
        setResult(undefined)
      }
    }
  }, [project_code, name]);

  
  return [result, error];
}
