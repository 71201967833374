
import request from "../../libs/api/request";
import { ApiSuccessResponse } from "../../libs/api/response";

export const WorkspaceApi_GetMyWorkspaces = () :Promise<ApiSuccessResponse<MyWorkspaces>> => {
    return request({
      url: `workspace/current`,
      method: "GET",
      headers: {needauth: true},
    });
};
  
export const WorkspaceApi_GetMyWorkspaceById = (id: number) :Promise<ApiSuccessResponse<MyWorkspace>> => {
    return request({
        url: `workspace/current/${id}`,
        method: "GET",
        headers: {needauth: true}
    });
};

export const WorkspaceApi_GetById = (id: number) :Promise<ApiSuccessResponse<Workspace>> => {
    return request({
      url: `workspace/view/${id}`,
      method: "GET",
      headers: {needauth: true}
    });
};

export const WorkspaceApi_UpdateWorkspace = (id: number, name: string) :Promise<ApiSuccessResponse<void>> => {
    return request({
      url: `workspace/workspace/${id}/update`,
      method: "PUT",
      data: {
        name: name
      },
      headers: {needauth: true}
    });
  };
  
  export const WorkspaceApi_AddMember = (workspace_id: number, email: string, role: "owner"|"member") :Promise<ApiSuccessResponse<void>> => {
    return request({
      url: `workspace/workspace/${workspace_id}/member`,
      method: "POST",
      data: {email:email,role:role},
      headers: {needauth: true}
    });
  };
  
  export const WorkspaceApi_DeleteMember = (workspace_id: number, email: string) :Promise<ApiSuccessResponse<void>> => {
    return request({
      url: `workspace/workspace/${workspace_id}/member?email=${email}`,
      method: "DELETE",
      headers: {needauth: true}
    });
  };
  

export type MyWorkspace = {
    id: number
    name: string
    plan_code: string
    plan_name: string
    max_workspace_member: number
    max_codegen_schema_columns: number
    max_codegen_project: number
    role: "owner" | "member"
}
  
export type MyWorkspaces = {
    workspaces: MyWorkspace[]
}

export type Workspace = {
    id: number
    name: string
    plan_code: string
    plan_name: string
    max_workspace_member: number
    max_codegen_schema_columns: number
    max_codegen_project: number
    role: "owner" | "member"
    members: WorkspaceMember[]
}

export type WorkspaceMember = {
    id: number
    user_id: number 
    email: string
    name: string
    role: "owner" | "member"
    status:boolean
    is_register:boolean
  }
  