import { createContext, useState, useContext, useEffect, ReactNode } from 'react'
import { ApiErrorResponse } from '../api/response';
import { useAuth } from './auth';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { MyWorkspace } from 'repositories/workspace-api/workspace';
import { WorkspaceApi } from 'repositories/workspace-api';

const KEY_SELECTED_WORKSPACE = "selected_workspace"

export type workspace_ctx = {
    workspace?: MyWorkspace
    workspaceList: MyWorkspace[]
    setWorkspace: (w: MyWorkspace) => void
    mutate: () => void
};

const WorkspaceContext = createContext<workspace_ctx>({workspaceList:[], setWorkspace: () => {}, mutate: ()=>{}});

export const WorkspaceProvider = (props: {
    children?: ReactNode 
}) => {
    const { isAuthenticated } = useAuth()
    const [currentWorkspace, setCurrentWorkspace] = useState<MyWorkspace>()
    const [workspaceList, setWorkspaceList] = useState<MyWorkspace[]>([])

  
    const load_workspace = async () => {
        var workspace_list:MyWorkspace[] = []
        try {
            const resp = await WorkspaceApi.WorkspaceApi_GetMyWorkspaces()
            if(resp.data.workspaces.length > 0){
                workspace_list = resp.data.workspaces
                setWorkspaceList(resp.data.workspaces)
            } else {
                toast.error("have no workspaces: please contact admin")
                setCurrentWorkspace(undefined)
                setWorkspaceList([])
                return
            }
        } catch (error) {
            if(error as ApiErrorResponse){
                toast.error((error as ApiErrorResponse).message)
            } else {
                console.log("Unknown error:",error);
                toast.error("Internal Error")
            }      
            return
        }

        const ws_id = Cookies.get(KEY_SELECTED_WORKSPACE)
        if(ws_id !== undefined && ws_id !== "") {
            try {
                const resp = await WorkspaceApi.WorkspaceApi_GetMyWorkspaceById(Number(ws_id))
                setCurrentWorkspace(resp.data)
                return
            }catch (error) {               
                setCurrentWorkspace(workspace_list[0])
                Cookies.set(KEY_SELECTED_WORKSPACE, workspace_list[0].id.toString())
                console.log("workspace", error)
            }
        } else {
            setCurrentWorkspace(workspace_list[0])
            Cookies.set(KEY_SELECTED_WORKSPACE, workspace_list[0].id.toString())
        }
    }

    const set_workspace = async (w: MyWorkspace) => {
        await Cookies.set(KEY_SELECTED_WORKSPACE, w.id.toString())
        load_workspace()
    }

    useEffect(() => {
        if(isAuthenticated){
            load_workspace()
        }
    }, [isAuthenticated])

    return (
        <WorkspaceContext.Provider value={{ 
            workspace: currentWorkspace, 
            setWorkspace: set_workspace, 
            workspaceList: workspaceList,
            mutate: load_workspace,
        }}>
            {props.children}
        </WorkspaceContext.Provider>
    )
}

export const useWorkspace = () => useContext(WorkspaceContext)
