import React, { useEffect, useState } from "react";
import { Badge, Button, Label, Modal, Select, Spinner, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import { ApiGenProject, DatabaseServer, Framework, ProgrammingLanguage } from "repositories/apigen-api/param";
import { CodegenApi } from "repositories/apigen-api";
import { slugify } from "libs/strings/slugify";
import { useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const CreateSchemaModal = (props: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, project_id: number, onSuccess: () => void}) => {
  const [submitting, setSubmitting] = useState(false)

  const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
  })
  const { register, handleSubmit, formState: { errors  }, setValue, reset, setError } = useForm<{name: string}>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });
  
  useEffect(() => {
    if(props.show){ 
    }
  }, [props.show]);

  const onSubmitHandler = async (data: {name: string}) => {
    try {
      setSubmitting(true)
      await CodegenApi.ApiGenSchema_Create(props.project_id, data.name)
      props.onSuccess()
      reset()
      props.setShow(false)
      toast.success("Create Schema Success")
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "name": setError("name", { type: "focus", message: e.message }, { shouldFocus: true }); break;
         }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };
  
  return <>
      <Modal show={props.show} onClose={ ()=> { props.setShow(false) }} >
          <Modal.Header>New Schema</Modal.Header>
          <Modal.Body>
            <form key="create-schema-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)} >
              <div>
                <div className="mb-2 block"> <Label htmlFor="name" value="Schema (Table) Name"/></div>
                <TextInput {...register("name")} id="name" defaultValue={""} onChange={(e) => {
                  setValue("name", slugify(e.target.value, "_"), {shouldValidate: true});
                }} />
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>) }</p>
              </div>
              
              <div className="self-center">
                <Button type="submit" color="primary" disabled={submitting}>
                  Create
                  {submitting && (
                  <div className="ml-3">
                    <Spinner size="sm" light={true} />
                  </div>
                  )}
                </Button>
              </div>
            </form> 
          </Modal.Body>
      </Modal>
  </>
}

export default CreateSchemaModal