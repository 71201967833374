import React, { useState } from "react";
import { Button, Modal, Spinner } from "flowbite-react";
import { toast } from "react-toastify";
import { ApiErrorResponse } from "libs/api/response";
import { CodegenApi } from "repositories/apigen-api";

export interface RoutesDeleteModalState {
  show: boolean
  id: number
}
const RoutesDeleteModal = (props: {state: RoutesDeleteModalState, setState: React.Dispatch<React.SetStateAction<RoutesDeleteModalState>>, onSuccess: () => void}) => {
  const [submitting, setSubmitting] = useState(false)
  const onSubmitHandler = async () => {
    try {
      setSubmitting(true)
      await CodegenApi.ApiGenRoute_DeleteById(props.state.id)
      toast.success("Delete success...")
      props.setState({...props.state, show: false})
      props.onSuccess()
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }      
    } finally {
      setSubmitting(false)
    }
  };

  return <>
    <Modal show={props.state.show} size="sm" onClose={ ()=> props.setState({...props.state, show: false})} >
      <Modal.Header>Delete Confirmation</Modal.Header>
      <Modal.Body>
        <form key="delete-form" className="flex flex-col gap-4">
          <div>
            {/* <div className="mb-2 block"> <Label htmlFor="name" value="Field / Column Name" /></div> */}
            <div className="">Are you sure to delete this route (endpoint)?</div>
          </div>
          <div className="flex flex-wrap gap-4 self-center">
            <Button color="light" onClick={()=>{props.setState({...props.state, show: false})}}>
              Cancel
            </Button>
            <Button color={"failure"} onClick={onSubmitHandler} disabled={submitting}>
              Delete
              {submitting && (
              <div className="ml-3">
                  <Spinner size="sm" light={true} />
              </div>
              )}
            </Button>
          </div>
        </form> 
      </Modal.Body>
    </Modal>
  </>
}

export default RoutesDeleteModal