import React, { useState } from "react";
import DashboardLayout from "components/layout/dashboard-layout";
import { Button, Label, Sidebar, Spinner, TextInput } from "flowbite-react";
import { useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from "react-toastify";
import { FiLogOut, FiSettings, FiUser } from "react-icons/fi";
import { useAuth } from "libs/context/auth";
import { SuperSEO } from "react-super-seo";
import { useCurrentUser } from "libs/context/user";
import { User } from "repositories/user-api/param";
import { ApiErrorResponse } from "libs/api/response";
import { UserApi } from "repositories/user-api";

const ProfilePage = () => {
  const curr_user = useCurrentUser()
  const { logout } = useAuth()
  const [mode, setMode] = useState<"profile"|"account">("profile")

  if(curr_user.user === undefined) {
    return <DashboardLayout>
      <SuperSEO title="Dashboard: Account & Profile" description="Dashboard: Account & Profile" lang="en" />
      <div className="absolute w-full h-full bg-light-500/50 z-[100]">
        <div className="flex h-screen">
          <div className="m-auto">
            <Spinner />
          </div>
        </div>
      </div>
    </DashboardLayout>
  }
  return <DashboardLayout>
    <SuperSEO title="Dashboard: Account & Profile" description="Dashboard: Account & Profile" lang="en" />
    
    <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-5">
      Profile & Account
    </h1>
    <hr className="mb-4"></hr>

    <div className="flex gap-4 py-8 min-h-[500px]">
      <div className="w-1/4 flex flex-col border-tertiary pr-2 mr-2 border-r ">
        <Sidebar>
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              <Sidebar.Item active={mode==="profile"} href="#" className="text-sm" icon={FiUser} onClick={()=>setMode("profile")}>
                Profile
              </Sidebar.Item>
              <Sidebar.Item active={mode==="account"} href="#" className="text-sm" icon={FiSettings} onClick={()=>setMode("account")}>
                Change Password
              </Sidebar.Item>
              <Sidebar.Item color="failure" href="#" className="text-sm" icon={FiLogOut} onClick={()=>logout()} >
                Logout
              </Sidebar.Item>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>
      </div>
      <div className="w-3/4">
        {
        mode === "profile" ? 
        <FormUpdateProfile current_user={curr_user.user} onSubmitDone={() => curr_user.mutate()}></FormUpdateProfile>
        :
        <FormChangePassword></FormChangePassword>
        }
      </div>
    </div>
    <div className="flex">
      <div className="w-fit">
        
      </div>
      <div className="w-full">
        
       
      </div>
    </div>
  </DashboardLayout>
}

export default ProfilePage;

const FormUpdateProfile = (props: {current_user: User, onSubmitDone: () => void}) => {
  const [submitting, setSubmitting] = useState(false)

  const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
  })

  const { register, handleSubmit, formState: { errors  }, setError } = useForm<{name :string}>({ mode: 'onChange', resolver: yupResolver(ValidationSchema), defaultValues: {
    name: props.current_user.name
  } });

  const onSubmitHandler = async (data: {name :string}) => {
    try {
      setSubmitting(true)
      await UserApi.UserApi_UpdateProfile(data.name)
      toast.success("Update Profile Success")
      props.onSubmitDone()
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "name": setError("name", { type: "focus", message: e.message }, { shouldFocus: true }); break;   
          }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };

  return <form key="update-profile-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)} >
    <div>
      <div className="mb-2 block"> <Label htmlFor="dt" value="Email" /></div>
      <div className="text-sm">{props.current_user.email}</div>
    </div>
    <div>
      <div className="mb-2 block"> <Label htmlFor="name" value="Name"/></div>
      <TextInput {...register("name")} id="name"  defaultValue={""} />
      <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.name && <>{errors.name.message}</>) }</p>
    </div>
    
    <div className="self-center">
      <Button type="submit" color="primary" disabled={submitting}>
        Save Changes
        {submitting && (
        <div className="ml-3">
          <Spinner size="sm" light={true} />
        </div>
        )}
      </Button>
    </div>
  </form> 
}


const FormChangePassword = () => {

  const [submitting, setSubmitting] = useState(false)

  const ValidationSchema = yup.object().shape({
    old_password: yup.string().required(),
    new_password: yup.string().required(),
    confirm_new_password: yup.string().oneOf([yup.ref('new_password'), null], 'Passwords doesnt match')

  })
  const { register, handleSubmit, formState: { errors  }, setError } = useForm<{old_password: string, new_password: string, confirm_new_password: string}>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  const onSubmitHandler = async (data: {old_password: string, new_password: string, confirm_new_password: string}) => {
    try {
      setSubmitting(true)
      await UserApi.AuthApi_ChangePassword(data.old_password, data.new_password)
      toast.success("Change Password Success")
    } catch (error) {
      if(error as ApiErrorResponse){
        (error as ApiErrorResponse).other_errors.forEach( (e) => {
          switch(e.field){
            case "old_password": setError("old_password", { type: "focus", message: e.message }, { shouldFocus: true }); break; 
            case "new_password": setError("new_password", { type: "focus", message: e.message }, { shouldFocus: true }); break; 
          }
        })
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };

  return <form key="change-password-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)} >
    
    <div>
      <div className="mb-2 block"> <Label htmlFor="old_password" value="Old Password"/></div>
      <TextInput {...register("old_password")} type="password" id="old_password"  defaultValue={""} />
      <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.old_password && <>{errors.old_password.message}</>) }</p>
    </div>
    
    <div>
      <div className="mb-2 block"> <Label htmlFor="new_password" value="New Password"/></div>
      <TextInput {...register("new_password")} type="password" id="new_password"  defaultValue={""} />
      <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.new_password && <>{errors.new_password.message}</>) }</p>
    </div>

    <div>
      <div className="mb-2 block"> <Label htmlFor="confirm_new_password" value="Confirm New Password"/></div>
      <TextInput {...register("confirm_new_password")} type="password" id="confirm_new_password"  defaultValue={""} />
      <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.confirm_new_password && <>{errors.confirm_new_password.message}</>) }</p>
    </div>
    
    <div className="self-center">
      <Button type="submit" color="primary" disabled={submitting}>
        Save Changes
        {submitting && (
        <div className="ml-3">
          <Spinner size="sm" light={true} />
        </div>
        )}
      </Button>
    </div>
  </form> 
}

